<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Creating an AWS account</h2>
          <h3>1. AWS account</h3>
          <p>
            If you already have an AWS account, you can skip this step.
          </p>
          <p>Please follow an official tutorial from AWS on creating an account: <a href="https://docs.aws.amazon.com/accounts/latest/reference/manage-acct-creating.html" target="_blank" rel="noopener noreferrer" class="underline-link">Create an AWS account.</a></p>
          <InfoContainer class="mt-10">
            <span class="font-roboto-medium">INFO</span>
            When you create an AWS account, AWS requires you to use a credit card. You are not charged when you create an account. AWS charges you when you use your cloud resources.
            When selecting a support level when creating an account, select the free version.
          </InfoContainer>

          <h3>2. Protecting your AWS account</h3>
<p>
          When you create an AWS account, you create a root user. The root user has full access to the AWS account and should not be used.
        When we set up the AWS organisation later in this tutorial, you will no longer need the root user for access as we will be creating a separate user to access the accounts.
</p>
<p>For now, protect your account (root user) by doing at least these 2 things:</p>
  <ul class="list-item-one">

    <li><span class="font-roboto-medium">  <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/root-user-best-practices.html#ru-bp-password" target="_blank" rel="noopener noreferrer" class="underline-link">Use strong passwords </a></span></li>
    <li><span class="font-roboto-medium">  <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/enable-virt-mfa-for-root.html" target="_blank" rel="noopener noreferrer" class="underline-link">Enable multi-factor authentication</a></span>. You can use
      <a href="https://apps.apple.com/de/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer" class="underline-link">Google</a> or
      <a href="https://apps.apple.com/de/app/microsoft-authenticator/id983156458" target="_blank" rel="noopener noreferrer" class="underline-link">Microsoft</a> free Authenticator apps.</li>
  </ul>
          <p> You can also follow other best practices <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/root-user-best-practices.html" target="_blank" rel="noopener noreferrer" class="underline-link">here.</a> </p>

          <p>Great, now let's create an AWS organisation. </p>
          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import InfoContainer from "@/components/service-documentation/commons/InfoContainer.vue";

export default {
  name: 'DocsCreateAccount',
  components: {
    InfoContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {

    }
  }
}
</script>



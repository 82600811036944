<template>
  <button :class="{'copy-button': true, 'copied': copied}" @click="copyCode"></button>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    code: String,
  },
  setup(props) {
    const copied = ref(false);

    const copyCode = async () => {
      try {
        await navigator.clipboard.writeText(props.code);
        copied.value = true;
        setTimeout(() => copied.value = false, 1000);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    return { copyCode, copied };
  },
};
</script>

<style scoped>

.copy-button {
  position: absolute;
  top: 20px;
  right: 10px;
  background: url('@/assets/images/icon/copy-button.svg') no-repeat center;
  background-size: 100% 100%;
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  cursor: pointer;
}

.copied {
  background: url('@/assets/images/icon/tick.svg') no-repeat center;
}
</style>

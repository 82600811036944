<template>
  <BlogPostStartingWithAwsWhatToLearnAndDoFirstMainArea/>
</template>

<script>
import BlogPostStartingWithAwsWhatToLearnAndDoFirstMainArea from '@/components/blog/blog-post-starting-with-aws-what-to-learn-and-do-first/index.vue';

export default {
  name:'BlogPostStartingWithAwsWhatToLearnAndDoFirst',
  components:{BlogPostStartingWithAwsWhatToLearnAndDoFirstMainArea}
}
</script>

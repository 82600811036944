<template>
  <div class="blog">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <!-- Tab panes -->
          <div class="tab-content">
            <h2 class="font-roboto">Blog</h2>

            <div v-for="(item,index) in blog_data" :key="index"
                 :class="`tab-pane fade ${item.active ? 'active show' : ''}`">
              <router-link :to=item.to>
                <h3>{{ item.title_main_intro }}</h3>
                <p>
                  <em>{{ item.date_text_1 }}</em>
                </p>
              </router-link>
            </div> <!-- /.tab-content -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ai_app_dark from "@/assets/images/assets/ai_app_dark_with_bedrock.svg"
import question_mark from "@/assets/images/assets/question_mark.svg"

export default {
  name: 'BlogListMainArea',
  data() {
    return {
      blog_data: [
        {
          active: true,
          title_main_intro: 'Starting with AWS? What to learn and do first',
          to: "/blog/starting-with-aws-what-to-learn-and-do-first",
          date_text_1: 'Apr 20, 2024',
          img_src: question_mark
        },
        {
          active: true,
          title_main_intro: 'The tech stack of a simple SaaS for AWS cloud',
          to: "blog/the-tech-stack-of-a-simple-saas-for-aws-cloud",
          date_text_1: 'Apr 2, 2024',
          img_src: ai_app_dark
        },
      ],
    }
  }
}
</script>



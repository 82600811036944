<template>
  <PrivacyPolicyMain/>
</template>

<script>
import PrivacyPolicyMain from '../components/privacy';

export default {
  name:'PrivacyPolicy',
  components:{PrivacyPolicyMain}
}
</script>

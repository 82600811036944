<template>
  <SignUpConfirmAreaMain/>
</template>

<script>
import SignUpConfirmAreaMain from '../components/signup-confirm';

export default {
  name:'SignUpConfirmPage',
  components:{SignUpConfirmAreaMain}
}
</script>

import {ref} from 'vue';
import {isLemonSqueezy, isStripe} from "@/composables/useEnvironment";

export default function usePricingPlans() {
    let priceIds = []
    let prices = []

    if (isStripe()) {
        priceIds = process.env.VUE_APP_PRICE_IDS_FOR_SUBS_STRIPE.split(',');
        prices = process.env.VUE_APP_PRICES_FOR_SUBS_STRIPE.split(',');
    }
    else if (isLemonSqueezy()) {
        priceIds = process.env.VUE_APP_PRICE_IDS_FOR_SUBS_LEMON_SQUEEZY.split(',');
        prices = process.env.VUE_APP_PRICES_FOR_SUBS_LEMON_SQUEEZY.split(',');
    }

    const pricingPlansMonthly = ref([
        {
            id: 1,
            pack_name_key: "pricingPlans.monthly.free.name",
            pack_name_with_span_key: "pricingPlans.monthly.free.nameWithSpan",
            pack_name_en: "FreeMonthly",
            price: prices[0],
            priceId: priceIds[0],
            paymentMode: "subscription",
            info_span_key: "pricingPlans.monthly.free.infoSpan",
            info_em_key: "pricingPlans.monthly.free.infoEm",
            feature: [
                {id: 1, text_key: "pricingPlans.monthly.free.feature1"}
            ]
        },
        {
            id: 2,
            pack_name_key: "pricingPlans.monthly.basic.name",
            pack_name_with_span_key: "pricingPlans.monthly.basic.nameWithSpan",
            pack_name_en: "BasicMonthly",
            price: prices[1],
            priceId: priceIds[1],
            paymentMode: "subscription",
            info_span_key: "pricingPlans.monthly.basic.infoSpan",
            info_em_key: "pricingPlans.monthly.basic.infoEm",
            feature: [
                {id: 1, text_key: "pricingPlans.monthly.basic.feature1"},
                {id: 2, text_key: "pricingPlans.monthly.basic.feature2"}
            ]
        },
        {
            id: 3,
            pack_name_key: "pricingPlans.monthly.standard.name",
            pack_name_with_span_key: "pricingPlans.monthly.standard.nameWithSpan",
            pack_name_en: "StandardMonthly",
            price: prices[2],
            priceId: priceIds[2],
            paymentMode: "subscription",
            info_span_key: "pricingPlans.monthly.standard.infoSpan",
            info_em_key: "pricingPlans.monthly.standard.infoEm",
            feature: [
                {id: 1, text_key: "pricingPlans.monthly.standard.feature1"},
                {id: 2, text_key: "pricingPlans.monthly.standard.feature2"},
                {id: 3, text_key: "pricingPlans.monthly.standard.feature3"},
                {id: 4, text_key: "pricingPlans.monthly.standard.feature4"},
                {id: 5, text_key: "pricingPlans.monthly.standard.feature5"},
                {id: 6, text_key: "pricingPlans.monthly.standard.feature6"},
                {id: 7, text_key: "pricingPlans.monthly.standard.feature7"},
                {id: 8, text_key: "pricingPlans.monthly.standard.feature8"}
            ]
        }
    ]);

    const pricingPlansYearly = ref([
        {
            id: 1,
            pack_name_key: "pricingPlans.yearly.free.name",
            pack_name_with_span_key: "pricingPlans.yearly.free.nameWithSpan",
            pack_name_en: "FreeYearly",
            price: prices[3],
            priceId: priceIds[3],
            paymentMode: "subscription",
            info_span_key: "pricingPlans.yearly.free.infoSpan",
            info_em_key: "pricingPlans.yearly.free.infoEm",
            feature: [
                {id: 1, text_key: "pricingPlans.yearly.free.feature1"}
            ]
        },
        {
            id: 2,
            pack_name_key: "pricingPlans.yearly.basic.name",
            pack_name_with_span_key: "pricingPlans.yearly.basic.nameWithSpan",
            pack_name_en: "BasicYearly",
            price: prices[4],
            priceId: priceIds[4],
            paymentMode: "subscription",
            info_span_key: "pricingPlans.yearly.basic.infoSpan",
            info_em_key: "pricingPlans.yearly.basic.infoEm",
            feature: [
                {id: 1, text_key: "pricingPlans.yearly.basic.feature1"},
                {id: 2, text_key: "pricingPlans.yearly.basic.feature2"}
            ]
        },
        {
            id: 3,
            pack_name_key: "pricingPlans.yearly.standard.name",
            pack_name_with_span_key: "pricingPlans.yearly.standard.nameWithSpan",
            pack_name_en: "StandardYearly",
            price: prices[5],
            priceId: priceIds[5],
            paymentMode: "subscription",
            info_span_key: "pricingPlans.yearly.standard.infoSpan",
            info_em_key: "pricingPlans.yearly.standard.infoEm",
            feature: [
                {id: 1, text_key: "pricingPlans.yearly.standard.feature1"},
                {id: 2, text_key: "pricingPlans.yearly.standard.feature2"},
                {id: 3, text_key: "pricingPlans.yearly.standard.feature3"},
                {id: 4, text_key: "pricingPlans.yearly.standard.feature4"},
                {id: 5, text_key: "pricingPlans.yearly.standard.feature5"},
                {id: 6, text_key: "pricingPlans.yearly.standard.feature6"},
                {id: 7, text_key: "pricingPlans.yearly.standard.feature7"},
                {id: 8, text_key: "pricingPlans.yearly.standard.feature8"}
            ]
        }
    ]);


    return {
        pricingPlansMonthly,
        pricingPlansYearly,
        allPlans: [...pricingPlansMonthly.value, ...pricingPlansYearly.value]
    };
}

import {ref} from 'vue';
import axios from 'axios';
import {useToast} from "vue-toastification";
import {useI18n} from 'vue-i18n';
import {useSubscriptionsStore} from "@/store/subscriptions";
import {fetchAuthSession} from "aws-amplify/auth";

export default function useFetchActiveSubscriptions() {
    const { t } = useI18n();
    const subscriptionStore = useSubscriptionsStore();
    const fetchingSubscriptions = ref(false);
    const toast = useToast();

    const fetchActiveSubscriptions = async () => {
        fetchingSubscriptions.value = true;

        const session = await fetchAuthSession({forceRefresh: true}).catch(() => null);

        if (!session) {
            fetchingSubscriptions.value = false;
            return;
        }

        try {
            const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/active-subscriptions`, {
                headers: {
                    'Authorization': `Bearer ${session.tokens.accessToken}`
                }
            });

            if (response.data && response.data.customBody.activeSubscriptions) {
                subscriptionStore.$patch({
                    activeSubscriptions: response.data.customBody.activeSubscriptions // Update the store using $patch
                });
            }
        } catch (error) {
            toast.error(t('toasts.fetchActiveSubscriptionsError', { error: error.message }));
        } finally {
            fetchingSubscriptions.value = false;
        }
    };

    return { fetchingSubscriptions, fetchActiveSubscriptions };
}

<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">

          <h2>Add LemonSqueezy payments</h2>

          <h3>What is LemonSqueezy?</h3>

          <p>LemonSqueezy is a payment service (payment gateway) that allows you to accept payments from your users.<br> The main advantage is that it is a <a href="https://www.lemonsqueezy.com/reporting/merchant-of-record" target="_blank" rel="noopener noreferrer" class="underline-link">Merchant of Record</a>, meaning it handles VAT/sales tax (but takes a higher fee for it). <br>
            It's very popular with small businesses that don't have a large team of accountants on their side.
            <br> LemonSqueezy was acquired by Stripe, but it is not the same payment service.
          </p>

          <h3>1. Create products in LemonSqueezy (Test Mode and AWS dev account)</h3>
          <p>First, we will create products in a test mode that allows you to test payments. This only applies to your AWS dev account.</p>
          <ul class="list-item-one">
            <li>
              Create a <a href="https://www.lemonsqueezy.com/" target="_blank" rel="noopener noreferrer" class="underline-link">LemonSqueezy account</a> if you don't already have one. In your account, create a store.
              <InfoContainer class="mt-15">
                <span class="font-roboto-medium">If you already have a LemonSqueezy store</span>
                For every application/SaaS you have, you will need an additional LemonSqueezy store. Don't worry, all your stores will be under your name and the same email. <br>
                This is done also to avoid confusion in webhooks. <br>
                So, if you already have an LemonSqueezy store and you already have some other application there, just add an additional store.<br>
                If you don't want to create a new store, you can put multiple SaaS in one store, but this is not recommended.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/paymentproviders/ls_new_store.png"
                      alt=""
                      class="shapes screen-one"
                      style="width: 40%; height: 40%"
                  />
                </div>
              </InfoContainer>
            </li>

            <li class="mt-15">
              Activate the <span class="font-roboto-medium">Test mode</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_testmode.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 40%; height: 40%"
                />
              </div>
            </li>
            <li>
              Go to the Products tab and create three products: Free, Basic and Standard. When you create each product, create two variants of it: monthly and yearly. These will be our subscriptions (Free Monthly, Free Yearly, Basic Monthly, Basic Yearly, Standard Monthly, Standard Yearly). <br>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_products.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>

            </li>
            <li class="mt-25">
              Note (write down) a variant id of each of the six subscriptions. Also note the store ID from Settings -> Stores.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_variant_id.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <p>Store id:</p>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_store_id.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              Change <span class="font-roboto-medium">create_global_config_dev()</span> in global_config.py.<br>
            </li>
            <CodeContainer :code="paymentConfig.code" :language="paymentConfig.language"/>
          <li class="mt-15">
            Fill in <span class="font-roboto-medium">create_lemonsqueezy_plans_dev()</span> in global_config.py.<br>
            - price_id is the variant id you copied<br> - price is your price<br> - jobs_limit is the number of actions (add and delete notes) that a user is allowed to do on the Service page (you can leave jobs_limit as it is, and change it later at any time).<br> Example:
          </li>
            <CodeContainer :code="paymentConfig2.code" :language="paymentConfig2.language"/>
            <p>Note: make sure you copy it correctly (for example, your Standard Monthly plan variant id will be LemonSqueezyPlan price id with the name StandardMonthly).</p>
          </ul>


          <h3 style="margin-top: 40px !important;">2. Create a webhook in LemonSqueezy (Test Mode and AWS dev account)</h3>
          <ul class="list-item-one">
            <li>
              In your AWS dev account, go to API Gateway service and copy the URL of the gateway.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/gateway_url.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              In your LemonSqueezy account, create a webhook, paste the URL (without https:// part) you copied to the Callback URL field and add <span class="font-roboto-medium">/webhook_payment_lemonsqueezy</span> to it.<br>
              As a signing secret use any password-like text. Note that secret somewhere, we will need it later.<br>
              Also select the following events:
              <ul class="list-item-one">
                <li>subscription_created</li>
                <li>subscription_updated</li>
                <li>subscription_payment_failed</li>
                <li>subscription_payment_success</li>
              </ul>

              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_webhooks.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <div class="documentation-image mt-25">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_webhooks2.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              Create an API key in your LemonSqueezy account. Make a note of it, we will need it later.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_api_key.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            </ul>
          <h3 style="margin-top: 40px !important;">3. Configure Customer Portal (Test Mode and AWS dev account)</h3>
          <p>The LemonSqueezy Customer Portal allows your users to manage their subscriptions, including upgrades, downgrades and cancellations.</p>
          <ul class="list-item-one">
            <li class="mt-15">
              In your LemonSqueezy account, go to Design Settings and select the <span class="font-roboto-medium">Customer Portal tab</span>. <span class="font-roboto-medium">Enable it</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_customer_portal1.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 50%; height: 50%"
                />
              </div>
            </li>
            <li class="mt-15">
              Select <span class="font-roboto-medium">'Cancel subscriptions'</span> and <span class="font-roboto-medium">'Update subscriptions'</span>. These will allow users to cancel and switch subscriptions in their customer portal.
            </li>
            <li><span class="font-roboto-medium">Add the products</span> you've created. </li>
            <li>
              Select <span class="font-roboto-medium">'Apply changes immediately with proration'</span>. Proration allows your users to switch subscriptions at no extra cost. For example, if a user pays for a $20 monthly subscription and then wants to upgrade to a $50 monthly subscription (within a day after the $20 purchase), they only pay the remaining $30. If they upgrade the next day, or after a week, the remaining amount will be greater, and after a month it will be a full $50.<br>
              <div class="documentation-image mt-25">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_customer_portal2.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 50%; height: 50%"
                />
              </div>
            </li>
            <li class="mt-15">
              Publish the changes.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_customer_portal3.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>

          <h3 style="margin-top: 40px !important;">4. Save LemonSqueezy secrets to AWS Secret Manager (Test Mode and AWS dev account)</h3>
        <ul class="list-item-one">
          <p>Go to AWS Secret Manager (dev account) and update your secrets. Add the following:</p>
          <li>
            <span class="font-roboto-medium">lemonsqueezy_webhook_key</span> - this is a signing secret for the webhook that you've created when you created a webhook.
          </li>
          <li>
            <span class="font-roboto-medium">lemonsqueezy_api_key</span> - this is an API key you've created in the API settings of your LemonSqueezy account.
            <div class="documentation-image">
              <img
                  src="../../../../assets/images/documentation/paymentproviders/ls_secrets_secret_manager.png"
                  alt=""
                  class="shapes screen-one"
              />
            </div>
          </li>
        </ul>

          <h3 style="margin-top: 40px !important;">4. Deploy changes and test payments (Test Mode and AWS dev account)</h3>

          <ul class="list-item-one">
            <li>
              Now just push the code to your GitHub repository:
            </li>
            <CodeContainer class="mt-15" :code="commitPushDev.code" :language="commitPushDev.language"/>
            <li class="mt-15">After the rollout has been completed, the last step is to make sure your updated payment information is delivered to the frontend (it could be cached so we need to run CI/CD again).<br>
              To do this, just do an empty git commit and push the code.
            </li>
            <CodeContainer class="mt-15" :code="commitPushFinalSync.code" :language="commitPushFinalSync.language"/>
            <p>Alternatively, you can go to AWS Amplify (for <span class="font-roboto-medium">dev</span> account) and simply click:  <span class="font-roboto-medium">Redeploy this version.</span></p>
            <div class="documentation-image">
              <img
                  src="../../../../assets/images/documentation/deployment/amplify_redeploy.png"
                  alt=""
                  class="shapes screen-one"
              />
            </div>
            <li class="mt-15">
              Go to your website (AWS dev account, you can get the URL in Amplify Hosting) and test payments. Just subscribe to any plan. You must use <a href="https://docs.lemonsqueezy.com/help/getting-started/test-mode#test-card-numbers" target="_blank" rel="noopener noreferrer" class="underline-link">LemonSqueezy test cards</a> to do this.
              <InfoContainer class="mt-15">
                <span class="font-roboto-medium">IMPORTANT</span>
                1. Never use actual real cards when testing payments in Test Mode! Use only <a href="https://docs.lemonsqueezy.com/help/getting-started/test-mode#test-card-numbers" target="_blank" rel="noopener noreferrer" class="underline-link">LemonSqueezy test cards.</a>
                2. When testing LemonSqueezy payments from your website, we recommend that you use your browser's incognito mode (to avoid cookies issues).
              </InfoContainer>
            </li>
            <li class="mt-25">
              Note: After initial payment for the subscription, you can open the Customer Portal by navigating to the Account and clicking 'Manage'.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_customer_portal_manage.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>


          <h3 style="margin-top: 40px !important;">5. Enable LemonSqueezy payments for the prod account</h3>
          <ul class="list-item-one">
            <li>
              <span class="font-roboto-medium">Activate your store</span> in your LemonSqueezy account and finish the verification. Fill in the forms carefully. You may need to wait a few days for your activation request to be processed (you can always contact their support if it takes too long).
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_activate_store.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 25%; height: 25%"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_activate_store_steps.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 50%; height: 50%"
                />
              </div>
            </li>
            <li class="mt-15">
              Once the store has been activated, deactivate the <span class="font-roboto-medium">Test mode</span>.
            </li>
            <li>
              Repeat steps 1-4 from this documentation page, but this time:
              <ul class="list-item-one">
                <li>
                  Don't use the <span class="font-roboto-medium">Test mode</span>.
                </li>
                <li>
                  Instead of creating products again, you can simply select products in Test Mode and click 'Copy to Live Mode'.
                </li>
                <li>
                  Change the prod functions <span class="font-roboto-medium">create_lemonsqueezy_plans_prod()</span> and <span class="font-roboto-medium">create_global_config_prod()</span> functions instead of dev functions.
                </li>
                <li>
                  Save the LemonSqueezy secrets to your AWS prod account.
                </li>
                <li>
                  This time you don't need to test the payment flows with the test cards because it's a production version and you can accept real payments.
                </li>
              </ul>
            </li>
          </ul>
          <p><span class="font-roboto-medium">Congratulations!</span> You now have a payment system integrated into your website.</p>




          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import {ref} from "vue";
import CodeContainer from "@/components/service-documentation/commons/CodeContainer.vue";
import InfoContainer from "@/components/service-documentation/commons/InfoContainer.vue";

export default {
  name: 'AddLemonSqueezy',
  components: {
    InfoContainer,
    CodeContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    const paymentConfig = ref({
      language: 'python',
      code:
          'payment_system = "LEMON_SQUEEZY",\n' +
          'lemonsqueezy_store_id = "90152", # put your store id'
    });
    const paymentConfig2 = ref({
      language: 'python',
      code:
          '  LemonSqueezyPlan(\n' +
          '      name="FreeMonthly",\n' +
          '      price_id="432122", # change this\n' +
          '      price="0", # change this if you have a different price\n' +
          '      jobs_limit="10"),\n' +
          '# do the same for the rest of plans'
    });
    const commitPushDev = ref({
      language: 'bash',
      code:
          'cd saasconstruct # navigate to the project folder\n' +
          'git add .\n'+
          'git commit -m "support LemonSqueezy payments for the dev account"\n'+
          'git push origin main # pushes code to the main branch (prod)\n'+
          'git push origin main:development # pushes current main branch to development (dev)\n'
    });
    const commitPushFinalSync = ref({
      language: 'bash',
      code:
          'cd saasconstruct # navigate to the project folder\n' +
          'git add .\n'+
          'git commit --allow-empty -m "empty"\n'+
          'git push origin main # pushes code to the main branch (prod)\n'+
          'git push origin main:development # pushes current main branch to development (dev)\n'

    });
    return {
      paymentConfig,
      paymentConfig2,
      commitPushDev,
      commitPushFinalSync
    };
  }
}
</script>

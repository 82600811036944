<template>
  <a href="https://www.saasconstruct.com" class="builtwith-button font-roboto-light" target="_blank" rel="noopener noreferrer">
    Built with
    <!-- Use an img tag to include your SVG logo -->
    <img src="../../assets/images/logo/logo_no_text_for_button.svg" alt="Logo" class="logo-svg"/>
    <span class="font-roboto-medium">SaaS Construct</span>
  </a>
</template>

<script>
export default {
  name: 'BuiltWithButton',
}
</script>

<style scoped>
.builtwith-button {
  background: #fff;
  color: #000;
  border: 3px solid var(--blue-dark);
  padding: 10px 20px;
  font-size: 13px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.builtwith-button:hover {
  background: var(--blue-dark);
  color: #fff;
}

.builtwith-button .logo-svg {
  margin: 0 5px;
  width: 25px;
  height: auto;
}
</style>

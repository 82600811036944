<template>
  <PricingForOneTimePaymentsAreaMain/>
</template>

<script>
import PricingForOneTimePaymentsAreaMain from '../components/pricing-for-one-time-payments/index.vue';

export default {
  name:'PricingForOneTimePaymentsPage',
  components:{PricingForOneTimePaymentsAreaMain}
}
</script>

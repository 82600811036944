<template>
  <div class="col-xl-3 col-md-4 doc-sidebar">
    <div class="clearfix">
      <button class="btn btn-link d-md-none collapsed" type="button" data-bs-target="#doc-sidebar-nav"
              data-bs-toggle="collapse" aria-controls="doc-sidebar-nav" aria-expanded="false"
              aria-label="Toggle docs navigation">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
          <title>Menu</title>
          <path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                d="M4 7h22M4 15h22M4 23h22"></path>
        </svg>
      </button>
    </div>
    <nav class="doc-links collapse clearfix nav-fix" id="doc-sidebar-nav">
      <ul class="list-item">
        <li class="dropdown-holder" v-for="section in sections" :key="section.id">
          <div v-if="section.links && section.links.length > 0">
            <div class="section-header" :class="{ highlighted: isHighlighted(section.links[0].id) }">
              <router-link
                  :to="section.links[0].to"
                  class="nav-link section-title"

                  @click="handleSectionClick(section.id)">
                {{ $t(section.links[0].textKey) }}
              </router-link>
              <img
                  v-if="section.links.length > 1"
                  :src="isExpanded(section.id) ? require('@/assets/images/icon/arrow-down.svg') : require('@/assets/images/icon/arrow-right.svg')"
                  class="toggle-arrow"
                  @click.stop="toggleSection(section.id)"
              />
            </div>
            <div v-if="section.links.length > 1">
            <ul v-if="isExpanded(section.id)" class="sub-menu show">
              <li v-for="link in section.links.slice(1)" :key="link.id">
                <router-link
                    :to="link.to"
                    class="nav-link"
                    :class="{ highlighted: isHighlighted(link.id) }">
                  {{ $t(link.textKey) }}
                </router-link>
              </li>
            </ul>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useExpandedSectionsStore } from "@/store/documentation";
import {useDocumentationNavigation} from "@/composables/useDocumentationNavigation";
import {onMounted} from "vue";
import {useRoute} from "vue-router";

export default {
  name: 'SidebarDocumentationComponent',
  setup() {
    const { sections } = useDocumentationNavigation();
    const expandedSectionsStore = useExpandedSectionsStore();

    const toggleSection = (sectionId) => {
      expandedSectionsStore.toggleSection(sectionId);
    };

    const isExpanded = (sectionId) => {
      return expandedSectionsStore.isExpanded(sectionId);
    };

    const isHighlighted = (itemId) => {
      return expandedSectionsStore.isHighlighted(itemId);
    };

    const handleSectionClick = (sectionId) => {
      expandedSectionsStore.expandSection(sectionId);
    };

    const getSectionIdFromLinkId = (linkId) => {
      // Convert the integer to a string
      const linkIdStr = linkId.toString();
      // Get the first character of the string
      const firstChar = linkIdStr.charAt(0);
      // Convert the first character back to an integer
      const firstDigit = parseInt(firstChar, 10);
      // Return the first digit
      return firstDigit;
    };

      const route = useRoute();

      onMounted(() => {
        // Get the current path after the domain
        const currentPath = route.path;

        // Find the link corresponding to the current path
        let highlightedId = null;
        sections.value.forEach(section => {
          section.links.forEach(link => {
            if (link.to === currentPath) {
              highlightedId = link.id;
            }
          });
        });

        // Set the highlighted item in the store if found
        if (highlightedId !== null) {
          expandedSectionsStore.setHighlightedItem(highlightedId);
        }

        expandedSectionsStore.expandSection(getSectionIdFromLinkId(highlightedId))

      });

    return { sections, toggleSection, isExpanded, isHighlighted, handleSectionClick };
  },
};
</script>

<style scoped>

.doc-sidebar .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doc-sidebar .section-title {
  flex: 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  cursor: pointer;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.doc-sidebar .toggle-arrow {
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-right: 15px;
}

.doc-sidebar .nav-link {
  display: flex;
  align-items: center;
}

.doc-sidebar .highlighted {
  background-color: #e0e0e0;
  border-radius: 10px;
  font-weight: bold;
}

.doc-sidebar .sub-menu .nav-link {
  font-weight: normal;
}
</style>

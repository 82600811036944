import { ConsoleLogger } from 'aws-amplify/utils'

const logger = new ConsoleLogger('Hub-Logger');

export function useHub() {
    const hubListener = (data) => {
        switch (data?.payload?.event) {
            case 'configured':
                logger.debug('the Auth module is configured');
                break;
            case 'signIn':
                logger.debug('user signed in');
                break;
            case 'signIn_failure':
                logger.debug('user sign in failed');
                break;
            case 'signUp':
                logger.debug('user signed up');
                break;
            case 'signUp_failure':
                logger.debug('user sign up failed');
                break;
            case 'confirmSignUp':
                logger.debug('user confirmation successful');
                break;
            case 'completeNewPassword_failure':
                logger.debug('user did not complete new password flow');
                break;
            case 'autoSignIn':
                logger.debug('auto sign in successful');
                break;
            case 'autoSignIn_failure':
                logger.debug('auto sign in failed');
                break;
            case 'forgotPassword':
                logger.debug('password recovery initiated');
                break;
            case 'forgotPassword_failure':
                logger.debug('password recovery failed');
                break;
            case 'forgotPasswordSubmit':
                logger.debug('password confirmation successful');
                break;
            case 'forgotPasswordSubmit_failure':
                logger.debug('password confirmation failed');
                break;
            case 'verify':
                logger.debug('TOTP token verification successful');
                break;
            case 'tokenRefresh':
                logger.debug('token refresh succeeded');
                break;
            case 'tokenRefresh_failure':
                logger.debug('token refresh failed');
                break;
            case 'cognitoHostedUI':
                logger.debug('Cognito Hosted UI sign in successful');
                break;
            case 'cognitoHostedUI_failure':
                logger.debug('Cognito Hosted UI sign in failed');
                break;
            case 'customOAuthState':
                logger.debug('custom state returned from CognitoHosted UI');
                break;
            case 'customState_failure':
                logger.debug('custom state failure');
                break;
            case 'parsingCallbackUrl':
                logger.debug('Cognito Hosted UI OAuth url parsing initiated');
                break;
            case 'userDeleted':
                logger.debug('user deletion successful');
                break;
            case 'updateUserAttributes':
                logger.debug('user attributes update successful');
                break;
            case 'updateUserAttributes_failure':
                logger.debug('user attributes update failed');
                break;
            case 'signOut':
                logger.debug('user signed out');
                break;
            default:
                logger.debug('unknown event type');
                break;
        }
    };
    return { hubListener };
}

import DocsGetStarted from "@/components/service-documentation/get-started/index.vue";
import SetUpAws from "@/components/service-documentation/set-up-aws/index.vue";
import DocsCreateAccount
    from "@/components/service-documentation/set-up-aws/create-account/index.vue";
import CreateOrganisation from "@/components/service-documentation/set-up-aws/create-organisation/index.vue";
import InstallTools from "@/components/service-documentation/set-up-aws/install-tools/index.vue";
import InfraDeployment from "@/components/service-documentation/infra-deployment/index.vue";
import CloneRepo from "@/components/service-documentation/infra-deployment/clone-repo/index.vue";
import ExplanationCodebase from "@/components/service-documentation/infra-deployment/explanation-codebase/index.vue";
import DeployFrontend from "@/components/service-documentation/infra-deployment/deploy-frontend/index.vue";
import DeployBackend from "@/components/service-documentation/infra-deployment/deploy-backend/index.vue";
import TroubleShooting from "@/components/service-documentation/infra-deployment/troubleshooting/index.vue";
import SocialProviders from "@/components/service-documentation/social-providers/index.vue";
import AddGoogle from "@/components/service-documentation/social-providers/google/index.vue";
import PaymentProviders from "@/components/service-documentation/payment-providers/index.vue";
import AddLemonSqueezy from "@/components/service-documentation/payment-providers/lemonsqueezy/index.vue";
import AddStripe from "@/components/service-documentation/payment-providers/stripe/index.vue";
import AddAI from "@/components/service-documentation/ai/index.vue";
import AddChatbot from "@/components/service-documentation/ai/chatbot/index.vue";
import IncreaseQuotas from "@/components/service-documentation/increase-quotas/index.vue";
import RequestSES from "@/components/service-documentation/increase-quotas/ses/index.vue";

export default [
    {
        path: '/documentation/get-started',
        name: 'DocsGetStarted',
        component: DocsGetStarted,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/set-up-aws',
        name: 'DocsTutorials',
        component: SetUpAws,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/create-account',
        name: 'DocsCreateAccount',
        component: DocsCreateAccount,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/create-organisation',
        name: 'CreateOrganisation',
        component: CreateOrganisation,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/install-tools',
        name: 'InstallTools',
        component: InstallTools,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/infra-deployment',
        name: 'InfraDeployment',
        component: InfraDeployment,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/clone-repo',
        name: 'CloneRepo',
        component: CloneRepo,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/explanation-codebase',
        name: 'ExplanationCodebase',
        component: ExplanationCodebase,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/deploy-frontend',
        name: 'DeployFrontend',
        component: DeployFrontend,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/deploy-backend',
        name: 'DeployBackend',
        component: DeployBackend,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/troubleshooting',
        name: 'TroubleShooting',
        component: TroubleShooting,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/social-providers',
        name: 'SocialProviders',
        component: SocialProviders,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/add-google',
        name: 'AddGoogle',
        component: AddGoogle,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/payment-providers',
        name: 'PaymentProviders',
        component: PaymentProviders,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/add-lemonsqueezy',
        name: 'AddLemonSqueezy',
        component: AddLemonSqueezy,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/add-stripe',
        name: 'AddStripe',
        component: AddStripe,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/add-ai',
        name: 'AddAI',
        component: AddAI,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/add-chatbot',
        name: 'AddChatbot',
        component: AddChatbot,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/increase-quotas',
        name: 'IncreaseQuotas',
        component: IncreaseQuotas,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },
    {
        path: '/documentation/request-ses',
        name: 'RequestSES',
        component: RequestSES,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.documentationTextKey',
            isTranslated: false,
        }
    },

]

// UseDocumentationNavigation.js
import { ref } from 'vue';

export function useDocumentationNavigation() {
    const sections = ref([
        {
            id: 1,
            links: [
                { id: 101, textKey: 'documentationNavigation.getStarted', to: '/documentation/get-started' },
            ],
        },
        {
            id: 2,
            links: [
                { id: 201, textKey: 'documentationNavigation.setUpAws', to: '/documentation/set-up-aws' },
                { id: 202, textKey: 'documentationNavigation.createAccount', to: '/documentation/create-account' },
                { id: 203, textKey: 'documentationNavigation.createOrganisation', to: '/documentation/create-organisation' },
                { id: 204, textKey: 'documentationNavigation.installTools', to: '/documentation/install-tools' },
            ],
        },
        {
            id: 3,
            links: [
                { id: 301, textKey: 'documentationNavigation.infraDeployment', to: '/documentation/infra-deployment' },
                { id: 302, textKey: 'documentationNavigation.cloneRepo', to: '/documentation/clone-repo' },
                { id: 303, textKey: 'documentationNavigation.explanationCodebase', to: '/documentation/explanation-codebase' },
                { id: 304, textKey: 'documentationNavigation.deployFrontend', to: '/documentation/deploy-frontend' },
                { id: 305, textKey: 'documentationNavigation.deployBackend', to: '/documentation/deploy-backend' },
                { id: 306, textKey: 'documentationNavigation.troubleshooting', to: '/documentation/troubleshooting' },
            ],
        },
        {
            id: 4,
            links: [
                { id: 401, textKey: 'documentationNavigation.socialProviders', to: '/documentation/social-providers' },
                { id: 402, textKey: 'documentationNavigation.addGoogle', to: '/documentation/add-google' },

            ],
        },
        {
            id: 5,
            links: [
                { id: 501, textKey: 'documentationNavigation.paymentProviders', to: '/documentation/payment-providers' },
                { id: 502, textKey: 'documentationNavigation.addLemonSqueezy', to: '/documentation/add-lemonsqueezy' },
                { id: 503, textKey: 'documentationNavigation.addStripe', to: '/documentation/add-stripe' },

            ],
        },
        {
            id: 6,
            links: [
                { id: 601, textKey: 'documentationNavigation.ai', to: '/documentation/add-ai' },
                { id: 602, textKey: 'documentationNavigation.addChatbot', to: '/documentation/add-chatbot' },

            ],
        },
        {
            id: 7,
            links: [
                { id: 701, textKey: 'documentationNavigation.increaseQuotas', to: '/documentation/increase-quotas' },
                { id: 702, textKey: 'documentationNavigation.requestSES', to: '/documentation/request-ses' },

            ],
        },
    ]);

    return { sections };
}

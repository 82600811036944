<template>
  <SignUpAreaMain/>
</template>

<script>
import SignUpAreaMain from '../components/signup';

export default {
  name:'SignUpPage',
  components:{SignUpAreaMain}
}
</script>

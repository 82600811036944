<template>
  <div class="user-data-page clearfix">
    <div class="form-wrapper">
      <form @submit.prevent="onSubmit" class="user-data-form">
        <div class="wrapper-google-sign-in-button-sign-up-area">
          <button type="button" @click="onSubmitWithGoogle" class="google-sign-in-button">
            {{ $t('signup.signUpWithGoogle') }}
          </button>
        </div>
        <hr class="delimiter-80">
        <div class="row">
          <div class="input-group-meta d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('signup.emailLabel') }}</label>
              <input required type="email" :placeholder="$t('signup.emailPlaceholder')" v-model="email"
                     data-test="email-type"/>
            </div>
          </div>
          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('signup.passwordLabel') }}</label>
              <input
                  ref="passwordInput"
                  required
                  type="password"
                  :placeholder="$t('signup.passwordPlaceholder')"
                  class="pass_log_id"
                  v-model="password"
                  data-test="password-type"
              />
            </div>
          </div>
          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('signup.confirmPasswordLabel') }}</label>
              <input
                  ref="passwordInput"
                  required
                  type="password"
                  :placeholder="$t('signup.confirmPasswordPlaceholder')"
                  class="pass_log_id"
                  v-model="confirmPassword"
                  data-test="password-confirm"
              />
            </div>
          </div>

          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="agreement-statement d-flex flex-column justify-content-center align-items-start"
                 style="text-align: center">
              <p>
                {{ $t('signup.agreementStatement') }}
                <router-link to="/terms">{{ $t('signup.termsAndConditions') }}</router-link>
                {{ $t('signup.and') }}
                <router-link to="/privacy">{{ $t('signup.privacyPolicy') }}</router-link>
                .
              </p>
            </div>
          </div>

          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <button class="theme-btn-one mb-50" data-test="submit-button"
                      :disabled="isProcessing">
                <span v-if="isProcessing" class="spinner"
                      style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('signup.signUpWithEmailButton') }}</span>
              </button>
            </div>
          </div>

          <div class="input-group-meta mt-30 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <router-link class="global-link" to="/signin">{{ $t('signup.alreadyHaveAccountLink') }}</router-link>
            </div>
          </div>
        </div>
      </form>
    </div> <!-- /.form-wrapper -->
  </div>
</template>


<script>
import {ref} from 'vue';
import {useToast} from "vue-toastification";
import {useRouter} from 'vue-router';
import {signInWithRedirect, signOut, signUp} from 'aws-amplify/auth';
import {useAuthStore} from '@/store/auth';
import {useSecurity} from "@/composables/useSecurity";
import {useI18n} from 'vue-i18n';

export default {
  name: 'SaasInfraSignUpArea',
  setup() {
    const {t} = useI18n();
    const toast = useToast();
    const router = useRouter();
    const store = useAuthStore();
    const security = useSecurity();
    const toastTimeout = ref(4000);
    const isProcessing = ref(null);

    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');

    const signUpWithEmail = async () => {
      if (password.value !== confirmPassword.value) {
        toast.error(t('toasts.passwordsNotMatch'), {timeout: toastTimeout.value});
        return;
      }

      if (!security.isPasswordSecure(password.value)) {
        toast.error(t('toasts.passwordWeak'), {timeout: toastTimeout.value});
        return;
      }

      try {
        isProcessing.value = true;
        await signOut({global: false}); // clear storage
        await signUp({
          username: email.value,
          password: password.value,
          options: {
            userAttributes: {
              email: email.value
            },
            autoSignIn: true,
          },
        });
        store.$patch({currentEmail: email.value});
        toast.success(t('toasts.signedUpSuccess'), {timeout: toastTimeout.value});
        router.push('/signup-confirm');
      } catch (error) {
        store.$reset();
        toast.error(t('toasts.signUpError', {error: error.message}), {timeout: toastTimeout.value * 4});
      } finally {
        isProcessing.value = false;
      }
    };

    const signUpWithGoogle = async () => {
      try {
        await signOut({global: false}); // clear storage
        await signInWithRedirect({provider: "Google"});
      } catch (error) {
        toast.error(t('toasts.signInWithGoogleError', {error: error.message}), {timeout: toastTimeout.value});
      }
    };

    const onSubmit = () => {
      signUpWithEmail();
    };

    const onSubmitWithGoogle = () => {
      signUpWithGoogle();
    };

    return {
      email,
      password,
      confirmPassword,
      onSubmit,
      onSubmitWithGoogle,
      isProcessing
    };
  },
};
</script>

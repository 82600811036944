<template>
  <div class="terms_and_policies">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <!-- Tab panes -->
          <div class="tab-content">
            <div v-for="(item,index) in privacyData" :key="index"
                 :class="`tab-pane fade ${item.active ? 'active show' : ''}`">
              <h2 class="font-roboto"><span>{{ $t(item.titleIntro) }}</span></h2>
              <p class="font-roboto-light" v-html="$t(item.textP1)"></p>
              <p class="font-roboto-light" v-html="$t(item.textP2)"></p>
              <p class="font-roboto-light" v-html="$t(item.textP3)"></p>
              <p class="font-roboto-light" v-html="$t(item.textP4)"></p>
              <p class="font-roboto-light" v-html="$t(item.textP5)"></p>
              <p class="font-roboto-light" v-html="$t(item.textP6)"></p>
            </div> <!-- /.tab-content -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { ref } from 'vue';

export default {
  name: 'SaasInfraAbout',
  setup() {
    const privacyData = ref([
      {
        active: true,
        titleIntro: 'aboutCompany.titleIntro',
        textP1: 'aboutCompany.textP1',
        textP2: 'aboutCompany.textP2',
        textP3: 'aboutCompany.textP3',
        textP4: 'aboutCompany.textP4',
        textP5: 'aboutCompany.textP5',
        textP6: 'aboutCompany.textP6',
      }
    ]);
    const dateText = ref('aboutCompany.dateText');

    return {
      privacyData,
      dateText
    }
  }
}

</script>

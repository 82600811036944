<template>
  <div class="modal fade modal-contact-popup-two" id="editEmailModal" tabindex="-1" role="dialog" aria-labelledby="contactModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <main class="main-body modal-content clearfix">
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <img src="../../assets/images/icon/close.svg" alt="">
        </button>
        <h2 class="form-title mb-10">{{ $t('editEmail.changeEmailAddress') }}</h2>
        <h3 class="form-subtitle">{{ $t('editEmail.currentEmailAddress') }}</h3>
        <h2 class="form-title mt-5" style="font-size: 18px">{{ authStore.currentEmail }}</h2>

        <!-- Change Email Address Form -->
        <form @submit.prevent="sendEmailChangeResetCode">
          <div class="row">
            <div class="d-flex flex-column justify-content-center">
              <h3 class="form-subtitle mb-10">{{ $t('editEmail.typeNewEmail') }}</h3>
              <input class="font-roboto-light" style="padding: 0 10px;" required type="email" placeholder="myemail@example.com" v-model="newEmail"/>
            </div>

            <div class="mt-10 d-flex justify-content-center align-items-center">
              <button class="theme-btn-one mb-50" :disabled="isMakingRequest">
                <span v-if="isMakingRequest" class="spinner" style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('editEmail.sendCodeForEmailUpdate') }}</span>
              </button>
            </div>
          </div>
        </form>

        <!-- Enter Code Form -->
        <form @submit.prevent="changeEmail">
          <div class="row">
            <div class="d-flex flex-column justify-content-center">
              <h3 class="form-subtitle mb-10">{{ $t('editEmail.enterCodeWeSent') }}</h3>
              <input class="font-roboto-light pass_log_id" style="padding: 0 10px;" ref="passwordInput" required type="password" placeholder="Enter code" v-model="code"/>
            </div>

            <div class="mt-10 d-flex justify-content-center align-items-center">
              <button class="theme-btn-one mb-50" :disabled="isMakingRequest">
                <span v-if="isMakingRequest" class="spinner" style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('editEmail.updateEmail') }}</span>
              </button>
            </div>
          </div>
        </form>
      </main>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useAuthStore} from "@/store/auth";
import {fetchAuthSession, updateUserAttributes} from "aws-amplify/auth";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import axios from "axios";

export default {
  name: 'SaasEditEmailModal',
  setup() {
    const { t } = useI18n();
    const newEmail = ref('')
    const code = ref(null)
    const authStore = useAuthStore()
    const toast = useToast();
    const toastTimeout = ref(4000)
    const isMakingRequest = ref(false)

    const sendEmailChangeResetCode = async () => {
      if (newEmail.value === authStore.currentEmail) {
        toast.error(t('toasts.sameEmailAddress'), { timeout: toastTimeout.value });
        return;
      }
      try {
        isMakingRequest.value = true
        await updateUserAttributes({userAttributes: { email: newEmail.value }});
        toast.success(t('toasts.codeSentForEmailUpdate'), { timeout: toastTimeout.value * 4 });
      } catch (err) {
        toast.error(t('toasts.errorSupport', { error: err }), { timeout: toastTimeout });
      } finally {
        isMakingRequest.value = false;
      }
    }

    const changeEmail = async () => {
      if (newEmail.value == null) {
        toast.error(t('toasts.typeYourNewEmail'), { timeout: toastTimeout.value * 4 });
        return;
      }
      const session = await fetchAuthSession({forceRefresh: true}).catch(() => null);

      if (!session) {
        toast.error(t('toasts.userNotLoggedIn'), { timeout: toastTimeout.value * 4 });
        return;
      }

      try {
        isMakingRequest.value = true;
        await axios.post(process.env.VUE_APP_BACKEND_URL + "/update-user-email", { newEmail: newEmail.value, code: code.value }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.tokens.accessToken}`
          }
        });
        authStore.$patch({ currentEmail: newEmail.value });

        toast.success(t('toasts.emailUpdated'), { timeout: toastTimeout.value * 4 });
        newEmail.value = null;
        code.value = null;
      } catch (err) {
        toast.error(t('toasts.errorUpdatingEmail', { error: err }), { timeout: toastTimeout });
      } finally {
        isMakingRequest.value = false;
      }
    }

    return {
      authStore,
      newEmail,
      code,
      sendEmailChangeResetCode,
      changeEmail,
      isMakingRequest
    };
  },
}
</script>

<template>
  <ForgotPasswordAreaMain/>
</template>

<script>
import ForgotPasswordAreaMain from '../components/forgot-password';

export default {
  name:'ForgotPasswordPage',
  components:{ForgotPasswordAreaMain}
}
</script>

import {ref} from 'vue';
import axios from 'axios';
import {useToast} from "vue-toastification";
import {useI18n} from 'vue-i18n';
import {useOneTimePaymentsStore} from "@/store/oneTimePayments";
import {fetchAuthSession} from "aws-amplify/auth";

export default function useFetchOneTimePaymentsMade() {
    const { t } = useI18n();
    const oneTimePaymentsStore = useOneTimePaymentsStore();
    const fetchingOneTimePaymentsMade = ref(false);
    const toast = useToast();

    const fetchOneTimePaymentsMade = async () => {
        fetchingOneTimePaymentsMade.value = true;

        const session = await fetchAuthSession({forceRefresh: true}).catch(() => null);

        if (!session) {
            fetchingOneTimePaymentsMade.value = false;
            return;
        }

        try {
            const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/one-time-payments-made`, {
                headers: {
                    'Authorization': `Bearer ${session.tokens.accessToken}`
                }
            });

            if (response.data && response.data.customBody.oneTimePaymentsMade) {
                oneTimePaymentsStore.$patch({
                    oneTimePaymentsMade: response.data.customBody.oneTimePaymentsMade // Update the store using $patch
                });
            }
        } catch (error) {
            toast.error(t('toasts.fetchOneTimePaymentsMadeError', { error: error.message }));
        } finally {
            fetchingOneTimePaymentsMade.value = false;
        }
    };

    return { fetchingOneTimePaymentsMade, fetchOneTimePaymentsMade };
}

import axios from 'axios';
import {useToast} from "vue-toastification";
import {useI18n} from 'vue-i18n';
import {fetchAuthSession} from "aws-amplify/auth";

export default function useCreatePortalSession() {
    const { t } = useI18n();
    const toast = useToast();

    const createPortalSession = async (language) => {
        const session = await fetchAuthSession({forceRefresh: true}).catch(() => null);

        if (!session) {
            toast.info(t('toasts.pleaseSignIn'));
            return;
        }

        try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/create-portal-session-stripe`, {
                returnUrl: window.location.href,
                locale: language
            }, {
                headers: {
                    'Authorization': `Bearer ${session.tokens.accessToken}`
                }
            });

            if (response.data && response.data.customBody.url) {
                window.location = response.data.customBody.url;
            }
        } catch (error) {
            toast.error(t('toasts.createPortalSessionError', { error: error.message }));
        }
    };

    return { createPortalSession };
}

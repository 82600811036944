<template>
  <div>
    <div :class="`theme-main-menu sticky-menu theme-menu-five fixed`">
      <div class="d-flex align-items-center justify-content-center">
        <div class="logo">
          <router-link to="/" class="nav-link">
            <img src="../../assets/images/logo/logo_with_text_2.svg" alt=""/>
          </router-link>
        </div>
        <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
          <div class="nav-container">
            <button
                class="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
                :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'"
                @click="menuActive = !menuActive"
            >
              <span></span>
            </button>
            <div class="navbar-collapse collapse" id="collapsibleNavbar">
              <div class="d-lg-flex justify-content-between align-items-center">
                <ul
                    class="navbar-nav main-side-nav font-roboto-medium"
                    id="one-page-nav"
                >
                  <!--actual section-->
                  <li class="nav-item">
                    <a href="https://demo.saasconstruct.com" target="_blank" rel="noopener noreferrer" class="nav-link plausible-event-name=DemoClick">{{ $t('header.demo') }}</a>
                  </li>
                  <li class="nav-item">
                    <router-link to="/#pricing" class="nav-link">{{ $t('header.pricing') }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/blog" class="nav-link">{{ $t('header.blog') }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/documentation/get-started" class="nav-link">{{ $t('header.documentation') }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/#pricing" class="nav-link">{{ $t('intro.getStarted') }}</router-link>
                  </li>
                </ul>
                <div class="d-lg-flex align-items-center right-widget font-roboto-medium">
                  <ul class="right-button-group d-flex align-items-center justify-content-center">
                    <li>
                      <router-link
                          v-if="store.isAuthenticated"
                          to="/account"
                          class="header-action"
                      >{{ $t('header.account') }}
                      </router-link>
                      <router-link
                          v-else
                          to="/signin"
                          class="header-action"
                      >{{ $t('header.login') }}
                      </router-link>
                    </li>
                    <li v-if="showLanguageDropdown">
                      <SaasInfraLanguageDropdown/>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>

import {computed, onMounted, ref} from "vue";
import {useAuthStore} from "@/store/auth";
import SaasInfraLanguageDropdown from "@/components/languages/SaasInfraLanguageDropdown.vue";
// import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
// import {useToast} from "vue-toastification";
// import {useTestBannerStore} from "@/store/testBanner";
// import {isLocalhost} from "@/composables/useEnvironment";

export default {
  name: "SaasInfraHeader",
  components: {
    SaasInfraLanguageDropdown
  },

  setup() {
    const store = useAuthStore()
    const isSticky = ref(false)
    const menuActive = ref(false)
    const route = useRoute();
    // const testBannerStore = useTestBannerStore();
    //
    // const toast = useToast();
    // const {t} = useI18n();

    // const showTestBannerToast = () => {
    //   if (!isLocalhost()) {
    //     const testBannerMessage = [
    //       '- ' + t('testBanner.testVersionInfo'),
    //       '- ' + t('testBanner.noRealPayments'),
    //       '- ' + t('testBanner.loginRegisterInfo')
    //     ].join('\n');
    //
    //     toast.info(testBannerMessage, {
    //       timeout: 0
    //     });
    //
    //     testBannerStore.showTestBanner();
    //   }
    // };
    //
    // onMounted(() => {
    //   showTestBannerToast();
    // });

    const handleSticky = () => {
      isSticky.value = window.scrollY > 80;
    };

    onMounted(async () => {
      window.addEventListener("scroll", handleSticky);
    });


    const showLanguageDropdown = computed(() => {
      // Check the isTranslated property in the current route's meta
      return route.meta.isTranslated !== false;
    });


    return {
      store,
      menuActive,
      showLanguageDropdown,
    };
  },
};
</script>

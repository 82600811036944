import {ref} from 'vue';
import {isLemonSqueezy, isStripe} from "@/composables/useEnvironment";

export default function usePricingProducts() {
    let priceIds = []
    let prices = []

    if (isStripe()) {
        priceIds = process.env.VUE_APP_PRICE_IDS_FOR_ONE_TIME_STRIPE.split(',');
        prices = process.env.VUE_APP_PRICES_FOR_ONE_TIME_STRIPE.split(',');
    }
    else if (isLemonSqueezy()) {
        priceIds = process.env.VUE_APP_PRICE_IDS_FOR_ONE_TIME_LEMON_SQUEEZY.split(',');
        prices = process.env.VUE_APP_PRICES_FOR_ONE_TIME_LEMON_SQUEEZY.split(',');
    }

    const allFeatures = [
        {
            id: 1, text_key: "pricingProducts.features.feature1",
            subfeatures: [
                {id: 101, text_key: "pricingProducts.features.subfeature101"},
                {id: 102, text_key: "pricingProducts.features.subfeature102"},
                {id: 103, text_key: "pricingProducts.features.subfeature103"},
            ]
        },
        {
            id: 2, text_key: "pricingProducts.features.feature2",
            subfeatures: [
                {id: 201, text_key: "pricingProducts.features.subfeature201"},
            ]
        },
        {
            id: 3, text_key: "pricingProducts.features.feature3",
            subfeatures: [
                {id: 301, text_key: "pricingProducts.features.subfeature301"},
                {id: 302, text_key: "pricingProducts.features.subfeature302"},
            ]
        },

        {
            id: 4, text_key: "pricingProducts.features.feature4",
            subfeatures: [
                {id: 401, text_key: "pricingProducts.features.subfeature401"},
                {id: 402, text_key: "pricingProducts.features.subfeature402"},
                {id: 403, text_key: "pricingProducts.features.subfeature403"},
            ]
        },
        {
            id: 5, text_key: "pricingProducts.features.feature5",
            subfeatures: [
                {id: 501, text_key: "pricingProducts.features.subfeature501"},
            ]
        },
        {
            id: 6, text_key: "pricingProducts.features.feature6",
            subfeatures: [
                {id: 601, text_key: "pricingProducts.features.subfeature601"},
                {id: 602, text_key: "pricingProducts.features.subfeature602"},
                {id: 603, text_key: "pricingProducts.features.subfeature603"},
                {id: 604, text_key: "pricingProducts.features.subfeature604"},
                {id: 605, text_key: "pricingProducts.features.subfeature605"},
            ]
        },
        {
            id: 7, text_key: "pricingProducts.features.feature7",
            subfeatures: [
                {id: 701, text_key: "pricingProducts.features.subfeature701"},
            ]
        },
        {
            id: 8, text_key: "pricingProducts.features.feature8",
            subfeatures: [
                {id: 801, text_key: "pricingProducts.features.subfeature801"},
                {id: 802, text_key: "pricingProducts.features.subfeature802"},
                {id: 803, text_key: "pricingProducts.features.subfeature803"},
                {id: 803, text_key: "pricingProducts.features.subfeature804"},
            ]
        },
        {
            id: 9, text_key: "pricingProducts.features.feature9",
            subfeatures: [
                {id: 901, text_key: "pricingProducts.features.subfeature901"},
                {id: 902, text_key: "pricingProducts.features.subfeature902"},
                {id: 903, text_key: "pricingProducts.features.subfeature903"},
            ]
        },
        {
            id: 10, text_key: "pricingProducts.features.feature10",
            subfeatures: [
                {id: 1001, text_key: "pricingProducts.features.subfeature1001"},
                {id: 1002, text_key: "pricingProducts.features.subfeature1002"},
            ]
        },
        {
            id: 11, text_key: "pricingProducts.features.feature11",
            subfeatures: [
                {id: 1101, text_key: "pricingProducts.features.subfeature1101"},
                // {id: 1102, text_key: "pricingProducts.features.subfeature1102"},
            ]
        },

        // {
        //     id: 12, text_key: "pricingProducts.features.feature12",
        //     subfeatures: [
        //         {id: 1201, text_key: "pricingProducts.features.subfeature1201"},
        //         {id: 1202, text_key: "pricingProducts.features.subfeature1202"},
        //     ]
        // },
        {
            id: 13, text_key: "pricingProducts.features.feature13",
            subfeatures: [
                {id: 1301, text_key: "pricingProducts.features.subfeature1301"},
                {id: 1301, text_key: "pricingProducts.features.subfeature1302"},
            ]
        },
        {
            id: 14, text_key: "pricingProducts.features.feature14",
            subfeatures: [
                {id: 1401, text_key: "pricingProducts.features.subfeature1401"},
            ]
        },
        {
            id: 15, text_key: "pricingProducts.features.feature15",
            subfeatures: [
                {id: 1501, text_key: "pricingProducts.features.subfeature1501"},
                {id: 1502, text_key: "pricingProducts.features.subfeature1502"},
            ]
        },
        {
            id: 16, text_key: "pricingProducts.features.feature16",
            subfeatures: [
                {id: 1601, text_key: "pricingProducts.features.subfeature1601"},
                {id: 1602, text_key: "pricingProducts.features.subfeature1602"},
                {id: 1603, text_key: "pricingProducts.features.subfeature1603"},
                {id: 1604, text_key: "pricingProducts.features.subfeature1604"},
            ]
        },
        {id: 17, text_key: "pricingProducts.features.feature17"},
        {id: 18, text_key: "pricingProducts.features.feature18"},
    ]

    const pricingProducts = ref([
        {
            id: 1,
            pack_name_key: "pricingProducts.basic.name",
            pack_name_en: "OneTimePaymentBasic",
            isMostPopular: false,
            price: prices[0],
            priceId: priceIds[0],
            paymentMode: "payment",
            features: allFeatures,
            specialFeatures: [18],
            concatFeaturesText: [{id: 17, text_key: "pricingProducts.features.additionalTextFeature17OneYear"},]
        },
        {
            id: 2,
            pack_name_key: "pricingProducts.standard.name",
            pack_name_en: "OneTimePaymentStandard",
            isMostPopular: true,
            price: prices[1],
            priceId: priceIds[1],
            paymentMode: "payment",
            features: allFeatures,
            specialFeatures: [],
            concatFeaturesText: [{id: 17, text_key: "pricingProducts.features.additionalTextFeature17TwoYears"},]
        },
    ]);

    const getMostPopularProduct = () => {
        return pricingProducts.value.find(product => product.isMostPopular);
    };

    const findAdditionalTextKey = (featureId, productId) => {
        const product = pricingProducts.value.find(p => p.id === productId);
        if (!product || !product.concatFeaturesText) return '';

        const additionalText = product.concatFeaturesText.find(text => text.id === featureId);
        return additionalText ? additionalText.text_key : '';
    };

    return {
        pricingProducts,
        allProducts: pricingProducts.value,
        getMostPopularProduct,
        findAdditionalTextKey
    };
}

import {createRouter, createWebHistory} from 'vue-router'

import {useToast} from "vue-toastification";
import {useAuth} from '@/composables/useAuth';
import {i18n} from '@/main';
import {useAuthStore} from "@/store/auth";
import mainRoutes from "@/routes/mainRoutes";
import documentationRoutes from "@/routes/documentationRoutes";
import blogRoutes from "@/routes/blogRoutes";

const {refreshAuthStateInStore} = useAuth();
const toast = useToast()
const toastTimeout = 4000

const routes = [...mainRoutes, ...documentationRoutes, ...blogRoutes]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to) {
        const routeScrollBehavior = {
            '/documentation': 'instant',
        };
        const defaultBehavior = 'smooth';
        const behavior = routeScrollBehavior[to.path] || defaultBehavior;

        if (to.hash) {
            return {
                el: to.hash,
                behavior: behavior,
            }
        }
    },
    routes
})


router.beforeEach((to, from, next) => {
    const key1 = to.meta.companyKey;
    const key2 = to.meta.textKey;
    if (key1) {
        document.title = i18n.global.t(key1) + " | " + i18n.global.t(key2); // no other way
    }
    const store = useAuthStore()
    const navigationWithAuthCheck = async () => {
        await refreshAuthStateInStore(store, to.meta.avoidClearingEmailOnError);

        if (!store.isAuthenticated && to.meta.requiresAuth) {
            toast.error(i18n.global.t("toasts.pleaseSignIn", {
                timeout: toastTimeout
            }));
            next({name: 'SignInPage'});
        } else {
            next();
        }
    };

    navigationWithAuthCheck()

    window.scrollTo({top: 0, left: 0, behavior: 'instant'})
});


export default router

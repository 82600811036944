<template>
  <div class="main-page-wrapper overflow-hidden">
    <SaasInfraHeader/>
    <SaasInfraPricingForSubscriptionsArea/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraPricingForSubscriptionsArea from './SaasInfraPricingForSubscriptionsArea.vue';
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";

export default {
  name: 'SaasInfraPricingForSubscriptionsAreaMain',
  components: {SaasInfraHeader, SaasInfraFooter, SaasInfraPricingForSubscriptionsArea}
}
</script>

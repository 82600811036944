<template>
  <router-view/>
  <global-chat />
</template>

<script setup>
import { onMounted } from "vue";
import GlobalChat from "@/components/chat/GlobalChat.vue";
import AOS from "aos";
import axios from "axios";

onMounted(async () => {
  // Initialize AOS
  AOS.init({
    duration: 1200,
  });

  // Fetch status on page load/reload
  await axios.get(`${process.env.VUE_APP_BACKEND_URL}/app-service-status`);
});
</script>



<template>
  <div class="clearfix">
    <SaasInfraHeader/>
    <CloneRepo/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import CloneRepo from "@/components/service-documentation/infra-deployment/clone-repo/CloneRepo.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";


export default {
  name: 'InfraCloneRepo',
  components: {SaasInfraHeader, SaasInfraFooter, CloneRepo},
}
</script>

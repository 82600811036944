<template>
  <button
      class="plausible-event-name=ChatbotClick"
      @click="setInteractionHappened"
      style="
      position: fixed;
      bottom: 20px;
      right: 3%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
    "
  >
    <div v-if="isAlertVisible" @mouseover="showClose = true" @mouseleave="showClose = false"
         style="display: flex; flex-direction: column; align-items: flex-end;">
      <button
          v-if="showClose"
          class="close-btn"
          @click.stop="hideAlert"
          style="
          position: absolute;
          padding: 10px;
          top: -20px;
          right: -20px;
          background: none;
          border: none;
          cursor: pointer;
          z-index: 2;
        "
      >
        <img
            alt="Close Button"
            width="22"
            height="22"
            src="@/assets/images/icon/rounded-close-button.svg"
        />
      </button>

      <div
          class="alert alert-light font-roboto-light"
          role="alert"
          @click="openChat"
          style="
          margin: 0 0 10px;
          padding: 16px 24px;
          font-size: 14px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          position: relative;
        "
      >
        {{ $t('chat.popUp') }}
      </div>
      <div
          class="alert alert-light font-roboto-light"
          role="alert"
          @click="openChat"
          style="
        margin: 0;
        padding: 16px 24px;
        font-size: 14px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        position: relative;
      "
      >
        {{ $t('chat.popUp2') }}
      </div>
    </div>

    <div @click="toggleChat">
      <img :src="isChatVisible ? arrowDownIcon : chatIcon" alt="Chat Icon" width="52" height="52"/>
    </div>
  </button>

  <div v-show="isChatVisible">
    <deep-chat
        ref="chatElementRef"
        class="font-roboto-light deep-chat-el"
        style="
        height: 60vh; /* Adjustable relative to viewport */
        border-radius: 20px 20px 0px 0px;
        border: 1px solid #ececec;
        margin-bottom: 0;
        position: fixed;
        bottom: 120px; /* Adjusted relative to viewport */
        right: 3%;
        z-index: 1;
        width: 415px; /* Relative width for flexibility */
      "
        :demo="true"
        :history="getChatHistory()"
        :textInput='{
            "styles": {
             "container": {"width": "95%"},
                },
                "placeholder": { "text": $t("chat.placeholder") },
                "characterLimit": 100
             }'
        :onMessage="handleMessage"
        :requestBodyLimits='{"maxMessages": 1}'
    ></deep-chat>
    <div
        class="privacy-policy font-roboto-light deep-chat-el"
        style="
        font-size: 12px;
        color: #6c757d;
        border-radius: 0 0 20px 20px;
        border: 1px solid #ececec;
        margin: 0;
        position: fixed;
        bottom: 80px; /* Adjusted relative to viewport */
        right: 3%;
        z-index: 1;
        width: 415px; /* Matches the chat width */
        text-align: center;
        padding: 5px;
        box-sizing: border-box;
        background: #fff;
      "
    >
      {{ $t('chat.privacyNoticeBefore') }}
      <a href="/privacy" target="_blank" style="text-decoration: underline; color: inherit">
        {{ $t('chat.privacyNoticeLink') }}
      </a>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import { useAuthStore } from '@/store/auth'
import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios'
import 'deep-chat'

const arrowDownIcon = require('@/assets/images/icon/arrow-down-purple.svg');
const chatIcon = require('@/assets/images/icon/chatbot.svg');

export default {
  name: 'GlobalChat',
  setup() {
    const {t} = useI18n()
    const chatElementRef = ref(null)
    const authStore = useAuthStore()
    const isChatVisible = ref(false)
    const isAlertVisible = ref(false)
    const showClose = ref(false)
    const hasAlertBeenShown = ref(false)

    const getChatHistory = () => {
      const initialMessage = {
        role: 'ai',
        text: t('chat.initialMessage'),
      };
      const storedHistory = sessionStorage.getItem('messages');
      if (storedHistory) {
        const parsedHistory = JSON.parse(storedHistory);
        // Replace the first message with the initial message. This is done if user changed the language
        if (parsedHistory.length > 0) {
          parsedHistory[0] = initialMessage;
        }
        return parsedHistory;
      }
      return [initialMessage];
    };


    const saveThreadId = (threadId) => {
      sessionStorage.setItem('thread_id', threadId)
    }

    const getThreadId = () => {
      return sessionStorage.getItem('thread_id')
    }

    const updateChatHistory = () => {
      if (!chatElementRef.value) return
      const deepChatElement = chatElementRef.value
      const messages = deepChatElement.getMessages()
      sessionStorage.setItem('messages', JSON.stringify(messages))
    }

    const handleMessage = ({isHistory}) => {
      if (!isHistory) {
        updateChatHistory()
      }
    }

    const setInteractionHappened = () => {
      sessionStorage.setItem('rootButtonClicked', 'true')
      isAlertVisible.value = false
    }

    const checkSessionStorageAndAuth = () => {
      if (
          !sessionStorage.getItem('rootButtonClicked') &&
          !authStore.isAuthenticated &&
          !hasAlertBeenShown.value
      ) {
        isAlertVisible.value = true
        hasAlertBeenShown.value = true
      } else {
        isAlertVisible.value = false
      }
    }

    const toggleChat = () => {
      isChatVisible.value = !isChatVisible.value
      if (isChatVisible.value) {
        isAlertVisible.value = false
        showClose.value = false
      }
    }

    const hideAlert = () => {
      isAlertVisible.value = false
      setInteractionHappened()
    }

    const openChat = () => {
      hideAlert()
      isChatVisible.value = true
    }

    const makeRequestToAskBot = async (body, signals) => {
      try {
        const session = await fetchAuthSession({ forceRefresh: true }).catch(() => null)
        const headers = session?.tokens
            ? { Authorization: `Bearer ${session.tokens.accessToken}` }
            : {}
        const threadId = getThreadId()
        if (threadId) {
          body.thread_id = threadId
        }

        const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/ask-bot`, body, {
          headers
        })

        const {answer, thread_id, run_id} = response.data.customBody

        if (thread_id) {
          saveThreadId(thread_id)
        }

        if (answer) {
          const cleanAnswer = answer
              .replace(/\n/g, '<br>')
              .replace(/【\d+:\d+†source】/g, '') // Removes patterns like
              .replace(/\[([^\]]+)]\((https?:\/\/[^\s)]+)\)/g, '<a href="$2" target="_blank">$1</a>');
          signals.onResponse({role: 'ai', html: cleanAnswer});
        } else if (thread_id && run_id) {
          await checkRunStatus(thread_id, run_id, signals)
        }
      } catch (err) {
        const errorMessage =
            err.response?.status === 429
                ? t('chat.errorRequest429')
                : `Error: ${err.response?.data?.message || err.message}`
        signals.onResponse({role: 'ai', text: errorMessage})
      }
    }

    const checkRunStatus = async (thread_id, run_id, signals, retryCount = 0) => {
      if (retryCount >= 3) {
        signals.onResponse({role: 'ai', text: t('chat.errorRequest429')})
        return
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/check-run`, {
          thread_id,
          run_id,
        })

        const {answer} = response.data.customBody
        if (answer) {
          signals.onResponse({role: 'ai', html: answer.replace(/\n/g, '<br>')})
        } else {
          await checkRunStatus(thread_id, run_id, signals, retryCount + 1)
        }
      } catch (err) {
        signals.onResponse({
          role: 'ai',
          text: `Error: ${err.response?.data?.message || err.message}`,
        })
      }
    }

    onMounted(() => {
      setTimeout(() => {
        checkSessionStorageAndAuth()
      }, 4000)

      if (chatElementRef.value) {
        Object.assign(chatElementRef.value, {
          request: {handler: makeRequestToAskBot},
        })
      }
    })

    return {
      chatElementRef,
      isChatVisible,
      toggleChat,
      showClose,
      hideAlert,
      openChat,
      isAlertVisible,
      setInteractionHappened,
      chatIcon,
      arrowDownIcon,
      getChatHistory,
      handleMessage
    }
  },
}
</script>


<template>
  <AccountAreaMain/>
</template>

<script>
import AccountAreaMain from '../components/account';

export default {
  name: 'AccountPage',
  components: {AccountAreaMain}
}
</script>

import {defineStore} from "pinia"

export const useOneTimePaymentsStore = defineStore({
    id: "oneTimePayments",
    state: () => ({
        oneTimePaymentsMade: null,
    }),
    getters: {},
    actions: {}
})

<template>
  <div class="main-page-wrapper font-roboto overflow-hidden">
    <SaasInfraHeader/>
    <BlogPostSimpleTechStackForAwsCloudMainArea/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraHeader from '@/components/header/SaasInfraHeader.vue';
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import BlogPostSimpleTechStackForAwsCloudMainArea
  from "@/components/blog/blog-post-the-tech-stack-of-a-simple-saas-for-aws-cloud/BlogPostSimpleTechStackForAwsCloudMainArea.vue";

export default {
  name: 'BlogPostSimpleTechStackForAwsCloud',
  components: {
    BlogPostSimpleTechStackForAwsCloudMainArea,
    SaasInfraHeader,
    SaasInfraFooter,
  }
}
</script>

<template>
  <div class="clearfix">
    <SaasInfraHeader/>
    <AddGoogle/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";
import AddGoogle from "@/components/service-documentation/social-providers/google/AddGoogle.vue";


export default {
  name: 'DocsIndex',
  components: {SaasInfraHeader, SaasInfraFooter, AddGoogle}
}
</script>

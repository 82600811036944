<template>
  <div class="fancy-feature-eleven pt-100" id="management">
    <div class="inner-container">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 col-md-9 m-auto">
            <div class="title-style-six text-center">
              <h2>{{ $t('management.title') }}</h2>
            </div>
            <!-- /.title-style-six -->
          </div>
        </div>
      </div>

      <div class="block-style-sixteen">
        <div class="container">
          <div class="row">
            <div
                class="col-xl-5 col-lg-6 screen-left"
                data-aos="fade-right"
                data-aos-duration="1200"
            >
              <div
                  class="icon d-flex align-items-center justify-content-center"
              >
                <img src="../../assets/images/assets/git_download.svg" alt="" />
              </div>
              <h3 class="title">{{ $t('management.cloneTitle') }}</h3>
              <p>
                {{ $t('management.cloneDescription') }}
              </p>
            </div>
          </div>
        </div>
        <img
            src="../../assets/images/assets/git_clone_dark.svg"
            alt=""
            class="shapes screen-one"
        />
      </div>

      <div class="block-style-sixteen">
        <div class="container">
          <div class="row">
            <div
                class="col-xl-5 col-lg-6 screen-left"
                data-aos="fade-right"
                data-aos-duration="1200"

            >
              <div
                  class="icon d-flex align-items-center justify-content-center"
              >
                <img src="../../assets/images/icon/62_c.svg" alt="" />

              </div>
              <h3 class="title">{{ $t('management.deploymentTitle') }}</h3>
              <p>
                {{ $t('management.deploymentDescription') }}
              </p>
            </div>
          </div>
        </div>
        <img
            src="../../assets/images/assets/cdk_deploy_dark.svg"
            alt=""
            class="shapes screen-one"
        />
      </div>

<!--      <div class="block-style-sixteen">-->
<!--        <div class="container">-->
<!--          <div class="row">-->
<!--            <div-->
<!--                class="col-xl-5 col-lg-6 screen-left"-->
<!--                data-aos="fade-right"-->
<!--                data-aos-duration="1200"-->

<!--            >-->
<!--              <div-->
<!--                  class="icon d-flex align-items-center justify-content-center"-->
<!--              >-->
<!--                <img src="../../assets/images/icon/106-c.svg" alt="" />-->

<!--              </div>-->
<!--              <h3 class="title">{{ $t('management.resultTitle') }}</h3>-->
<!--              <p>-->
<!--                {{ $t('management.resultDescription') }}-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <img-->
<!--        src="../../assets/images/documentation/deployment/initial_run.gif"-->
<!--            alt=""-->
<!--            class="shapes screen-one"-->
<!--        />-->
<!--      </div>-->
      <!-- /.block-style-sixteen -->
    </div>
    <!-- /.inner-container -->
  </div>
</template>


<script>
export default {
  name: "SaasInfraManagement",
};
</script>

<template>
  <div class="modal fade modal-contact-popup-two" id="editPasswordModal" tabindex="-1" role="dialog" aria-labelledby="contactModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <main class="main-body modal-content clearfix">
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <img src="../../assets/images/icon/close.svg" alt="">
        </button>
        <h2 class="form-title mb-10">{{ $t('editPassword.changePassword') }}</h2>
        <form @submit.prevent="changePassword">
          <div class="row">
            <div class="d-flex flex-column justify-content-center">
              <h3 class="form-subtitle mb-15">{{ $t('editPassword.typeOldNewPasswords') }}</h3>
              <input class="font-roboto-light mb-10 pass_log_id" style="padding: 0 10px;" required type="password" :placeholder="$t('editPassword.oldPasswordPlaceholder')" v-model="oldPassword"/>
              <input class="font-roboto-light mb-10 pass_log_id" style="padding: 0 10px;" required type="password" :placeholder="$t('editPassword.newPasswordPlaceholder')" v-model="newPassword"/>
              <input class="font-roboto-light pass_log_id" style="padding: 0 10px;" required type="password" :placeholder="$t('editPassword.confirmNewPasswordPlaceholder')" v-model="confirmPassword"/>
            </div>

            <div class="mt-10 d-flex justify-content-center align-items-center">
              <button class="theme-btn-one mb-50">{{ $t('editPassword.updatePassword') }}</button>
            </div>
          </div>
        </form>
      </main>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useAuthStore} from "@/store/auth";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import {useSecurity} from "@/composables/useSecurity";
import {updatePassword} from "aws-amplify/auth";

export default {
  name: 'SaasEditPasswordModal',
  setup() {
    const { t } = useI18n();
    const oldPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const store = useAuthStore();
    const security = useSecurity();
    const toast = useToast();
    const toastTimeout = ref(4000);

    const changePassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        toast.error(t('toasts.passwordsNotMatch'), { timeout: toastTimeout });
        return;
      }

      if (!security.isPasswordSecure(newPassword.value)) {
        toast.error(t('toasts.passwordWeak'), { timeout: toastTimeout });
        return;
      }

      try {
        await updatePassword({oldPassword: oldPassword.value, newPassword: newPassword.value});
        toast.success(t('toasts.passwordChanged'), { timeout: toastTimeout.value * 4 });
        oldPassword.value = null;
        newPassword.value = null;
        confirmPassword.value = null;
      } catch (err) {
        toast.error(t('toasts.errorSupport', { error: err }), { timeout: toastTimeout });
      }
    };

    return {
      store,
      oldPassword,
      newPassword,
      confirmPassword,
      changePassword,
    };
  },
}

</script>

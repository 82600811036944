<template>
  <div class="account-page clearfix">
    <div class="settings-field">
      <!-- Plan Section -->
      <AccountOneTimePaymentSection/>
      <!-- Email Section -->
      <div class="container mb-30 d-flex justify-content-center align-items-center">
        <div class="bg-wrapper d-flex flex-column justify-content-center align-items-start">
          <h2 class="font-roboto" style="font-size: 24px">{{ $t('account.yourEmail') }}</h2>
          <div class="bg-wrapper-inner d-flex flex-row align-items-center justify-content-between">
            <h2 class="font-roboto-light" style="font-size: 20px">
              {{ authStore.currentEmail }}
              {{ authStore.isFederated ? `(${authStore.currentProviderName}) ` : '' }}
            </h2>
            <router-link to="#editEmailModal" data-bs-toggle="modal" data-bs-target="#editEmailModal"
                         class="theme-btn-one" :class="{hidden_link: authStore.isFederated}"
                         style="max-width: 143px; text-align: center;">
              {{ $t('account.edit') }}
            </router-link>
          </div>
        </div>
      </div>

      <!-- Password Section -->
      <div v-if="authStore.isAuthenticated && !authStore.isFederated" class="container mb-30 d-flex justify-content-center align-items-center">
        <div class="bg-wrapper d-flex flex-column justify-content-center align-items-start">
          <h2 class="font-roboto" style="font-size: 24px">{{ $t('account.yourPassword') }}</h2>
          <div class="bg-wrapper-inner d-flex flex-row align-items-center justify-content-between">
            <h2 class="font-roboto-light" style="font-size: 20px">
              ∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗
            </h2>
            <router-link to="#editPasswordModal" data-bs-toggle="modal" data-bs-target="#editPasswordModal"
                         class="theme-btn-one"
                         style="max-width: 143px; text-align: center;">
              {{ $t('account.edit') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Sign Out and Other Options -->
    <div class="input-group-meta mt-40 d-flex justify-content-center align-items-center">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <button @click="signOutAndClear" class="theme-btn-one w-100 mb-15" style="background: #000;">{{
            $t('account.signOut')
          }}
        </button>
        <div class="mb-30 agreement-checkbox d-flex justify-content-between align-items-center">
          <input required type="checkbox" id="remember" v-model="fromEverywhere">
          <label class="font-roboto-light" for="remember">{{ $t('account.fromEverywhere') }}</label>
        </div>
      </div>
    </div>

    <!-- Danger Zone -->
    <div class="mt-0">
      <div class="delimiter-80-with-text font-roboto-light">{{ $t('account.dangerZone') }}</div>
    </div>

    <!-- Delete Account -->
    <div class="input-group-meta mt-30 d-flex justify-content-center align-items-center">
      <div class="d-flex flex-column justify-content-center align-items-start">
        <router-link to="#deleteUserModal" data-bs-toggle="modal" data-bs-target="#deleteUserModal"
                     class="theme-btn-one w-100 mb-30"
                     style="text-align: center; background: #EF4444;"
                     data-test="delete-account-button">
          {{ $t('account.deleteAccount') }}
        </router-link>
      </div>
    </div>
  </div>
  <EditEmailModal/>
  <EditPasswordModal/>
  <DeleteUserModal/>
</template>


<script>
import {onMounted, ref} from 'vue';
import {useToast} from "vue-toastification";
import {useRouter} from 'vue-router';
import {useAuthStore} from "@/store/auth";
import {signOut} from 'aws-amplify/auth';
import EditEmailModal from "./SaasInfraEditEmailModal.vue";
import EditPasswordModal from "./SaasInfraEditPasswordModal.vue";
import DeleteUserModal from "./SaasInfraDeleteUserModal.vue";
import {useI18n} from "vue-i18n";
import {useAllStores} from "@/store/allStores";
import AccountOneTimePaymentSection from "@/components/account/AccountOneTimePaymentSection.vue";
import {useAuth} from "@/composables/useAuth";


export default {
  name: 'SaasInfraAccountArea',
  components: {
    AccountOneTimePaymentSection,
    EditEmailModal,
    EditPasswordModal,
    DeleteUserModal,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const authStore = useAuthStore()
    const {resetAllStores} = useAllStores()
    const toastTimeout = ref(4000)
    const email = ref(null);
    const code = ref(null);
    const newPassword = ref(null);
    const fromEverywhere = ref(false);
    const {refreshAuthStateInCognitoCache} = useAuth();
    const {t} = useI18n();

    async function updateAccountEmailIfNeeded() {
      if (authStore.isFederated && !authStore.currentEmail) {
        refreshAuthStateInCognitoCache(authStore)
      }
    }

    onMounted(() => {
      updateAccountEmailIfNeeded();
    });


    const signOutAndClear = async () => {
      try {
        const temp = authStore.isFederated;
        await signOut({global: fromEverywhere.value === true});
        await resetAllStores();
        if (!temp) {
          router.push("/"); // for internal amplify bug
        }

        toast.success(t('toasts.signedOutSuccess'), {
          timeout: toastTimeout.value
        });
      } catch (err) {
        toast.error(t('toasts.signedOutError', {error: err}), {
          timeout: toastTimeout.value
        });
      }
    };

    return {
      authStore,
      email,
      code,
      newPassword,
      signOutAndClear,
      fromEverywhere,
    };
  },
};
</script>


<template>
  <div class="main-page-wrapper font-roboto overflow-hidden">
    <SaasInfraHeader/>
    <BlogListMainArea/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraHeader from '@/components/header/SaasInfraHeader.vue';
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import BlogListMainArea from "@/components/blog/bloglist/BlogListMainArea.vue";

export default {
  name: 'BlogList',
  components: {
    BlogListMainArea,
    SaasInfraHeader,
    SaasInfraFooter,
  }
}
</script>

<template>
  <div class="clearfix">
    <SaasInfraHeader/>
    <SetUpAws/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";
import SetUpAws from "@/components/service-documentation/set-up-aws/SetUpAws.vue";

export default {
  name: 'DocsIndex',
  components: {SaasInfraHeader, SaasInfraFooter, SetUpAws}
}
</script>

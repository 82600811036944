<template>
  <div class="fancy-feature-ten pt-100" id="why">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-xl-7 col-lg-10 col-md-8 m-auto">
          <div class="title-style-six text-center">
            <h2>{{ $t('why.title') }}</h2>
          </div>
          <!-- /.title-style-six -->
        </div>
      </div>

      <div class="row justify-content-center mt-35 md-mt-20">
        <div
            v-for="item in fancyFeatureData"
            :key="item.id"
            class="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            :data-aos-delay="item.delay"
        >
          <div class="block-style-fifteen mt-40">
            <div
                class="icon d-flex align-items-center justify-content-center"
            >
              <img :src="item.icon" alt="" />
            </div>
            <h3>{{ $t(`why.items.${item.id}.title`) }}</h3>
            <p>{{ $t(`why.items.${item.id}.subtitle`) }}</p>
          </div>
          <!-- /.block-style-fifteen -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "SaasInfraWhy",
  data() {
    return {
      fancyFeatureData: [
        {
          id: 1,
          icon: require(`@/assets/images/icon/106-c.svg`),
          delay: "",
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/79-c.svg`),
          delay: "100",
        },
        {
          id: 3,
          icon: require(`@/assets/images/icon/romb-c.svg`),
          delay: "200",
        },
      ],
    };
  },
};

</script>

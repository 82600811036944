<template>
  <div class="clearfix">
    <SaasInfraHeader/>
    <DocsPaymentProviders/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";
import DocsPaymentProviders from "@/components/service-documentation/payment-providers/DocsPaymentProviders.vue";


export default {
  name: 'DocsIndex',
  components: {SaasInfraHeader, SaasInfraFooter, DocsPaymentProviders}
}
</script>

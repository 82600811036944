<template>
  <div class="modal fade modal-contact-popup-two" id="deleteUserModal" tabindex="-1" role="dialog" aria-labelledby="contactModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <main class="main-body modal-content clearfix">
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" ref='showModal'>
          <img src="../../assets/images/icon/close.svg" alt="">
        </button>
        <h2 class="form-title mb-10">{{ $t('deleteUser.caution') }}</h2>
        <h2 class="form-title mb-10">{{ $t('deleteUser.accountDeletionWarning') }}</h2>
        <h3 class="form-subtitle">{{ $t('deleteUser.currentAccount') }}</h3>
        <h2 class="form-title mt-5" style="font-size: 18px">{{ authStore.currentEmail }}</h2>
        <form @submit.prevent="sendDeleteUserCode">
          <div class="row">
            <div class="mt-10 d-flex justify-content-center align-items-center">
              <button class="theme-btn-one mb-50" :disabled="isMakingRequest">
                <span v-if="isMakingRequest" class="spinner" style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('deleteUser.sendConfirmationCode') }}</span>
              </button>
            </div>
          </div>
        </form>

        <form @submit.prevent="deleteUser">
          <div class="row">
            <div class="d-flex flex-column justify-content-center">
              <h3 class="form-subtitle mb-10">{{ $t('deleteUser.enterCodeWeSent') }}</h3>
              <input class="font-roboto-light pass_log_id" style="padding: 0 10px;" ref="passwordInput" required type="password" :placeholder="$t('deleteUser.enterCodePlaceholder')" v-model="code"/>
            </div>

            <div class="mt-10 d-flex justify-content-center align-items-center">
              <button class="theme-btn-one mb-50" :disabled="isMakingRequest" style="background: #EF4444;">
                <span v-if="isMakingRequest" class="spinner" style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('deleteUser.deleteAccount') }}</span>
              </button>
            </div>
          </div>
        </form>
      </main>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useAuthStore} from "@/store/auth";
import {useToast} from "vue-toastification";
import {signOut, fetchAuthSession} from 'aws-amplify/auth';
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import axios from "axios";
import {useAllStores} from "@/store/allStores";

export default {
  name: 'SaasDeleteUserModal',
  setup() {
    const router = useRouter();
    const {t} = useI18n();
    const toast = useToast();
    const toastTimeout = ref(4000)
    const authStore = useAuthStore()
    const deleteWord = ref('')
    const {resetAllStores} = useAllStores()
    const isMakingRequest = ref(false)
    const code = ref(null)
    const showModal = ref(null)

    const sendDeleteUserCode = async () => {
      isMakingRequest.value = true
      const session = await fetchAuthSession({forceRefresh: true}).catch(() => null);

      if (!session) {
        toast.error(t('toasts.userNotLoggedIn'), { timeout: toastTimeout.value * 4 });
        return;
      }

      try {
        isMakingRequest.value = true
        await axios.get(process.env.VUE_APP_BACKEND_URL + "/confirm-account-deletion", {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.tokens.accessToken}`
          }
        });

        toast.success(t('toasts.codeSent'), { timeout: toastTimeout.value * 4 });
      } catch (err) {
        toast.error(t('toasts.errorSupport', {error: err.response.data.message}), { timeout: toastTimeout });
      } finally {
        isMakingRequest.value = false
      }
    }

    const signOutAndClear = async () => {
      // if sign out can be done by social login as well -> remove await in signOut to clear localStorage, it seems some internal bug
      try {
        const temp = authStore.isFederated;
        await signOut({global: true});
        showModal.value.click();
        if (!temp) {
          router.push("/"); // for internal amplify bug
        }
        await resetAllStores();
        toast.success(t('toasts.signedOutSuccess'), { timeout: toastTimeout.value });
      } catch (err) {
        toast.error(t('toasts.signedOutError', {error: err}), { timeout: toastTimeout.value });
      }
    };

    const deleteUser = async () => {
      if (code.value == null) {
        toast.error(t('toasts.provideCode'), { timeout: toastTimeout.value * 4 });
        return;
      }

      const session = await fetchAuthSession({forceRefresh: true}).catch(() => null);

      if (!session) {
        toast.error(t('toasts.userNotLoggedIn'), { timeout: toastTimeout.value * 4 });
        return;
      }

      try {
        isMakingRequest.value = true
        await axios.post(process.env.VUE_APP_BACKEND_URL + "/delete-account", {
          code: code.value
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.tokens.accessToken}`
          }
        });
        signOutAndClear()
        toast.success(t('toasts.accountDeleted'), { timeout: toastTimeout.value * 4 });
      } catch (err) {
        toast.error(t('toasts.errorDeletingUser', {error: err}), { timeout: toastTimeout });
      } finally {
        isMakingRequest.value = false
      }
    }

    return {
      authStore,
      deleteWord,
      sendDeleteUserCode,
      deleteUser,
      isMakingRequest,
      code,
      showModal
    };
  },
}


</script>

<template>
  <div class="modal fade modal-contact-popup-one" id="contactModal" tabindex="-1" role="dialog"
       aria-labelledby="contactModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <main class="main-body modal-content clearfix">
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <img src="../../assets/images/icon/close.svg" alt="">
        </button>
        <h2 class="form-title">{{ $t('contactModal.title') }}</h2>
        <form @submit.prevent="onSubmit" id="contact-form" data-bs-toggle="validator">
          <div class="row">
            <div class="col-12">
              <div class="input-group-meta form-group mb-15">
                <label>{{ $t('contactModal.nameLabel') }}</label>
                <div class="d-flex justify-content-center align-items-center">
                  <input v-model="formData.senderName" type="text" :placeholder="$t('contactModal.namePlaceholder')" required="required"
                         :data-error="$t('contactModal.nameError')">
                  <div class="help-block with-errors"></div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group-meta form-group mb-35">
                <label>{{ $t('contactModal.emailLabel') }}</label>
                <div class="d-flex justify-content-center align-items-center">
                  <input v-model="formData.email" type="email" :placeholder="$t('contactModal.emailPlaceholder')" required="required"
                         :data-error="$t('contactModal.emailError')">
                  <div class="help-block with-errors"></div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group-meta form-group mb-25">
                <div class="d-flex justify-content-center align-items-center">
                    <textarea v-model="formData.message" :placeholder="$t('contactModal.messagePlaceholder')" required="required"
                              :data-error="$t('contactModal.messageError')" maxlength="1000"></textarea>
                  <div class="help-block with-errors"></div>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center" type="submit">
              <button class="theme-btn-one" :disabled="formSubmitted" :class="{ 'disabled': formSubmitted }">
                <span v-if="formSubmitted" class="spinner" style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('contactModal.sendButton') }}</span>

              </button>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <p>{{ remainingCharacters }} {{ $t('contactModal.charactersRemaining') }}</p>
          </div>
        </form>
      </main> <!-- /.main-body -->
    </div>
  </div>
</template>


<script>
import { ref, computed } from 'vue';
import axios from 'axios';
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

export default {
  name: 'SaasContactModal',
  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const toastTimeout = ref(4000);
    const formSubmitted = ref(false);
    const formData = ref({
      senderName: '',
      email: '',
      message: ''
    });

    const remainingCharacters = computed(() => {
      return 1000 - formData.value.message.length;
    });

    const onSubmit = () => {
      const { senderName, email, message } = formData.value;
      const subject = `Contact from: ${senderName}, email: ${email}`;
      const config = {
        method: 'post',
        url: process.env.VUE_APP_BACKEND_URL + "/contact",
        headers: { 'Content-Type': 'application/json' },
        data: { senderName, email, message, subject }
      };

      formSubmitted.value = true;
      axios(config)
          .then(() => {
            formData.value = { senderName: '', email: '', message: '' }; // Clear form data
            toast.success(t('toasts.messageReceived'), { timeout: toastTimeout.value });
          })
          .catch(() => {
            toast.error(t('toasts.errorSendingMessage'), { timeout: toastTimeout });
          })
          .finally(() => {
            formSubmitted.value = false;
          });
    };

    return {
      formSubmitted,
      formData,
      remainingCharacters,
      onSubmit
    };
  },
}

</script>

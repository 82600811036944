<template>
  <div class="clearfix">
    <SaasInfraHeader/>
    <TroubleShooting/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import TroubleShooting from "@/components/service-documentation/infra-deployment/troubleshooting/TroubleShooting.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";


export default {
  name: 'InfraDeployBackend',
  components: {SaasInfraHeader, SaasInfraFooter, TroubleShooting},
}
</script>

<template>
  <div class="main-page-wrapper overflow-hidden">
    <SaasInfraHeader/>
    <SaasInfraPrivacyPolicy/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";
import SaasInfraPrivacyPolicy from "@/components/privacy/SaasInfraPrivacyPolicy.vue";

export default {
  name: 'PrivacyPolicyMain',
  components: {SaasInfraHeader, SaasInfraFooter, SaasInfraPrivacyPolicy},
}
</script>

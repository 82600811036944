<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Deploy the frontend</h2>
          <h3>1. Deploy the frontend to the dev account</h3>
          <p>
            We will use AWS Amplify Hosting to deploy and host the website (the frontend).
          </p>

          <ul class="list-item-one">
            <li>
              Go to your access portal and select a dev account. <br> We got the access portal URL in the <router-link to="/documentation/create-organisation" class="underline-link">'Create AWS Organisation'</router-link> step (URL is always available in the <a href="https://us-east-1.console.aws.amazon.com/singlesignon" target="_blank" rel="noopener noreferrer" class="underline-link">IAM Identity Center</a> Dashboard of your initial AWS account, in the region you previously chosen).
              <br> Once you are in the dev account, make sure it is in the region you specified earlier.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/go_to_dev_account.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>

          <li class="mt-15">
            Go to AWS Amplify. Use the search bar to navigate. It can take some time for AWS Amplify to open.
            <div class="documentation-image">
              <img
                  src="../../../../assets/images/documentation/deployment/go_to_amplify.png"
                  alt=""
                  class="shapes screen-one"
              />
            </div>
          </li>
            <li class="mt-15">
              Click <span class="font-roboto-medium">'Create new app'</span>, select 'GitHub' and click <span class="font-roboto-medium">'Next'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/choose_github.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              </li>
            <li class="mt-15">
              <span class="font-roboto-medium">Follow the instructions</span> and give access to your forked SaaSConstruct repository.
            </li>
            <li class="mt-15">
              Select your repository. Then select the <span class="font-roboto-medium">'development'</span> branch. Then click on  <span class="font-roboto-medium">'My app is monorepo'</span> and type "frontend" in the field below. Then click <span class="font-roboto-medium">'Next'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/select_repo.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-15">
              In the next step, you can choose to protect access to the website with the password. As this is a development account, it makes sense to do this so that only you have access to it during development. <br>
              After that, click <span class="font-roboto-medium">'Next'</span>. On the next page, click <span class="font-roboto-medium">'Save and deploy'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/password_protect.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              </li>
            <li class="mt-15">
              AWS Amplify will build your frontend. Wait for it to finish. Now you can then visit the site.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/visit_site.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            </ul>
          <p>Great! You have a website. Now let's do the same for the production account.
          </p>

          <h3>2. Deploy the frontend to the production account</h3>
          <p>The process is essentially the same. Go to your <span class="font-roboto-medium">prod account</span>  and repeat the steps. <br> In this case you need to choose the <span class="font-roboto-medium">'main'</span>  branch when you create an application, and you don't want to password protect the site as it will be open to the world for people to visit.</p>
          <p>Congratulations, you now have development and production set up for your frontend. Your frontend will be built and deployed to the appropriate AWS account whenever you 'git push to the dev or main branch.</p>

          <h3>3. Set up a domain for the production account</h3>
          <p>You can easily set up a domain for your website.</p>
          <ul class="list-item-one">
          <li>
            Go to your prod account and choose <span class="font-roboto-medium">'Route 53'</span>. Use the search bar to navigate.
          </li>

          <li>
            Follow these tutorials to either:
            <ul class="list-item-one">
            <li>
              <a href="https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/domain-register.html#domain-register-procedure-section" target="_blank" rel="noopener noreferrer" class="underline-link">Register a new domain </a> (recommended).
            </li>
            <li>
              If you already have an existing domain, you can  <a href="https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/domain-register.html#domain-register-procedure-section" target="_blank" rel="noopener noreferrer" class="underline-link">transfer</a> the domain to Route53.
            </li>
              <li>
                Once this is done, make sure you have a hosted zone in Route 53 for your domain (it may take several minutes for AWS to provide this). Route 53 will automatically create one for you when you register a new domain.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/deployment/domain_got.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>
              <li class="mt-15">
                Otherwise, create a hosted zone yourself.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/deployment/create_zone.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>

            </ul>
          </li>
            <li class="mt-15">
              Let's attach the domain to the website. Go to AWS Amplify (prod account). Select your app, go to <span class="font-roboto-medium">'Custom domains'</span> under 'Hosting', and click <span class="font-roboto-medium">'Add domain'</span>. <br>
              Select your domain and click <span class="font-roboto-medium">'Configure domain'</span>. Wait for it to finish.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/configure_domain.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>
          <p>Congratulations! You now have a website that is accessible through a domain. You can visit the domain and the website will be displayed in the browser.</p>

          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";

export default {
  name: 'ExplanationCodebase',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {
    }
  }
}
</script>



// useCreateCheckoutSessionForOneTimePayments.js

import axios from 'axios';
import {useToast} from 'vue-toastification';
import {useRouter} from 'vue-router';
import {useLanguageStore} from '@/store/language';
import {GENERAL_CONSTANTS} from '@/constants/generalConstants';
import {fetchAuthSession} from "aws-amplify/auth";
import {useI18n} from "vue-i18n";

export default function useCreateCheckoutSessionForOneTimePaymentsStripe() {
    const toast = useToast();
    const { t } = useI18n();

    const router = useRouter();
    const languageStore = useLanguageStore();

    const createCheckoutSessionForOneTimePaymentStripe = async (item, creatingSession) => {
        const session = await fetchAuthSession({forceRefresh: true}).catch(() => {
            toast.info(t('toasts.pleaseSignIn'));
            router.push('/signup');
            return null;
        });

        if (!session) return;

        creatingSession.value = true;
        creatingSession.value = item.id;
        try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/create-checkout-session-stripe`, {
                priceId: item.priceId,
                successUrl: `${window.location.origin}/documentation/get-started?${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_KEY}=${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_SUCCESS}`,
                cancelUrl: `${window.location.origin}?${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_KEY}=${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_FAIL}#pricing`,
                mode: item.paymentMode,
                locale: languageStore.language,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.tokens.accessToken}`,
                },
            });

            if (response.data && response.data.customBody.url) {
                window.location.href = response.data.customBody.url;
            }
        } catch (error) {
            toast.error(`Error creating checkout session: ${error.message}`);
        } finally {
            creatingSession.value = false;
        }
    };

    const createCheckoutSessionForOneTimePaymentWithoutLoginStripe = async (item, creatingSession) => {
        creatingSession.value = true;
        creatingSession.value = item.id;

        try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/create-checkout-session-without-login-stripe`, {
                priceId: item.priceId,
                successUrl: `${window.location.origin}/documentation/get-started?${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_KEY}=${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_SUCCESS}`,
                cancelUrl: `${window.location.origin}?${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_KEY}=${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_FAIL}`,
                mode: item.paymentMode,
                locale: languageStore.language,
                fromNoLogin: "yes",
            });

            if (response.data && response.data.customBody.url) {
                window.location.href = response.data.customBody.url;
            }
        } catch (error) {
            toast.error(`Error creating checkout session: ${error.message}`);
        } finally {
            creatingSession.value = false;
        }
    };

    return {
        createCheckoutSessionForOneTimePaymentStripe,
        createCheckoutSessionForOneTimePaymentWithoutLoginStripe,
    };
}

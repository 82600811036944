import { defineStore } from 'pinia';

export const useExpandedSectionsStore = defineStore('expandedSections', {
    state: () => ({
        expandedSections: new Set(),
        highlightedItem:  1,
    }),
    actions: {
        toggleSection(sectionId) {
            if (this.expandedSections.has(sectionId)) {
                this.expandedSections.delete(sectionId);
            } else {
                this.expandedSections.add(sectionId);
            }
        },
        expandSection(sectionId) {
            this.expandedSections.add(sectionId);
        },
        isExpanded(sectionId) {
            return this.expandedSections.has(sectionId);
        },
        setHighlightedItem(itemId) {
            this.highlightedItem = itemId;
        },
        isHighlighted(itemId) {
            return this.highlightedItem === itemId;
        }
    }
});

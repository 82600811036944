<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">

          <h2>Add payments</h2>

          <h3>What will we do?</h3>
          <p>
            In this section, we will add payments, either <span class="font-roboto-medium">Stripe</span> or <span class="font-roboto-medium">LemonSqueezy</span>. Stripe is popular with large companies and LemonSqueezy (which is Merchant of Record) is popular with small teams because it handles sales tax/VAT.
            <br>
            <br>
            For these payment systems, SaaSConstruct supports both <span class="font-roboto-medium">Checkout</span> (initial payment) and <span class="font-roboto-medium">Customer Portal</span> (subscription management), which means that users can buy your products and manage their subscriptions (change, cancel, renew) as well as their billing information and invoices without you doing anything.
          </p>
          <div class="documentation-image mt-25">
            <img
                src="../../../assets/images/documentation/paymentproviders/ls_payments.gif"
                alt=""
                class="shapes screen-one"
            />
          </div>

          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";

export default {
  name: 'DocsPaymentProviders',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {};
  }
}
</script>

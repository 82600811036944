<template>
  <div class="info-alert">
    <div class="icon">ℹ️</div>
    <div class="content">

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoContainer'
}
</script>

<style scoped>
.info-alert {
  display: flex;
  align-items: flex-start;
  background-color: #e6f7ff;
  border-left: 4px solid #1890ff;
  padding: 10px 16px;
  border-radius: 10px;
  color: #004085;
}

.icon {
  font-size: 24px;
  margin-right: 10px;
}

.content {
  display: flex;
  flex-direction: column;
}

a {
  color: #1890ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>

<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Setting up AWS</h2>
          <h3>What will we do?</h3>
          <p>
            In this section, we will set up <span
              class="font-roboto-medium"> development and production AWS accounts</span> and place them in <span
              class="font-roboto-medium"> AWS organisations </span> so you can access them easily.
          </p>
          <h3>AWS account</h3>
          <p>
            An <span class="font-roboto-medium"> AWS account </span> represents your environment. Each account is
            isolated from other accounts (i.e. cloud resources in one account are independent of resources in another
            account) to ensure security and separation of concerns.

          </p>
          <div class="documentation-image">
            <img
                src="../../../assets/images/documentation/setupaws/aws_account_overview.png"
                alt=""
                class="shapes screen-one"
            />
          </div>
          <h3>AWS Organisations</h3>
          <p>
            <span class="font-roboto-medium"> AWS Organisations</span> is a group of AWS accounts. It allows you to have
            one place (AWS access portal) to access all the accounts you specify.
          </p>
          <div class="documentation-image">
            <img
                src="../../../assets/images/documentation/setupaws/aws_organisations_access_portal.png"
                alt=""
                class="shapes screen-one"
            />
          </div>
          <h3>
            Let's begin!
          </h3>
          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";

export default {
  name: 'SetUpAws',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {

    }
  }
}
</script>



// useCreateCheckoutSessionForOneTimePayments.js

import axios from 'axios';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import { GENERAL_CONSTANTS } from '@/constants/generalConstants';
import {fetchAuthSession} from "aws-amplify/auth";
import {useI18n} from "vue-i18n";
import {isCdkProd} from "@/composables/useEnvironment";

export default function useCreateCheckoutSessionForOneTimePaymentsLemonSqueezy() {
    const toast = useToast();
    const { t } = useI18n();

    const router = useRouter();

    const createCheckoutSessionForOneTimePaymentLemonSqueezy = async (item, creatingSession) => {
        const session = await fetchAuthSession({forceRefresh: true}).catch(() => {
            toast.info(t('toasts.pleaseSignIn'));
            router.push('/signup');
            return null;
        });

        if (!session) return;

        creatingSession.value = true;
        creatingSession.value = item.id;
        try {
            const response = await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/create-checkout-session-lemonsqueezy`,
                {
                    productOptions: {
                        "redirect_url": `${window.location.origin}/documentation/get-started?${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_KEY}=${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_SUCCESS}`
                    },
                    testMode: !isCdkProd(),
                    store: {
                        "data": {
                            "type": "stores",
                            "id": process.env.VUE_APP_LEMON_SQUEEZY_STORE_ID
                        }
                    },
                    variant:{
                        "data": {
                            "type": "variants",
                            "id": item.priceId
                        }
                    }
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${session.tokens.accessToken}`
                    }
                }
            );

            if (response.data && response.data.customBody.url) {
                window.location.href = response.data.customBody.url;
            }
        } catch (error) {
            toast.error(`Error creating checkout session: ${error.message}`);
        } finally {
            creatingSession.value = false;
        }
    };

    const createCheckoutSessionForOneTimePaymentWithoutLoginLemonSqueezy = async (item, creatingSession) => {
        creatingSession.value = true;
        creatingSession.value = item.id;

        try {
            const response = await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/create-checkout-session-without-login-lemonsqueezy`,
                {
                    productOptions: {
                        "redirect_url": `${window.location.origin}/documentation/get-started?${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_KEY}=${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_SUCCESS}`
                    },
                    testMode: !isCdkProd(),
                    store: {
                        "data": {
                            "type": "stores",
                            "id": process.env.VUE_APP_LEMON_SQUEEZY_STORE_ID
                        }
                    },
                    variant:{
                        "data": {
                            "type": "variants",
                            "id": item.priceId
                        }
                    },
                    fromNoLogin: "yes"
                },
            );

            if (response.data && response.data.customBody.url) {
                window.location.href = response.data.customBody.url;
            }
        } catch (error) {
            toast.error(`Error creating checkout session: ${error.message}`);
        } finally {
            creatingSession.value = false;
        }
    };

    return {
        createCheckoutSessionForOneTimePaymentLemonSqueezy,
        createCheckoutSessionForOneTimePaymentWithoutLoginLemonSqueezy,
    };
}

<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Deployment on AWS</h2>
          <h3>What will we do?</h3>
          <p>
            In this section, we will deploy both frontend and backend of our application on AWS, as well as configure the CI/CD pipeline so that deployments are done on git commands. After that, you will have a working website with authentication.
          </p>
          <div class="documentation-image">
            <img
                src="../../../assets/images/documentation/deployment/initial_run.gif"
                alt=""
                class="shapes screen-one"
            />
          </div>
          <h3>
            Let's begin!
          </h3>
          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";

export default {
  name: 'InfraDeployment',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {

    }
  }
}
</script>



import {fetchAuthSession, getCurrentUser} from 'aws-amplify/auth';

export function useAuth() {
    async function refreshAuthStateInStore(authStore, avoidClearingEmailOnError) {
        await getCurrentUser()
            .then((user) => {
                if (!authStore.currentEmail) {
                    let parts = user.username.split("_");
                    let providerName = "";
                    if (parts.length > 1 && parts[0]) {
                        let rawProviderName = parts[0];
                        providerName = rawProviderName.charAt(0).toUpperCase() + rawProviderName.slice(1);
                        authStore.$patch({
                            currentProviderName: providerName,
                        })
                    }

                    const loginId = user.signInDetails?.loginId;
                    if (loginId) {
                        authStore.$patch({
                            currentEmail: loginId,
                        })
                    }
                }
            }).catch(() => {
                if (!avoidClearingEmailOnError) {
                    authStore.$patch({
                        currentEmail: null,
                    })
                }
            });
    }

    async function refreshAuthStateInCognitoCache(authStore) {
        await fetchAuthSession()
            .then((session) => {
                if (Object.prototype.hasOwnProperty.call(session.tokens.idToken.payload, 'identities')) {
                    const providerName = session.tokens.idToken.payload.identities[0].providerName;
                    authStore.$patch({
                        currentProviderName: providerName,
                    })
                }

                if (Object.prototype.hasOwnProperty.call(session.tokens.idToken.payload, 'email')) {
                    authStore.$patch({
                        currentEmail: session.tokens.idToken.payload.email,
                    })
                }
            }).catch(() => {
                authStore.$patch({
                    currentEmail: null,
                })
            });
    }

    return {refreshAuthStateInStore, refreshAuthStateInCognitoCache};


}

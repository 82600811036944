<template>
  <div class="user-data-page clearfix">
    <div class="form-wrapper">
      <form @submit.prevent="onSubmit" class="user-data-form">
        <hr class="delimiter-80">
        <div class="row">
          <div v-if="!store.currentEmail"
               class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('signupConfirm.typeEmailAgain') }}</label>
              <input required type="email" :placeholder="$t('signupConfirm.emailPlaceholder')" v-model="email"/>
            </div>
          </div>
          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('signupConfirm.enterCodeSent') }}</label>
              <input
                  ref="passwordInput"
                  required
                  type="text"
                  :placeholder="$t('signupConfirm.codePlaceholder')"
                  class="pass_log_id"
                  v-model="code"
                  data-test="code-input"
              />
            </div>
          </div>

          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <button class="theme-btn-one mb-50" data-test="submit-button"
                      :disabled="isProcessing">
                <span v-if="isProcessing" class="spinner"
                      style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('signupConfirm.activateButton') }}</span>
              </button>
            </div>
          </div>

          <div class="input-group-meta mt-30 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <p class="global-link" @click="sendCode">{{ $t('signupConfirm.sendNewCodeLink') }}</p>
            </div>
          </div>
        </div>
      </form>
    </div> <!-- /.form-wrapper -->
  </div>
</template>


<script>
import {ref} from 'vue';
import {useToast} from "vue-toastification";
import {useRouter} from 'vue-router';
import {autoSignIn, confirmSignUp} from 'aws-amplify/auth';
import {useAuthStore} from "@/store/auth";
import {useI18n} from 'vue-i18n';
import {useSendCode} from '@/composables/useSendNewCode';

export default {
  name: 'SaasInfraSignUpConfirmArea',
  setup() {
    const {t} = useI18n();
    const toast = useToast();
    const router = useRouter();
    const store = useAuthStore();
    const toastTimeout = ref(4000);
    const code = ref(null);
    const email = ref(null);
    const isProcessing = ref(null);
    const {sendNewCode} = useSendCode();

    const confirmSignUpEmailPassword = async () => {
      const username = store.currentEmail || email.value;
      try {
        isProcessing.value = true;
        await confirmSignUp({username: username, confirmationCode: code.value});
        await autoSignIn();
        toast.success(t('toasts.emailConfirmed'), {timeout: toastTimeout.value});
        router.push('/account');
      } catch (error) {
        toast.error(t('toasts.confirmSignUpError', {error: error.message}), {timeout: toastTimeout.value});
      } finally {
        isProcessing.value = false;
      }
    };

    const sendCode = async() => {
      await sendNewCode(email.value)
    }

    const onSubmit = () => {
      confirmSignUpEmailPassword();
    };

    return {
      store,
      code,
      email,
      sendCode,
      onSubmit,
      isProcessing
    };
  },
};
</script>

<template>
  <div class="doc-pagination pt-40">
    <ul class="d-flex align-items-center justify-content-between">
      <li class="d-flex align-items-center doc-pagination-left-arrow">
        <router-link :to="getPreviousLinkAndTextKey().link" v-if="getPreviousLinkAndTextKey().link">
          ←
        </router-link>
        &nbsp;&nbsp;&nbsp;
        <span v-if="getPreviousLinkAndTextKey().link">{{$t(getPreviousLinkAndTextKey().textKey)}}</span>
      </li>
      <li class="d-flex align-self-end doc-pagination-right-arrow">
        <span v-if="getNextLinkAndTextKey().link">{{$t(getNextLinkAndTextKey().textKey)}}</span> &nbsp;&nbsp;&nbsp;
        <router-link :to="getNextLinkAndTextKey().link" v-if="getNextLinkAndTextKey().link">
          →
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import {useDocumentationNavigation} from "@/composables/useDocumentationNavigation";

export default {
  name: 'DocumentationPagination',
  setup() {
    const route = useRoute();
    const documentationNavigation = useDocumentationNavigation();
    const sections = documentationNavigation.sections;

    const getPreviousIndex = () => {
      const allLinks = sections.value.flatMap(section => section.links);
      return allLinks.findIndex(link => link.to === route.path) - 1;
    }

    const getNextIndex = () => {
      const allLinks = sections.value.flatMap(section => section.links);
      return allLinks.findIndex(link => link.to === route.path) + 1;
    }

    const getPreviousLinkAndTextKey = () => {
      const allLinks = sections.value.flatMap(section => section.links);
      const prevIndex = getPreviousIndex();
      return {
        link: allLinks[prevIndex]?.to,
        textKey: allLinks[prevIndex]?.textKey
      };
    }

    const getNextLinkAndTextKey = () => {
      const allLinks = sections.value.flatMap(section => section.links);
      const nextIndex = getNextIndex();
      return {
        link: allLinks[nextIndex]?.to,
        textKey: allLinks[nextIndex]?.textKey
      };
    }

    return {
      getPreviousLinkAndTextKey,
      getNextLinkAndTextKey
    }
  }
};
</script>

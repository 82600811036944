/* eslint-disable */

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const localhost_with_port = "http://localhost:8080/"

const isFrontendHosted = Boolean(
    process.env.NODE_ENV === "production"
)

const federatedAfterSignInPage = process.env.VUE_APP_REDIRECT_PATH

const awsconfig = {
    "aws_project_region": process.env.VUE_APP_AWS_PROJECT_REGION,
    "aws_cognito_identity_pool_id": process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.VUE_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.VUE_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    mandatorySignIn: true,
    cookieStorage: {
        domain: isLocalhost ? "localhost" : window.location.hostname,
        secure: isFrontendHosted,
        sameSite: 'strict',
        path: '/',
        expires: 10,
    },
    "oauth": {
        "domain": process.env.VUE_APP_COGNITO_DOMAIN,
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": isLocalhost ? `${localhost_with_port}${federatedAfterSignInPage}` : `https://${window.location.hostname}/${federatedAfterSignInPage}`,
        "redirectSignOut": isLocalhost ? localhost_with_port : `https://${window.location.hostname}/`,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsconfig;

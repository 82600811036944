<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Troubleshooting</h2>

          <h3>Troubleshooting backend</h3>

          <p>If you have any problems deploying the backend resources and CI/CD for them, you can inspect the resources.</p>
          <ul class="list-item-one">
            <li>
              To check what went wrong go to the  <span class="font-roboto-medium">CloudFormation</span> of the dev or prod AWS account and see the CloudFormation stacks.
              <ul class="list-item-one">
              <li class="mt-10">
                <span class="font-roboto-medium">CDKToolkit</span>  - stack created when you bootstrap the account for CDK.
              </li>
                <li>
                  <span class="font-roboto-medium">SaasConstructPipelineStack-[dev or prod] </span> - stack containing resources for your CI/CD: AWS CodePipeline.
                </li>
                <li>
                  <span class="font-roboto-medium">SaasConstruct-AppStack-[dev or prod] </span> - stack containing resources for your backend: database, AWS Cognito, etc.
                </li>
              </ul>
            </li>
            <div class="documentation-image">
              <img
                  src="../../../../assets/images/documentation/deployment/cloudformation_stacks.png"
                  alt=""
                  class="shapes screen-one"
              />
            </div>
            <li class="mt-25">
              Select the stack that has problems (usually its status will be in red colour). Go to the <span class="font-roboto-medium">Events</span> tab.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/cloudformation_events.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-15">
              Inspect the issue and resolve it.
            </li>
            <li class="mt-15">
              Redeploy (just commit any code changes to the GitHub repository).
            </li>
            <li class="mt-15">
              If necessary, you can also do a <span class="font-roboto-medium">complete restart</span>: you can always delete all stacks (click on the button 'Delete' for each stack) and deploy them again (repeat the steps in the documentation).
              <InfoContainer class="mt-15">
                <span class="font-roboto-medium">INFO</span>

                <ul class="list-item-one">
                  <p> After deleting the stacks, make sure you also manually delete the following resources (just go to the corresponding service in your dev or prod accounts):</p>
                    <li>
                      AWS SES identities
                    </li>
                  <li>
                    S3 buckets
                  </li>
                  <li>
                    DynamoDB tables
                  </li>
                  <li>
                    Cognito user pool
                  </li>
                  <li>
                    CloudWatch log groups
                  </li>
                </ul>
              </InfoContainer>
            </li>
            </ul>



          <h3 style="margin-top: 30px !important;">Troubleshooting frontend</h3>
          <p>Usually you won't need to, but you can always review deployments of your frontend in AWS Amplify Hosting and access the logs to see what went wrong.</p>
          <ul class="list-item-one">
            <li>
              Go to the AWS Amplify and select your app. Select the branch and inspect <span class="font-roboto-medium">'Build'</span> or <span class="font-roboto-medium">'Deploy'</span> stage, you will see the logs in a dropdown section.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/amplify_select_branch.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/amplify_select_build.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              Inspect the issue and resolve it.
            </li>
            <li>
              Redeploy.
            </li>
            </ul>




          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import InfoContainer from "@/components/service-documentation/commons/InfoContainer.vue";

export default {
  name: 'TroubleShooting',
  components: {
    InfoContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {

    return {

    }
  }
}
</script>




<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Install tools</h2>
          <h3>Tools for your local machine</h3>
          <p>
            To continue, we need to set up some tools for your local machine.
            <br>
          </p>
          <p>Please install the following tools (as mentioned in <a href="https://docs.aws.amazon.com/cdk/v2/guide/getting_started.html#getting_started_cli" target="_blank" rel="noopener noreferrer" class="underline-link">AWS documentation)</a>:</p>
          <ul class="list-item-one">
            <li>
              AWS CLI from <a href="https://docs.aws.amazon.com/cli/latest/userguide/getting-started-install.html" target="_blank" rel="noopener noreferrer" class="underline-link">here</a>.
            </li>
            <li>
              Node.js (version 20 or higher) from <a href="https://nodejs.org/en" target="_blank" rel="noopener noreferrer" class="underline-link">here</a>. We recommend a version in <a href="https://nodejs.org/en/about/previous-releases" target="_blank" rel="noopener noreferrer" class="underline-link">active long-term support</a>.
            </li>
            <li>
              Python (recommended version is 3.11) from <a href="https://www.python.org/downloads/release/python-3118/" target="_blank" rel="noopener noreferrer" class="underline-link">here</a>.
            </li>
            <li>
              Pip, the Python package manager from <a href="https://pip.pypa.io/en/stable/installation/" target="_blank" rel="noopener noreferrer" class="underline-link">here</a>.
            </li>
            <li>
              virtualenv, the Python virtual environment manager from <a href="https://virtualenv.pypa.io/en/latest/installation.html" target="_blank" rel="noopener noreferrer" class="underline-link">here</a>.
            </li>
            <li>
              AWS CDK CLI from <a href="https://docs.aws.amazon.com/cdk/v2/guide/getting_started.html#getting_started_install" target="_blank" rel="noopener noreferrer" class="underline-link">here</a>.
            </li>
            <li>
              If you don't already have Git installed, you can download it from <a href="https://git-scm.com/book/en/v2/Getting-Started-Installing-Git" target="_blank" rel="noopener noreferrer" class="underline-link">here</a>.
            </li>
            </ul>

          <p>Great! Let's start with the deployment!</p>

          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";

export default {
  name: 'DocsInstallTools',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {

    }
  }
}
</script>



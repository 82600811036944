<template>
  <SaasInfraHeader/>
  <div class="main-page-wrapper overflow-hidden">
    <div class="error-page d-lg-flex align-items-center">
      <div class="text-wrapper order-lg-first">
        <h1 class="font-roboto">{{$t('404.title')}}</h1>
        <router-link to="/" class="back-home font-roboto d-flex align-items-center justify-content-center">
          <span>{{$t('404.backToHome')}}</span>
          <img src="../assets/images/icon/53.svg" alt="">
        </router-link>
      </div> <!-- /.text-wrapper -->
    </div>
  </div>
</template>

<script>
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";

export default {
  name: "NotFound",
  components: {SaasInfraHeader}
}
</script>

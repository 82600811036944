<template>
  <SignInAreaMain/>
</template>

<script>
import SignInAreaMain from '../components/signin';

export default {
  name:'SignInPage',
  components:{SignInAreaMain}
}
</script>

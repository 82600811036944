<template>
  <div class="md-pt-50" id="pricing">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 m-auto">
          <div class="title-style-six text-center mb-15">
            <h2>{{ $t('pricingForSubscriptionsArea.pricingPlans') }}</h2>
            <h4 class="mt-20">{{ $t('pricingForSubscriptionsArea.yearSubscriptionOffer') }}</h4>
            <SliderComponent @update:billing="updateBillingOption"/>
          </div>
        </div>
      </div>
    </div>

    <div class="pricing-table-area-four">
      <div class="tab-content">
        <div class="tab-pane active" id="month">
          <div class="row justify-content-center">
            <div
                v-for="item in selectedPricingPlans"
                :key="item.id"
                class="col-lg-4 col-sm-6"
            >
              <div class="pr-table-wrapper">
                <div class="pack-name">{{ $t(item.pack_name_key) }}</div>

                <!-- /.top-banner -->
                <ul class="pr-feature" style="padding: 20px 0 20px 0;">
                  <li v-for="feature in item.feature" :key="feature.id">
                    {{ $t(feature.text_key) }}
                  </li>
                </ul>
                <div
                    class="top-banner d-md-flex"
                >
                  <div>
                    <div><span>${{ item.price }}</span>/{{ $t(item.info_span_key) }}</div>
                    <em>{{ $t(item.info_em_key) }}</em>
                  </div>
                </div>
                <button
                    class="trial-button"
                    @click="createCheckoutSession(item)"
                    :class="{loading: creatingSession === item.id, 'disabled-pay-button': (subscriptionStore.activeSubscriptions && subscriptionStore.activeSubscriptions.includes(item.pack_name_en))}"
                    :disabled="creatingSession === item.id || (subscriptionStore.activeSubscriptions && subscriptionStore.activeSubscriptions.includes(item.pack_name_en)) || fetchingSubscriptions"
                >
                  <span v-if="creatingSession === item.id || fetchingSubscriptions" class="spinner"></span>
                  <span v-else-if="subscriptionStore.activeSubscriptions && subscriptionStore.activeSubscriptions.includes(item.pack_name_en)">{{ $t('pricingForSubscriptionsArea.subscribed') }}</span>
                  <span v-else>{{ $t('pricingForSubscriptionsArea.subscribe') }}</span>
                </button>
              </div>
              <!-- /.pr-table-wrapper -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.pricing-table-area-four -->
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue';
import {useToast} from "vue-toastification";
import {useRouter} from 'vue-router';
import useFetchActiveSubscriptions from "@/composables/useFetchActiveSubscriptions";
import {useSubscriptionsStore} from "@/store/subscriptions";
import useCreatePortalSession from "@/composables/useCreatePortalSession";
import usePricingPlans from "@/composables/usePricingPlans";
import axios from 'axios';
import {GENERAL_CONSTANTS} from "@/constants/generalConstants";
import SliderComponent from '@/components/slider/PricingSlider.vue';
import {useLanguageStore} from "@/store/language";
import {useI18n} from "vue-i18n";
import {fetchAuthSession} from "aws-amplify/auth";


export default {
  name: "PricingArea",
  components: {
    SliderComponent
  },
  setup() {
    const creatingSession = ref(null);
    const router = useRouter();
    const toast = useToast();
    const { t } = useI18n();
    const toastTimeout = ref(4000)
    const subscriptionStore = useSubscriptionsStore()
    const {fetchingSubscriptions, fetchActiveSubscriptions} = useFetchActiveSubscriptions();
    const {createPortalSession} = useCreatePortalSession();
    const {pricingPlansMonthly, pricingPlansYearly} = usePricingPlans();

    const isMonthlyBilling = ref(true);
    const languageStore = useLanguageStore();

    const updateBillingOption = (option) => {
      isMonthlyBilling.value = (option === "monthly");
    };

    const selectedPricingPlans = computed(() => {
      return isMonthlyBilling.value ? pricingPlansMonthly.value : pricingPlansYearly.value;
    });
    const createCheckoutSession = async (item) => {
      if (subscriptionStore.activeSubscriptions &&
          subscriptionStore.activeSubscriptions?.length > 0) {
        creatingSession.value = item.id;
        await createPortalSession(languageStore.language);
        creatingSession.value = null;
        return;
      }
      // Attempt to authenticate the user and get the token
      const session = await fetchAuthSession({forceRefresh: true})
          .catch(() => {
            toast.info(t('toasts.pleaseSignIn'), {
              timeout: toastTimeout.value
            });
            router.push('/signin');
            return null;
          });

      if (!session) return;

      try {
        creatingSession.value = item.id;
        const response = await axios.post(process.env.VUE_APP_BACKEND_URL + "/create-checkout-session", {
          priceId: item.priceId,
          successUrl: `${window.location.origin}/account?${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_KEY}=${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_SUCCESS}`,
          cancelUrl: `${window.location.origin}/#pricing?${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_KEY}=${GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_FAIL}`,
          mode: item.paymentMode,
          locale: languageStore.language
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.tokens.accessToken}`
          }
        });

        if (response.data && response.data.customBody.url) {
          window.location = response.data.customBody.url;
        }

      } catch (err) {
        // Handle other potential errors here
        toast.error(t('toasts.createCheckoutSessionError', { error: err }), {
          timeout: toastTimeout.value
        });
      } finally {
        creatingSession.value = null;
      }
    };


    onMounted(() => {
      if (!subscriptionStore.activeSubscriptions) {
        fetchActiveSubscriptions();
      }
    });


    return {
      creatingSession,
      pricingPlansYearly,
      createCheckoutSession,
      fetchingSubscriptions,
      subscriptionStore,
      updateBillingOption,
      selectedPricingPlans
    };
  }
};
</script>





<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Creating an AWS Organisation</h2>
          <h3>AWS Organisation</h3>

          <p>AWS Organisation is a group of AWS accounts. It allows you to have
            one place (AWS access portal) to access all the accounts you specify.</p>
          <p>
            We will need development and production AWS accounts.
            We will add them through AWS Organisation.
          </p>

          <h3>1. Create an AWS Organisation</h3>
          <ul class="list-item-one">
            <li>
              Go to your <a href="https://console.aws.amazon.com/console/home?nc2=h_ct&src=header-signin" target="_blank" rel="noopener noreferrer" class="underline-link">AWS account.</a>
            </li>
            <li>
              Navigate to <a href="https://us-east-1.console.aws.amazon.com/organizations" target="_blank" rel="noopener noreferrer" class="underline-link">AWS Organisation</a>. You can use the search bar at the top.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/navigate.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              Click <span class="font-roboto-medium">'Create an organisation'.</span> Wait for the creation to complete.
            </li>
            <li>
              Click <span class="font-roboto-medium">'Add account'.</span>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/add_account.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li>
              <span class="font-roboto-medium">Fill in the forms and add an account.</span>
              You need to do this twice, once for the dev account and once for the prod account. <br>
               For one account use name <span class="font-roboto-medium">dev</span> and for other use
              <span class="font-roboto-medium">prod</span>.
              <br>Leave the OrganizationAccountAccessRole as it is. <br> Emails should be unique and not asssociated
              with the account you created earlier. <br>
              For email you can either use different emails you have, subdomain emails, or simply + sign. Example: if you
              have an email myemail@example.com, you can simply add a plus sign. Result is myemail+dev@example.com and
              myemail+prod@example.com.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/add_account_forms.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>
          <p>Before we go any further, we need to decide in which region we will create cloud resources.</p>
          <h3>2. Choose a region</h3>
          <p>This is where you decide which region you will create your resources in.</p>
            <ul class="list-item-one">
              <li>
                Go to your <a href="https://console.aws.amazon.com/console/home?nc2=h_ct&src=header-signin" target="_blank" rel="noopener noreferrer" class="underline-link">AWS account.</a>
              </li>
              <li>
                Select a region (top right corner). To ensure availability of all features, recommended regions are:
                <ul class="list-item-one" style="padding-left: 25px">
                  <li>us-east-1 (popular with US users)</li>
                  <li>us-west-2</li>
                  <li>eu-central-1 (popular with European users)</li>
                  <li>eu-west-1</li>
                  <li>eu-west-2</li>
                  <li>eu-west-3</li>
                  <li>ap-south-1 (popular with Indian users)</li>
                  <li>ap-southeast-2</li>
                </ul>
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/regions.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>
              <p class="mt-15">
                If you prefer to choose a different region, make sure the services are available. <br>
                You can use <a href="https://aws.amazon.com/about-aws/global-infrastructure/regional-product-services/"
                               target="_blank" rel="noopener noreferrer" class="underline-link">this AWS
                documentation</a> to check. <br>
                Check the following services: Bedrock, Amplify, Cloudfront, Secret Manager, Api Gateway, Lambda,
                Cognito, SES, DynamoDb, S3, CloudWatch, CodePipeline.
              </p>
            </ul>

          <h3>3. Create a permission set</h3>
          <p>Permission sets allow users to access AWS accounts.</p>
          <ul class="list-item-one">
            <li>
              Navigate to <a href="https://us-east-1.console.aws.amazon.com/singlesignon" target="_blank" rel="noopener noreferrer" class="underline-link">IAM Identity Center</a>.
            </li>
            <li>
              Select a region that you chose previously.
            </li>
            <li>
              Click <span class="font-roboto-medium">'Enable'.</span>
            </li>
            <li>
              Click <span class="font-roboto-medium">'Permission sets'.</span>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/permission_sets.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-15">
              Click <span class="font-roboto-medium">'Create permission set'.</span>
            </li>
            <div class="documentation-image">
              <img
                  src="../../../../assets/images/documentation/setupaws/create_permission_set.png"
                  alt=""
                  class="shapes screen-one"
              />
            </div>
            <li class="mt-15">
              Ensure that the type is 'Predefined permission set' and the policy is 'Administrator Access'. Then click <span class="font-roboto-medium">'Next'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/choose_set.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-15">
              Name the permission set, select the duration (8 hours or less) and click 'Next'. On the next page, click <span class="font-roboto-medium">'Create'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/create_set.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>
          <p>Great, you've created a set of permissions that we'll use to access the accounts.</p>

          <h3>4. Create an Admin group and add a user to it</h3>
          <p>Now we will create a group and add a user to it. We will use this user to access the AWS accounts.</p>
          <ul class="list-item-one">
            <li>
              Navigate to <a href="https://us-east-1.console.aws.amazon.com/singlesignon" target="_blank" rel="noopener noreferrer" class="underline-link">IAM Identity Center</a>, click <span class="font-roboto-medium">'Groups'</span>, and then <span class="font-roboto-medium">'Create group'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/create_group.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-15">
              Name it AdminGroup and click on <span class="font-roboto-medium">'Create Group'</span>. Don't click on 'Add a new user'.
            </li>
            <li class="mt-15">
              Click <span class="font-roboto-medium">'Users'</span>, and then <span class="font-roboto-medium">'Add user'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/add_user.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-15">
              Fill in the forms and click <span class="font-roboto-medium">'Next'</span>. Email can be any email you have.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/create_user.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>
            <li class="mt-15">
              Choose AdminGroup and click <span class="font-roboto-medium">'Next'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/add_user_to_group.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              </li>
            <li class="mt-15">
              On Review page click <span class="font-roboto-medium">'Add user'</span>.
              </li>
            <li class="mt-15">
              You will receive an email from AWS with an invitation. Accept it and create a password for the user.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/setupaws/create_password.png"
                    alt=""
                    style="width: 50%; height: 50%"
                    class="shapes screen-one"
                />
              </div>
              <p>
                You've just created a user. This user will be used to access both the dev and prod AWS accounts. We just need to add this user to these accounts.
              </p>
            </li>

            <h3>5. Add user to dev and prod accounts</h3>
            <p>Now all we need to do is add the user to the dev and prod accounts.</p>
            <ul class="list-item-one">
              <li>
                Navigate to <a href="https://us-east-1.console.aws.amazon.com/singlesignon" target="_blank" rel="noopener noreferrer" class="underline-link">IAM Identity Center</a>. Click 'AWS accounts'.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/aws_accounts_navigate.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>
              <li class="mt-15">
                <span class="font-roboto-medium">Select dev and prod accounts</span> and click  <span class="font-roboto-medium">'Assign users or groups'</span>.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/assign_users_to_aws_account.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>
              <li class="mt-15">
                Choose AdminGroup and click <span class="font-roboto-medium">'Next'</span>.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/add_group_to_account.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>
              <li class="mt-15">
                Choose your permission set and click <span class="font-roboto-medium">'Next'</span>. On the next page click <span class="font-roboto-medium">'Submit'</span>.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/choose_permission_set.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>
              </ul>
            <p>Congratulations, let's check your access now!</p>

            <h3>6. Verify the access and enable multi-factor authentication</h3>
            <ul class="list-item-one">
              <li>
                Navigate to <a href="https://us-east-1.console.aws.amazon.com/singlesignon" target="_blank" rel="noopener noreferrer" class="underline-link">IAM Identity Center</a>. Go to <span class="font-roboto-medium">'Dashboard'</span>. Copy the <span class="font-roboto-medium">'AWS access portal URL'</span>. We also recommend that you bookmark this URL in your browser.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/access_portal_url.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>

              <li class="mt-15">
                Go to the URL. Put a name of the user and password.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/username_access_portal.png"
                      alt=""
                      style="width: 50%; height: 50%"
                      class="shapes screen-one"
                  />
                </div>

              </li>
              <li class="mt-15">
                Enable multi-factor authentication.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/mfa_access_portal.png"
                      alt=""
                      style="width: 50%; height: 50%"
                      class="shapes screen-one"
                  />
                </div>
              </li>

              <li class="mt-15">
                Once this is done, you will be able to see the Access Portal and access the dev and prod accounts.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/setupaws/aws_organisations_access_portal.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>
            </ul>

            <h3>Conclusion</h3>
            <p>Now you can access your dev and prod AWS accounts from one place. Congratulations! </p>
            <p>What we've done so far: </p>
            <ul class="list-item-one">
              <li>
                Created an AWS organisation and added dev and prod AWS accounts.
              </li>
              <li>
                Chosen a region.
              </li>
              <li>
                Created a permission set, an admin group and a user.
              </li>
              <li>
                Gave the user access to the dev and prod accounts.
              </li>
            </ul>
            <p>Now let's install the necessary tools.</p>
            </ul>







          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";

export default {
  name: 'DocsCreateOrganisation',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {}
  }
}
</script>



<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">

          <h2>Add social providers</h2>

          <h3>What will we do?</h3>
          <p>
            In this section, we will add social sign-in flows, such as one from Google, so that users can signup/signin in to the website using their Google account.
          </p>
          <div class="documentation-image">
            <img
                src="../../../assets/images/documentation/socialproviders/google_sign_in.gif"
                alt=""
                class="shapes screen-one"
            />
          </div>

          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";

export default {
  name: 'DocsSocialProviders',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {};
  }
}
</script>

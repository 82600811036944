<template>
  <div class="clearfix">
    <SaasInfraHeader/>
    <DeployBackend/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import DeployBackend from "@/components/service-documentation/infra-deployment/deploy-backend/DeployBackend.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";


export default {
  name: 'InfraDeployBackend',
  components: {SaasInfraHeader, SaasInfraFooter, DeployBackend},
}
</script>

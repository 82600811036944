<template>
  <a
      :href="link"
      :class="['link-to-always', customClass || 'link-to-default']"
      :data-bs-toggle="dataToggle"
      :data-bs-target="dataTarget"
      :style="customStyle"
      target="_blank"
      v-bind="$attrs"
  >
    <span>{{ linkLabel }}</span>
  </a>
</template>

<script>
export default {
  name: 'LinkTo',
  props: {
    isMakingRequest: Boolean,
    linkLabel: String,
    link: String,
    dataToggle: String,
    dataTarget: String,
    customClass: {
      type: String,
      default: ''
    },
    customStyle: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['click'],
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>


<style scoped>
.link-to-default {
  min-width: 150px;
  height: 49.5px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 50px;
  background: var(--blue-dark);
  padding: 0 35px;
  border-radius: 8px;
  transition:
      all 0.3s ease-in-out,
      background-color 0.3s,
      box-shadow 0.3s;
  cursor: pointer;
  white-space: nowrap;
}

.link-to-default:hover {
  background: #ffba12;
}

.link-to-always:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow:
      0 -1px 0 rgba(0, 0, 0, 0.04),
      0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

</style>

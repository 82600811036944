<template>
  <button
    :class="['request-button-always', customClass || 'request-button-default']"
    @click="handleClick"
    :disabled="isMakingRequest"
    :style="customStyle"
  >
    <span v-if="isMakingRequest">
      <template v-if="$slots.spinner">
        <!-- Slot for custom spinner -->
        <slot name="spinner"></slot>
      </template>
      <template v-else>
        <!-- Default spinner -->
        <span class="request-button-spinner"></span>
      </template>
    </span>
    <span v-else-if="buttonLabel">{{ buttonLabel }}</span>
    <span v-else-if="iconPath">
      <img class="customClassIcon" :src="iconPath" alt="Icon" />
    </span>
  </button>
</template>

<script>
export default {
  name: 'RequestButton',
  props: {
    isMakingRequest: Boolean,
    buttonLabel: String,
    customClass: {
      // Allows passing a custom class for additional styles
      type: String,
      default: ''
    },
    customStyle: {
      // Allows inline styles to be applied dynamically
      type: Object,
      default: () => ({})
    },
    iconPath: {
      // Path to the SVG icon
      type: String,
      default: ''
    },
    customClassIcon: {
      // Custom class for the icon styling
      type: String,
      default: ''
    }
  },
  emits: ['click'],
  setup(props, { emit }) {
    const handleClick = () => {
      emit('click')
    }

    return { handleClick }
  }
}
</script>

<style scoped>
.request-button-default {
  min-width: 150px;
  height: 49.5px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 50px;
  background: var(--blue-dark);
  padding: 0 35px;
  border-radius: 8px;
  transition:
    all 0.3s ease-in-out,
    background-color 0.3s,
    box-shadow 0.3s;
  cursor: pointer;
  white-space: nowrap;
}

.request-button-default:hover {
  background: #ffba12;
}


.request-button-always:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, 0.04),
    0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.request-button-spinner {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
}

@keyframes request-button-spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>

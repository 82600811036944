<template>
  <SaasInfraMain/>
</template>

<script>
import SaasInfraMain from '../components/saas-infra-main';

export default {
  name:'SaasInfra',
  components:{
    SaasInfraMain,
  }
}
</script>

<template>
  <BlogPostSimpleTechStackForAwsCloudAreaMain/>
</template>

<script>
import BlogPostSimpleTechStackForAwsCloudAreaMain from '@/components/blog/blog-post-the-tech-stack-of-a-simple-saas-for-aws-cloud/index.vue';

export default {
  name:'BlogPostSimpleTechStackForAwsCloudPage',
  components:{BlogPostSimpleTechStackForAwsCloudAreaMain}
}
</script>

<template>
  <div class="blog">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h2>{{ title }}</h2>
          <div>
            <img src="@/assets/images/assets/question_mark.svg" alt="" />
          </div>
          <div v-html="sanitizedContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';

export default {
  name: 'BlogPostStartingWithAwsWhatToLearnAndDoFirstMainArea',
  data() {
    return {
      title: 'Starting with AWS? What to learn and do first',
      content: `
   <h3>Introduction</h3>
<p>This is the kind of question that might arise if you are:</p>
<ul>
  <li>a student,</li>
  <li>someone who has worked with on-premise systems,</li>
  <li>someone who wants to pivot your career to cloud engineering,</li>
  <li>familiar with other clouds (GCP/Azure), but your new job requires AWS.</li>
</ul>

<p>I have worked with AWS for several years, and if I had to start over, this is how I would approach it.</p>

<p>First of all, getting certifications (I got one some years ago: AWS Machine Learning) is great and all, but I would not recommend it unless you are a consultant. Certificates are mostly about theory or memorizing documentation.</p>

<p>If you actually want to learn something, start building on AWS and read the documentation. That's all you need. AWS has great documentation.</p>

<h3>Access</h3>
<p>The first thing people do is create an AWS account with the root user and then continue to use that root user. Don't do it. Never use the root user. Here are the best practices for the root user:</p>
<p><a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/root-user-best-practices.html">Root User Best Practices</a></p>
<p>Always use MFA: <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/root-user-best-practices.html#ru-bp-mfa">MFA Information</a></p>

<p>It is important not to use the root user but to create an administrative user to manage your account. If you just have one account for now, just follow these docs: <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/getting-started-account-iam.html">Set up AWS account.</a></p>

<p>But in most cases, you will have several AWS accounts, so it is better to organize your AWS accounts into AWS Organizations and give access via an AWS access portal under a URL, so you will have one webpage where you can access all those accounts.</p>

<p>My usual setup in AWS Organizations is as follows:</p>
<ul>
  <li>Management account with consolidated billing enabled,</li>
  <li>Development account,</li>
  <li>Production account.</li>
</ul>
<p>Second, you should configure IAM Identity Center (you need it if you want to access/manage your accounts from one place, it is super convenient). Here are also the official documentation link: <a href="https://docs.aws.amazon.com/SetUp/latest/UserGuide/setup-configadminuser.html">AWS docs</a></p>
<p>Overall, for the initial AWS setup, I wrote a quick setup guide with AWS Organisations and IAM Identity Center, you can just follow it: <a href="https://saasconstruct.com/documentation/create-organisation">Setup Guide.</a></p>


<h3>Alarms</h3>
<p>The most important thing after proper access is to watch your AWS bill. The very first thing you should do is to set up a billing alarm (e.g., if it exceeds $10 per month).</p>
<p><a href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/monitor_estimated_charges_with_cloudwatch.html">Setup a Billing Alarm</a></p>

<p>You might also want to set up other alarms, but that depends on the service you use. For example, I have an alarm that notifies me if too many requests are coming through the API Gateway.</p>

<h3>Free Tier</h3>
<p>The Free Tier allows you to use some AWS resources for free, to a certain extent.</p>
<p>For example, I use AWS CodePipeline for my CI/CD: it rolls out a new version of the app after I commit to GitHub. One such pipeline is covered by the Free Tier, but not the others (if you have several).</p>
<p>It is important to at least check if the service you plan to implement is included in the Free Tier. You can check it here: <a href="https://aws.amazon.com/free/">AWS Free Tier</a></p>

<h3>Building a project</h3>
<p>AWS has a great number of whitepapers, guides, and blog posts to choose from:</p>
<p>AWS whitepapers: <a href="https://aws.amazon.com/whitepapers">AWS Whitepapers</a></p>
<p>AWS blog: <a href="https://aws.amazon.com/blogs/aws/">AWS Blogs</a></p>

<p>But to me, it is much more fun to actually build something. Here are some resources:</p>
<p>AWS samples: <a href="https://github.com/aws-samples">AWS Samples on GitHub</a></p>
<p>This is a collection of repositories where you can find a lot of projects to explore. Many of these examples have documentation and guides, just explore the repos. For example, if you are interested in infrastructure-as-code apps, you can check this repo: <a href="https://github.com/aws-samples/aws-cdk-examples">AWS CDK Examples</a></p>

<p>You can also check out some AWS workshops: <a href="https://serverlessland.com/learn?type=Workshops">AWS Workshops</a></p>

<h3>Building an actual product (SaaS)</h3>
<p>I’ve written a blog post on the architecture I've used to create a SaaS, which is based on AWS cloud infrastructure and uses Stripe (or LemonSqueezy) as a payments processor.</p>
<p><a href="https://saasconstruct.com/blog/the-tech-stack-of-a-simple-saas-for-aws-cloud">The Tech Stack of a Simple SaaS for AWS Cloud</a></p>

<p>I’ve also written an AWS SaaS template, which can be used to bootstrap a tech startup.</p>
<p>Demo of the template: <a href="https://demo.saasconstruct.com/">Demo SaaS Construct</a></p>
<p>You can get the template here: <a href="https://saasconstruct.com/#pricing">SaaS Construct Pricing</a></p>

<p>Hope this blog post was helpful :)</p>
      `,
    };
  },
  computed: {
    sanitizedContent() {
      const config = {
        ADD_ATTR: ['target'],
      };

      return DOMPurify.sanitize(this.content, config);
    }
  }

}
</script>

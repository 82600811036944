<template>
  <div class="terms_and_policies">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <!-- Tab panes -->
          <div class="tab-content">
            <div v-for="(item,index) in terms_data" :key="index"
                 :class="`tab-pane fade ${item.active ? 'active show' : ''}`">
              <h2 class="font-roboto">{{ item.title_main }}</h2>
              <h3>{{ item.title_main_intro }}</h3>
              <p v-html="item.text_intro_1"></p>
              <p v-html="item.text_intro_2"></p>
              <p v-html="item.text_intro_3"></p>
              <h3>{{ item.title_acc_terms }}</h3>
              <p v-html="item.text_acc_terms_1"></p>
              <p v-html="item.text_acc_terms_2"></p>
              <p v-html="item.text_acc_terms_3"></p>
              <p v-html="item.text_acc_terms_4"></p>
              <h3>{{ item.title_mods }}</h3>
              <p v-html="item.text_mods_1"></p>
              <p v-html="item.text_mods_2"></p>
              <p v-html="item.text_mods_3"></p>
              <h3>{{ item.title_copyright }}</h3>
              <p v-html="item.text_copyright_1"></p>
              <p v-html="item.text_copyright_2"></p>
              <p v-html="item.text_copyright_3"></p>
              <h3>{{ item.title_privacy }}</h3>
              <p v-html="item.text_privacy_1"></p>
              <h3>{{ item.title_general }}</h3>
              <p v-html="item.text_general_1"></p>
              <p v-html="item.text_general_2"></p>
              <p v-html="item.text_general_3"></p>
              <p v-html="item.text_general_4"></p>
              <p v-html="item.text_general_5"></p>
              <p v-html="item.text_general_6"></p>
              <p v-html="item.text_general_7"></p>
              <h3>{{ item.title_term }}</h3>
              <p v-html="item.text_term_1"></p>
              <p v-html="item.text_term_2"></p>
              <ul>
                <li>{{ item.text_term_3_list_1 }}</li>
                <li>{{ item.text_term_3_list_2 }}</li>
                <li>{{ item.text_term_3_list_3 }}</li>
                <li>{{ item.text_term_3_list_4 }}</li>
              </ul>
              <p v-html="item.text_term_4"></p>
              <h3>{{ item.title_ip }}</h3>
              <p v-html="item.text_ip_1"></p>
              <p v-html="item.text_ip_2"></p>
              <p v-html="item.text_ip_3"></p>
              <p v-html="item.text_ip_4"></p>
              <p v-html="item.text_ip_5"></p>
              <p v-html="item.text_ip_6"></p>
              <p v-html="item.text_ip_7"></p>
              <p v-html="item.text_ip_9"></p>
              <p v-html="item.text_ip_99"></p>
              <p v-html="item.text_ip_10"></p>
              <p v-html="item.text_ip_11"></p>
              <h3>{{ item.title_3rd_party }}</h3>
              <p v-html="item.text_3rd_party_1"></p>
              <h3>{{ item.title_non_compete }}</h3>
              <p v-html="item.text_non_compete_1"></p>
              <p v-html="item.text_non_compete_2"></p>
              <h3>{{ item.title_liability }}</h3>
              <p v-html="item.text_liability_1"></p>
<!--              <p v-html="item.text_liability_2"></p>-->
              <p v-html="item.text_liability_3"></p>
              <p v-html="item.text_liability_4"></p>
              <p v-html="item.text_liability_5"></p>
              <h3>{{ item.title_independent }}</h3>
              <p v-html="item.text_independent_1"></p>
              <h2 class="font-roboto">{{ item.title_subs }}</h2>
              <h3>{{ item.title_subs_term }}</h3>
              <p v-html="item.text_subs_term_2"></p>
              <p v-html="item.text_subs_term_3"></p>
              <p v-html="item.text_subs_term_4"></p>
              <p v-html="item.text_subs_term_5"></p>
            </div>
            <p>
              <em>{{ date_text }}</em>
            </p>
          </div> <!-- /.tab-content -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaasInfraTerms',
  data() {
    return {
      terms_data: [
        {
          active: true,
          title_main: 'SaasConstruct Terms and Conditions',
          title_main_intro: '1. Introduction',
          text_intro_1: "These SaasConstruct Terms and Conditions (\"Terms\", \"Terms and Conditions\") govern your relationship with saasconstruct.com (\"Service\"). When we say ”company”, “we”, “our”, “us”, ”service”, ”services”, ”infrastructure” and ”infrastructures”  in this document, we are referring to SaasConstruct.",
          text_intro_2: "When you use our services, now or in the future, you are agreeing to the latest Terms of Service. That’s true for any of our existing and future products and all features that we add to our service over time. There may be times where we do not exercise or enforce any right or provision of the Terms of Service; in doing so, we are not waiving that right or provision. These terms do contain a limitation of our liability.",
          text_intro_3: "If you do not agree to these Terms of Service, do not use this service. Violation of any of the terms below may result in the termination of your account. That’s a broad statement and it means you need to place a lot of trust in us. We do our best to deserve that trust by being open about who we are, how we work, and keeping an open door to your feedback.",
          title_acc_terms: '2. Account Terms',
          text_acc_terms_1: "You are responsible for maintaining the security of your account and password. We cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.",
          text_acc_terms_2: "You may not use our service for any illegal purpose or to violate any laws in your jurisdiction.",
          text_acc_terms_3: "You must provide a valid email address in order to complete the signup process.",
          text_acc_terms_4: "You must be a human. Accounts registered by bots or other automated methods are not permitted.",
          title_mods: '3. Modifications to the service and prices',
          text_mods_1: "We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, any part of the service with or without notice.",
          text_mods_2: "We may change the pricing structure for our products.",
          text_mods_3: "We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the service.",
          title_copyright: '4. Content ownership, copyright and trademark',
          text_copyright_1: "You are solely responsible for any content and other material that you submit, publish, transmit, email, or display on, through, or with the service.",
          text_copyright_2: "You may provide us with feedback, suggestions, and ideas about the service. You agree that we own all rights to use and incorporate the feedback you provide in any way, including in future enhancements and modifications to the service, without payment or attribution to you.",
          text_copyright_3: "You must not modify another website so as to falsely imply that it is associated with us. The look and feel of the service is copyright© to us. All rights reserved. SaasConstruct and its logo and any other product or service name or slogan displayed on the service are trademarks of the company and may not be copied, imitated or used, in whole or in part, without the prior written permission of the company or the applicable trademark holder. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the service, use of the service, or access to the service without the express written permission from us.",
          title_privacy: '5. Privacy',
          text_privacy_1: "Your access to and use of the Service is also conditioned on your acceptance of and compliance with our Privacy Policy. Our Privacy Policy describes our policies and procedures on the collection, use and disclosure of your personal information when you access our websites or use our Service and tells you about your privacy rights and how the law protects you. Please read Our Privacy Policy carefully before using our Service.",
          title_general: '6. General',
          text_general_1: "Your use of SaasConstruct is at your sole risk. The software is provided on an “as is” and “as available” basis.",
          text_general_2: "We design our infrastructures with care, based on our own experience and the experiences of customers who share their time and feedback. However, there is no such thing as a infrastructure that pleases everybody. We make no guarantees that our services and infrastructures will meet your specific requirements or expectations.",
          text_general_3: "As with any software, our services inevitably have some bugs. We don’t guarantee completely error-free services.",
          text_general_4: "Technical support is provided by email or by Discord group. Responses are provided on the reasonable-effort basis without guaranteed response.",
          text_general_5: "We use third party vendors to provide the necessary hardware, storage, payment processing and related technology required to run the infrastructures.",
          text_general_6: "The Services should not be used by anyone under the age of 18.",
          text_general_7: "If it turns out we owe taxes on your purchase, you agree to pay all applicable taxes.",
          title_term: '7. Term and Termination',
          text_term_1: "The product is one-time purchase.",
          text_term_2: "We can terminate your account and Services, if you breach Terms or if:",
          text_term_3_list_1: "you fail to timely pay any amount owed to us;",
          text_term_3_list_2: "you are or become subject to any proceeding under the Bankruptcy Code or similar laws;",
          text_term_3_list_3: "we, in our sole discretion and in good faith, believe you use the services to compete with us;",
          text_term_3_list_4: "if, in our sole discretion, we believe that you continued use of the Services creates legal risk for us or presents a threat to the security of the Services or our customers.",
          text_term_4: "We may terminate these Terms in their entirety, or the terms of any specific Service, at any time upon the material breach of these Terms or any published policy applicable to the Services by you (or an Authorized User).",
          title_ip: "8. Intellectual Property Rights",
          text_ip_1: "<b>Customer License.</b> Provided that you completed the purchase, and provided we have not terminated or suspended all, or any portion of, the Terms or your Services, we hereby grant you a worldwide, non-exclusive, non-transferable, non-sublicensable license for you to use, install, test, execute, perform, and copy the Services exclusively for your business use, and to create derivative works or otherwise modify the Services (including source code of the Services) purely for your own purposes (the “License”). The licence covers only the code we have written (\"Source Code\") and does not include any third party libraries. We make no warranties about third party libraries and their use now or in the future. Customers are responsible for ensuring that all third party libraries they install, including those listed in the SaaSConstruct source code, are suitable for their use-case and if they (customers) have necessary permissions.",
          text_ip_2: "After the purchase, you can use the code, except in similar manner as us (see Use of Services to Compete).",
          text_ip_3: "<b>Licence is private to Customer.</b> We do not grant you a license, and you acknowledge that you are not permitted to sell, distribute, or publish in any way (under an open source license or otherwise) to any other party without express written permission from us, any portion of the Services (including any source code of the Services) or any derivative or modification of the Services created by you, even if we otherwise publish or redistribute (under an open source license or otherwise) the Services or any portion thereof.",
          text_ip_4: "<b>Limited Rights Transfer.</b> We do not transfer any rights to any portion of the Services to you or any third party, and delivery of any Services to you shall not constitute any transfer of rights, except as set forth above. We retain all rights unless enumerated otherwise herein.",
          text_ip_5: "<b>We Retain Ownership of our Services and Source Code.</b> We retain all rights to all of our works (including source code, object code, user interfaces, documentation, or other works), algorithms, data, inventions, discoveries, ideas, designs, trade secrets, tangible or intangible, embodied in the Services. No portion of the Services is developed as a “work-made-for-hire”, for you, and such Services shall be exclusively owned by us.",
          text_ip_6: "<b>Use with Other Customers.</b> We reserve the right to use the Services or any portion thereof for any and all purposes. We intend and reserves the right, and you hereby acknowledge our intent and right, to incorporate the Services or any portion thereof into projects for customers other than you, and, without limitation, to license the Services or any portion thereof to our other customers and to other third parties.",
          text_ip_7: "<b>Customer Contributions to us.</b> Nothing in these Terms requires you to contribute to the Services; however, if you choose to contribute any intellectual property via a submission to our source code repositories (for example, via a GitHub pull request), we will own such contribution. You warrant that such contributions do not include any of your Confidential Information, and you hereby assign to us all rights to such contributions, including, without limitation, all copyrights, patent rights, trade secret rights, trademark rights, moral rights, and other intellectual property rights to and in the Services in all countries, including the right to pursue patents, utility models, or industrial design applications in all countries, and will do everything reasonably possible (when requested by us) to carry out in good faith the intent of this clause.",
          text_ip_9: "<b>Use of Open Source Software.</b> You acknowledge that from time to time we may utilize third-party software, such as publicly-distributed software (e.g., third-party software commonly known as “free software” or “open source software” subject to one or more third-party license agreements), or other third-party documentation and information in generating the Services or otherwise providing products or services to you under these Terms. We may incorporate such third-party software into the Services or make use of such third-party software in the Services.",
          text_ip_99: "<b>Use of third-party Software.</b> You acknowledge that from time to time we may utilize third-party software, though such software may not fall under Open Source Software definition.",
          text_ip_10: "<b>Your Use of Open Source Software.</b> You also acknowledge that, under the terms of the License granted herein, you agree not to distribute the Services or any derivative of the Services in any way and, as such, acknowledge and agree that you will not “convey” any Services or derivative of the Services in a manner that would trigger GPL, LGPL, or MPL obligations commonly referred to as “viral” obligations. In the event you produce any derivative work from any portion of the Services, or otherwise modify the Services, you shall be solely responsible for ensuring that such derivatives or modifications comply with the terms of the licenses to any third-party software incorporated into the Services. The same applies to any third-party Software as well.",
          text_ip_11: "You hereby grant us a royalty-free, worldwide, transferable, sublicensable, irrevocable, perpetual license to use, reproduce, translate, modify, create derivative works from, distribute, and incorporate into the Services, any suggestions, enhancement requests, recommendations or other feedback provided by you or your Authorized Users relating to Services. Notwithstanding anything stated to the contrary in the Terms, this irrevocable license will continue after the expiry or termination of these Terms for any reason.",
          title_3rd_party: "9. Third-Party Applications",
          text_3rd_party_1: "When using our Services, you may need to access third-party services like GitHub or Discord, and they may require you to agree to their own terms and conditions and policies. You should carefully review those before agreeing to them.",
          title_non_compete: "10. Use of Services to Compete",
          text_non_compete_1: "If you compete with us by selling reusable or pre-built infrastructures, we will immediately terminate your license.",
          text_non_compete_2: "You warrant that you are not in the business of offering, designing, selling, or otherwise distributing reusable or pre-built infrastructures to software companies, and further agree that you will not offer, design, sell, or otherwise distribute reusable infrastructure modules to software companies located in any jurisdiction, so long as we is in the business of offering, designing, selling, or otherwise distributing reusable infrastructure modules to software companies. In the event that we, at its sole discretion and in good faith, determines that you are competing with us in violation of this clause, we may, as its sole remedy, terminate these Terms, including, without limitation, the License and any terms of service for any specific Services, upon notice to you, and you shall immediately cease use of all of our intellectual property upon such termination.",
          title_liability: '11. Liability',
          text_liability_1: 'You expressly understand and agree that we shall not be liable, in law or in equity, to you or to any third party for any direct, indirect, incidental, lost profits, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if the company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) any licences related issues; (vi) or any other matter relating to this Terms of Service or the services, whether as a breach of contract, tort (including negligence whether active or passive), or any other theory of liability.',
          // text_liability_2: 'In any case, total liability to you under these Terms can not not exceed the compensation received by us from you when you completed the purchase',
          text_liability_3: 'You will defend, indemnify, and hold harmless us and our Affiliates and their respective officers, shareholders, directors, employees, and agents from and against any third party liability, claims, demands, damages, judgments, losses and expenses of any nature, including legal expenses and attorney’s fees, arising out of any theory of liability (including tort, warranty, or strict liability) or out of damage to any property, resulting from the use of the Services by you or your Authorized Users.',
          text_liability_4: '<b>Exclusion of Liability.</b> In no event will we or our affiliates, or respective officers, shareholders, directors, employees, agents, affiliates, successors or assigns be liable for lost profits or special, incidental, or consequential damages, whether in an action in contract or tort, even if the covered party has been advised by us.',
          text_liability_5: 'The information on this website is provided “as-is”, without any guarantees. Its authors disclaim all liability for actions taken or not taken based on that information, or for any errors or omissions it may contain.',
          title_independent: '12. Independent Parties',
          text_independent_1: 'You and us are independent parties and nothing in these Terms will constitute either Party or their employees as the employer. Neither you nor us has any authority to assume or create any obligation or liability, either express or implied, on behalf of the other',
          title_subs: 'Other',
          title_subs_term: '1. Terms',
          text_subs_term_2: '<b>Code repository (Source Code).</b> After the purchase you will receive access to the private source code repositories (“Library Repos”), which contain a collection of infrastructure code created by us. From time to time, we might release new features, bug fixes, security patches, automated tests, documentation, and other improvements to existing code, as well as create completely new code (collectively, “Updates”).',
          text_subs_term_3: '<b>Updates</b> to the software are not guaranteed.',
          text_subs_term_4: 'Due to the nature of digital products, the Services/Products <b>cannot be refunded</b> or exchanged once access is granted.',
          text_subs_term_5: 'It is solely your responsibility to update your software.',
        },
      ],
      date_text: "Last updated: August 16, 2024."
    }
  }
}
</script>

<template>
  <div class="clearfix">
    <SaasInfraHeader/>
    <DocsCreateOrganisation/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import DocsCreateOrganisation from "@/components/service-documentation/set-up-aws/create-organisation/DocsCreateOrganisation.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";

export default {
  name: 'DocsIndex',
  components: {SaasInfraHeader, SaasInfraFooter, DocsCreateOrganisation},
}
</script>

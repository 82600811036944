<template>
  <div :class="['btn-group language-dropdown', isWideScreen ? 'dropstart' : 'dropup']">
    <button type="button" class="btn btn-custom" data-bs-toggle="dropdown" aria-expanded="false">
      <span :class="`fi fi-${flagLanguage} fis`"></span>
    </button>
    <ul class="dropdown-menu">
      <li @click="changeLanguage('en')"><span class="fi fi-us fis"></span> English</li>
      <li @click="changeLanguage('de')"><span class="fi fi-de fis"></span> Deutsch</li>
      <li @click="changeLanguage('fr')"><span class="fi fi-fr fis"></span> Français</li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {useLanguageStore} from "@/store/language";

export default {
  name: 'SaasInfraLanguageDropdown',
  setup() {
    const { locale } = useI18n();
    const languageStore = useLanguageStore();
    const currentLanguage = ref(languageStore.language);
    const isWideScreen = ref(window.innerWidth > 992);

    const flagLanguage = computed(() => {
      return currentLanguage.value === 'en' ? 'us' : currentLanguage.value;
    });

    const changeLanguage = (newLanguage) => {
      locale.value = newLanguage;
      languageStore.setLanguage(newLanguage);
      currentLanguage.value = newLanguage;
    };


    // Method to handle window resize
    const handleResize = () => {
      isWideScreen.value = window.innerWidth > 992;
    };

    // Add event listener when component is mounted
    onMounted(() => {
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial check
    });

    // Remove event listener when component is unmounted
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      changeLanguage,
      currentLanguage,
      flagLanguage,
      isWideScreen
    };
  }
};
</script>

<style scoped>
.language-dropdown .btn-custom {
  background-color: white;
  color: black;
  cursor: pointer;
}

.language-dropdown .dropdown-menu {
  width: auto;
  min-width: 0;
  max-width: 250px;
}

.language-dropdown .dropdown-menu li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  cursor: pointer;
}
</style>

<template>
  <div class="main-page-wrapper font-roboto overflow-hidden">
    <SaasInfraHeader/>
    <SaasInfraIntro/>
    <SaasInfraManagement/>
    <SaasInfraWhy/>
    <div class="mt-100" id="pricing">
    <SaasInfraPricingForOneTimePaymentsArea/>
    </div>

    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraHeader from '../header/SaasInfraHeader.vue';
import SaasInfraIntro from './SaasInfraIntro.vue';
import SaasInfraManagement from './SaasInfraManagement.vue';
import SaasInfraPricingForOneTimePaymentsArea from '../pricing-for-one-time-payments/SaasInfraPricingForOneTimePaymentsArea.vue'
import SaasInfraWhy from './SaasInfraWhy.vue';
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";

export default {
  name: 'productLandingMain',
  components: {
    SaasInfraHeader,
    SaasInfraIntro,
    SaasInfraPricingForOneTimePaymentsArea,
    SaasInfraManagement,
    SaasInfraWhy,
    SaasInfraFooter,
  }
}
</script>

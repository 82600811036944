import awsconfig from "@/aws-exports";
import {CookieStorage} from "aws-amplify/utils";
import { defaultStorage } from 'aws-amplify/utils';


class BrowserStorage {

    browserCookieStorage = new CookieStorage(awsconfig.cookieStorage);
    browserLocalStorage = defaultStorage;

    // Determines if the key should be stored in cookies
    async shouldUseCookie(key) {
        const cookieKeys = ["LastAuthUser", "refreshToken", "userData"];
        return cookieKeys.some(subKey => key.includes(subKey));
    }

    // Set a cookie or localstorage item based on the key
    async setItem(key, value) {
        if (await this.shouldUseCookie(key)) {
            this.browserCookieStorage.setItem(key, value)
        } else {
            this.browserLocalStorage.setItem(key, value);
        }
    }

    // Get a cookie or localstorage item based on the key
    async getItem(key) {
        if (await this.shouldUseCookie(key)) {
            return this.browserCookieStorage.getItem(key)
        } else {
            return this.browserLocalStorage.getItem(key);
        }
    }

    // Remove a cookie or localstorage item based on the key
    async removeItem(key) {
        if (await this.shouldUseCookie(key)) {
            this.browserCookieStorage.removeItem(key);
        } else {
            this.browserLocalStorage.removeItem(key);
        }
    }


    // Clear all cookies and localstorage for the current domain
    async clear() {
        await this.browserLocalStorage.clear()
        await this.browserCookieStorage.clear();
    }
}

let browserStorage = new BrowserStorage()
export {browserStorage}

// useSendCode.js
import {ref} from 'vue';
import {useAuthStore} from "@/store/auth";
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
import {resendSignUpCode} from 'aws-amplify/auth';

export function useSendCode() {
    const store = useAuthStore();
    const { t } = useI18n();
    const toast = useToast();
    const toastTimeout = ref(5000); // Default timeout or retrieve from somewhere

    const sendNewCode = async (email) => {
        const username = store.currentEmail || email;
        if (!username) {
            toast.error(t('toasts.typeEmailForNewCode'), {timeout: toastTimeout.value * 4});
            return;
        }
        try {
            await resendSignUpCode({username});
            toast.success(t('toasts.codeResent'), {timeout: toastTimeout.value});
        } catch (error) {
            toast.error(t('toasts.errorResendingCode', {error: error.message}), {timeout: toastTimeout.value * 4});
        }
    };

    return { sendNewCode };
}

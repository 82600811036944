<template>
  <footer class="theme-footer-five mt-130 md-mt-100">
    <div class="inner-container">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-4">

            <div class="title" style="margin-bottom: 15px">{{ $t('footer.helpTitle') }}</div>
            <div class="text-center">
              {{ $t('footer.use') }}
              <router-link
                  to="#contactModal"
                  data-bs-toggle="modal"
                  data-bs-target="#contactModal"
                  class="email header-action"
              >{{ $t('footer.contactForm') }}
              </router-link>
              {{ $t('footer.orWrite') }} <a href="mailto:support@saasconstruct.com"
                                            class="email">{{ $t('footer.supportEmail') }}</a>
            </div>
            <div class="title mt-15" style="margin-bottom: 10px">{{ $t('footer.followSocial') }}</div>
            <ul class="d-flex justify-content-center social-icon">
              <li>
                <a href="https://x.com/@server_kota" target="_blank">
                  <img
                      src="../../assets/images/icon/social_icons/x.svg"
                      alt=""
                      width="25"
                      height="25"
                  /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- ContactModal start -->
      <ContactModal/>
      <!-- ContactModal end -->
    </div> <!-- /.inner-container -->

    <div class="container about-company">
      <div class="row justify-content-center align-items-center">
        <!-- First column -->
        <div class="col-6 col-lg-4">
          <div class="title-about-company">{{ $t('footer.legalTitle') }}</div>
          <div class="text-center">
            <router-link to="/terms">{{ $t('footer.terms') }}</router-link>
          </div>
          <div class="text-center">
            <router-link to="/privacy">{{ $t('footer.privacy') }}</router-link>
          </div>
        </div>
        <!-- Second column -->
        <div class="col-6 col-lg-4">
          <div class="title-about-company">{{ $t('footer.companyTitle') }}</div>
          <div class="text-center">
            <router-link to="/about">{{ $t('footer.about') }}</router-link>
          </div>
          <div class="text-center">
            <router-link to="/">{{ $t('footer.home') }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-35"><BuiltWithButton></BuiltWithButton></div>
    <p class="copyright">{{ $t('footer.copyright') }}</p>
  </footer>
</template>


<script>
import ContactModal from "../saas-infra-main/SaasInfraContactModal.vue";
import BuiltWithButton from "@/components/buttons/BuiltWithButton.vue";

export default {
  name: 'SaasInfraFooter',
  components: {
    ContactModal,
    BuiltWithButton
  },
}
</script>


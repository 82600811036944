<template>
  <div class="user-data-page clearfix">
    <div class="form-wrapper">
      <form @submit.prevent="sendPasswordResetCode" class="user-data-form">
        <hr class="delimiter-80">
        <div class="row">
          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('forgotPassword.typeEmail') }}</label>
              <input required type="email" :placeholder="$t('forgotPassword.emailPlaceholder')" v-model="email"/>
            </div>
          </div>

          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <button class="theme-btn-one mb-50" data-test="submit-button"
                      :disabled="isProcessing">
                <span v-if="isProcessing" class="spinner"
                      style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('forgotPassword.sendCodeButton') }}</span>
              </button>
            </div>
          </div>
        </div>
      </form>

      <form @submit.prevent="changePassword" class="user-data-form">
        <div class="row">
          <div class="input-group-meta mt-30 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('forgotPassword.enterCode') }}</label>
              <input required type="text" :placeholder="$t('forgotPassword.codePlaceholder')" v-model="code"/>
            </div>
          </div>

          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('forgotPassword.enterNewPassword') }}</label>
              <input required type="password" :placeholder="$t('forgotPassword.newPasswordPlaceholder')" v-model="newPassword"/>
            </div>
          </div>

          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <button class="theme-btn-one mb-50" data-test="submit-button"
                      :disabled="isProcessing">
                <span v-if="isProcessing" class="spinner"
                      style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('forgotPassword.changePasswordButton') }}</span>
              </button>
            </div>
          </div>

          <div class="input-group-meta mt-30 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <router-link class="global-link" to="/signin">{{ $t('forgotPassword.signInLink') }}</router-link>
            </div>
          </div>
        </div>
      </form>
    </div> <!-- /.form-wrapper -->
  </div>
</template>


<script>
import { ref } from 'vue';
import { useToast } from "vue-toastification";
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth";
import { useSecurity } from "@/composables/useSecurity";
import { useI18n } from "vue-i18n";
import {signOut, resetPassword, confirmResetPassword} from "aws-amplify/auth";

export default {
  name: 'SaasInfraForgotPasswordArea',
  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const router = useRouter();
    const store = useAuthStore();
    const security = useSecurity();
    const toastTimeout = ref(4000);
    const email = ref(null);
    const code = ref(null);
    const newPassword = ref(null);
    const isProcessing = ref(null);

    const sendPasswordResetCode = async () => {
      try {
        isProcessing.value = true;
        await resetPassword({username: email.value});
        toast.success(t('toasts.codeSent'), { timeout: toastTimeout.value * 4 });
      } catch (err) {
        toast.error(t('toasts.errorSupport', { error: err }), { timeout: toastTimeout });
      } finally {
        isProcessing.value = false;
      }
    };

    const signOutBefore = async () => {
      try {
        await signOut({ global: true });
        toast.success(t('toasts.signedOutSuccess'), { timeout: toastTimeout.value });
        router.push("/signin");
      } catch (err) {
        toast.error(t('toasts.signedOutError', { error: err }), { timeout: toastTimeout.value });
      }
    };

    const changePassword = async () => {
      if (!store.currentEmail && !email.value) {
        toast.error(t('toasts.typeEmail'), { timeout: toastTimeout.value * 4 });
        return;
      }

      if (!security.isPasswordSecure(newPassword.value)) {
        toast.error(t('toasts.passwordWeak'), { timeout: toastTimeout });
        return;
      }

      try {
        isProcessing.value = true;
        const username = store.currentEmail || email.value;
        await confirmResetPassword({username: username, newPassword: newPassword.value, confirmationCode: code.value});
        toast.success(t('toasts.passwordChanged'), { timeout: toastTimeout.value });
        signOutBefore();
      } catch (err) {
        toast.error(t('toasts.errorSupport', { error: err }), { timeout: toastTimeout });
      } finally {
        isProcessing.value = false;
      }
    };

    return {
      store,
      email,
      code,
      newPassword,
      sendPasswordResetCode,
      changePassword,
      isProcessing
    };
  },
};
</script>


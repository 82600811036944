<template>
  <BlogListAreaMain/>
</template>

<script>
import BlogListAreaMain from '../../components/blog/bloglist/index.vue';

export default {
  name:'BlogListPage',
  components:{BlogListAreaMain}
}
</script>


export const isCdkProd = () => {
    return process.env.VUE_APP_CDK_ENV === 'prod';
};

export const isLocalhost = () => {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

export const isStripe = () => {
    return process.env.VUE_APP_PAYMENT_SYSTEM === 'STRIPE';
};

export const isLemonSqueezy = () => {
    return process.env.VUE_APP_PAYMENT_SYSTEM === 'LEMON_SQUEEZY';
};

<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">

          <h2>Add AI services</h2>

          <h3>What will we do?</h3>
          <p>
            In this section we will add a RAG (Retrieval-Augmented Generation) system. This is a chatbot that is trained
            on your data to answer questions that visitors to your website may have.
          </p>
          <div class="documentation-image mt-25">
            <img
                src="../../../assets/images/documentation/ai/chatbot_gif.gif"
                alt=""
                class="shapes screen-one"
                style="width: 75%; height: 75%"
            />
          </div>


          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";

export default {
  name: 'AddAI',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {};
  }
}
</script>

<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">

          <h2>Increase AWS quotas</h2>
          <h3>What will we do?</h3>
          <p>
            In this section we will be increasing AWS quotas. We will send requests to AWS explaining why we need such an increase.
          </p>


          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";

export default {
  name: 'IncreaseQuotas',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {};
  }
}
</script>

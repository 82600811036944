<template>
  <div class="clearfix">
    <SaasInfraHeader/>
    <ExplanationCodebase/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import ExplanationCodebase from "@/components/service-documentation/infra-deployment/explanation-codebase/ExplanationCodebase.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";


export default {
  name: 'InfraCloneRepo',
  components: {SaasInfraHeader, SaasInfraFooter, ExplanationCodebase},
}
</script>

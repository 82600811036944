<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Codebase explained</h2>
          <h3>Codebase structure</h3>
          <p>The codebase consists of 4 folders, each folder is responsible for a part of the functionality of the whole application.</p>
          <CodeContainer :code="codeExplained.code" :language="codeExplained.language"/>





          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import CodeContainer from "@/components/service-documentation/commons/CodeContainer.vue";
import {ref} from "vue";

export default {
  name: 'DeployFrontend',
  components: {
    CodeContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    const codeExplained = ref({
      language: 'bash',
      code:
          'backend # code for the backend: AWS Lambda \n' +
          '   api # API endpoints (server) \n' +
          '   postconfirm # defines actions after user has signed up\n' +
          '   shared # shared utilities like sending emails, payments, etc.\n' +
          'cdk # code for the cloud infrastructure, see the blog post on the cloud resources\n' +
          '   app_stage # provides resources for the app like AWS Lambda, DynamoDB, etc. \n' +
          '   modules # utilities for creating AWS Lambdas, notifications, etc.  \n' +
          '   pipelines # provides CI/CD pipeline (CDK pipeline) \n' +
          'frontend # code of the Vue app\n' +
          'tools # code for storing your data in the vector database (chatbot)'

    });
    return {
      codeExplained
    }
  }
}
</script>



<template>
  <div class="billing-toggle">
    <button
        v-for="option in billingOptions"
        :key="option"
        :class="{ 'active': selectedOption === option }"
        @click="setOption(option)"
    >
      {{ $t(`pricingSlider.options.${option}`) }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: "monthly",
      billingOptions: ["monthly", "yearly"],
    };
  },
  methods: {
    setOption(option) {
      this.selectedOption = option;
      this.$emit('update:billing', option);
    },
  },
};
</script>

<style scoped>
.billing-toggle {
  display: flex;
  border: 3px solid #ececec;
  border-radius: 15px;
  overflow: hidden;
  max-width: 500px;
  margin: 30px auto 0 auto;
}

button {
  flex: 1;
  padding: 10px 20px;
  border: none;
  background: #fff;
  cursor: pointer;
  transition: background 0.3s;
  max-width: 500px;
}

button.active {
  background: #e0e0e0;
}
</style>

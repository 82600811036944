<template>
  <div class="main-page-wrapper overflow-hidden">
    <SaasInfraHeader/>
    <SaasInfraAboutCompany/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";
import SaasInfraAboutCompany from "@/components/about-company/SaasInfraAboutCompany.vue";

export default {
  name: 'PrivacyPolicyMain',
  components: {SaasInfraHeader, SaasInfraFooter, SaasInfraAboutCompany},
}
</script>

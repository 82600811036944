import BlogList from "@/pages/blog/blog-list.vue";
import BlogPostSimpleTechStackForAwsCloud from "@/pages/blog/blog-post-the-tech-stack-of-a-simple-saas-for-aws-cloud.vue";
import BlogPostStartingWithAwsWhatToLearnAndDoFirst from "@/pages/blog/blog-post-starting-with-aws-what-to-learn-and-do-first.vue";

export default [
    {
        path: '/blog',
        name: 'BlogList',
        component: BlogList,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.blogTextKey',
            isTranslated: false,
        },
    },
    {
        path: '/blog/the-tech-stack-of-a-simple-saas-for-aws-cloud',
        name: 'BlogPostSimpleTechStackForAwsCloud',
        component: BlogPostSimpleTechStackForAwsCloud,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.blogTextKey',
            isTranslated: false
        },
    },
    {
        path: '/blog/starting-with-aws-what-to-learn-and-do-first',
        name: 'BlogPostStartingWithAwsWhatToLearnAndDoFirst',
        component: BlogPostStartingWithAwsWhatToLearnAndDoFirst,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.blogTextKey',
            isTranslated: false
        },
    },
]

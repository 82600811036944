import {createApp} from "vue";
import router from "./routes";
import {createPinia} from 'pinia'
import "bootstrap";
import "./index.css";
import App from "./App.vue";
import Toast, {POSITION} from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import {Amplify} from 'aws-amplify';
import {ConsoleLogger, Hub} from 'aws-amplify/utils';
import awsconfig from './aws-exports';
import "flag-icons/css/flag-icons.min.css";
import {useHub} from "@/composables/useHub";
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';
import {browserStorage} from "@/hybrid-amplify-storage";

import {usePersistLanguage} from "@/store/language";
import {createI18n} from "vue-i18n";
import {useLanguageStore} from "@/store/language";
import {isCdkProd} from "@/composables/useEnvironment";
import 'highlight.js/styles/atom-one-dark.css'

import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import hljsVuePlugin from "@highlightjs/vue-plugin";
import {usePersistAuth} from "@/store/auth";

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', python);

Amplify.configure(awsconfig);
cognitoUserPoolsTokenProvider.setKeyValueStorage(browserStorage);

ConsoleLogger.LOG_LEVEL = isCdkProd() ? 'INFO' : 'DEBUG';

if (isCdkProd()) {
    const plausibleScript = document.createElement('script');
    plausibleScript.defer = true;
    plausibleScript.setAttribute('data-domain', 'saasconstruct.com');
    plausibleScript.src = 'https://plausible.io/js/script.tagged-events.js';
    document.head.appendChild(plausibleScript);
}

Hub.listen('auth', useHub().hubListener);

const pinia = createPinia()
let app = createApp(App);
app.use(router);
app.use(pinia);
app.use(Toast, {
    position: POSITION.BOTTOM_LEFT,
});
app.use(hljsVuePlugin)

const i18n = createI18n({
    legacy: false,
    locale: useLanguageStore().language,
    messages: {
        en: require('./locales/en.json'),
        de: require('./locales/de.json'),
        fr: require('./locales/fr.json'),
    }
});
app.use(i18n);

app.mount("#app");
usePersistLanguage();
usePersistAuth();

export {i18n};





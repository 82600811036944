<template>
  <PricingForSubscriptionsAreaMain/>
</template>

<script>
import PricingForSubscriptionsAreaMain from '../components/pricing-for-subscriptions/index.vue';

export default {
  name:'PricingForSubscriptionsPage',
  components:{PricingForSubscriptionsAreaMain}
}
</script>

import {defineStore} from "pinia";

const CURRENT_EMAIL_KEY = 'current_email';
const CURRENT_PROVIDER_KEY = 'current_provider';

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        currentEmail: localStorage.getItem(CURRENT_EMAIL_KEY),
        currentProviderName: localStorage.getItem(CURRENT_PROVIDER_KEY),
    }),
    getters: {
        isAuthenticated(state) {
            return !!state.currentEmail || !!state.currentProviderName;
        },
        isFederated(state) {
            return !!state.currentProviderName;
        }
    },
    actions: {
        resetEmail() {
            this.$patch({currentEmail: null});
        },
        resetProviderName() {
            this.$patch({currentProviderName: null});
        },
    }
});


export function usePersistAuth() {
    const authStore = useAuthStore();

    authStore.$subscribe((mutation, state) => {
        if (state.currentEmail !== null) {
            localStorage.setItem(CURRENT_EMAIL_KEY, state.currentEmail);
        } else {
            localStorage.removeItem(CURRENT_EMAIL_KEY);
        }

        if (state.currentProviderName !== null) {
            localStorage.setItem(CURRENT_PROVIDER_KEY, state.currentProviderName);
        } else {
            localStorage.removeItem(CURRENT_PROVIDER_KEY);
        }
    });
}

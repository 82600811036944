import {defineStore} from "pinia"

export const useSubscriptionsStore = defineStore({
    id: "subscriptions",
    state: () => ({
        activeSubscriptions: null,
    }),
    getters: {},
    actions: {}
})

import {defineStore, getActivePinia} from "pinia";
import {browserStorage} from "@/hybrid-amplify-storage";

export function useAllStores() {
    async function resetAllStores() {
        const activepinia = getActivePinia();
        if (activepinia) {
            // Reset all Pinia stores
            Object.entries(activepinia.state.value).forEach(([storeName, state]) => {
                const storeDefinition = defineStore(storeName, state);
                const store = storeDefinition(activepinia);
                store.$reset();
            });
        }

        await browserStorage.clear()
        sessionStorage.clear();
    }

    return {resetAllStores}
}

<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Hey builder, welcome 👋</h2>
          <h3>What is SaaSConstruct?</h3>
          <div class="documentation-image">
            <img
                src="../../../assets/images/documentation/deployment/initial_run.gif"
                alt=""
                class="shapes screen-one"
            />
          </div>
          <p class="mt-25">
            SaaSConstruct is a codebase (template) for the AWS cloud, consisting of cloud infrastructure, frontend, and backend.
            <span class="font-roboto-medium">SaaSConstruct can be deployed to AWS to create a SaaS website with payments, authorization, custom AI chatbot, and many more features</span>
            (check <a href="https://demo.saasconstruct.com" target="_blank" rel="noopener noreferrer" class="underline-link">the demo</a> to see the live SaaS version). It is a great way to <span class="font-roboto-medium">get your idea off the ground quickly, save on development costs, and learn about AWS</span>.
          </p>
          <p>
            The <a href="https://saasconstruct.com/blog/the-tech-stack-of-a-simple-saas-for-aws-cloud" target="_blank" rel="noopener noreferrer" class="underline-link">current architecture</a> is serverless, meaning that cloud resources scale down to zero when not in use.
          </p>
          <p>The architecture can be easily changed or extended by simply modifying the infrastructure code (e.g., if you prefer to use other cloud resources, including non-serverless ones).</p>

          <InfoContainer class="mt-10">
            <span class="font-roboto-medium">INFO</span>
            <p>Cloud resources used in SaaSConstruct are mostly covered by the <a href="https://aws.amazon.com/free" target="_blank" rel="noopener noreferrer" class="underline-link">AWS Free Tier</a>. For example, the costs of running this website you are currently on and everything behind it are almost zero, even with hundreds of unique visitors daily.</p>
          </InfoContainer>

          <h3>SaaSConstruct Features</h3>
          <ul class="list-item-one">
            <li class="mt-15"><span class="font-roboto-medium">AI First</span> - SaaSConstruct has the latest AI features, such as a retrieval-augmented generation system (RAG) using AWS Bedrock, which is connected to the chatbot on the website. You can feed the bot data about your SaaS, and afterward, the bot can answer questions about it and guide customers. You can also extend this system in any way you want, such as gathering customer feedback and performing other specific actions.</li>
            <li class="mt-15"><span class="font-roboto-medium">Out-of-the-Box</span> - Everything needed for SaaS is included: frontend (website), backend (business logic), and AWS cloud infrastructure (where everything runs). Once deployed using this documentation, you will have a fully functioning SaaS, ready to register users, accept payments, and much more (check <a href="https://demo.saasconstruct.com" target="_blank" rel="noopener noreferrer" class="underline-link">the demo</a> to see the live version).</li>
            <li class="mt-15"><span class="font-roboto-medium">Payments</span> - SaaSConstruct has two payment systems to choose from: Stripe (popular with large companies) and LemonSqueezy (Merchant of Record, popular with small teams because it handles sales tax/VAT automatically). Both are integrated with Checkout (initial payment) and Customer Portal (subscription management), which means that users can buy your products and manage their subscriptions (change, cancel, renew) as well as their billing information and invoices without you doing anything.</li>
            <li class="mt-15"><span class="font-roboto-medium">Authentication and Authorization</span> - Users can sign up/sign in via email or Google login. You can add other social providers as well (such as Facebook, Amazon, etc.). SaaSConstruct uses AWS Cognito and AWS Amplify library for this. Users can change their email and password, as well as delete their account. The forgot password flow is also included. The backend checks if the user is authenticated, and you can specify which routes are accessible without authentication.</li>
            <li class="mt-15"><span class="font-roboto-medium">Credit System for Subscriptions</span> - You can assign the number of actions (or jobs) the users can do for each subscription. An action can be anything you specify. For example, in the current implementation, one action refers to creating an item on the Service page. On the Free Plan, users can create only a small number of items per month, meaning creating an item consumes one action for the user. The data (how many actions are left) is displayed on the Account page.</li>
            <li class="mt-15"><span class="font-roboto-medium">Initial Business Logic</span> - Users can perform all actions mentioned above (login, pay, manage subscriptions, change user information, delete account, etc.), as well as view items on the Service page (with pagination), add and delete items, talk to the AI chatbot, use the contact form, etc.</li>
            <li class="mt-15"><span class="font-roboto-medium">Environments</span> - SaaSConstruct supports development and production environments, each in a separate AWS account to facilitate isolation and security.</li>
            <li class="mt-15"><span class="font-roboto-medium">CI/CD</span> - CI/CD is set up with CDK pipelines, deploying infrastructure to dev and prod environments based on the branch. To roll out a new version of your SaaS, it is "git push". In case of rollout failure, you will be notified by email.</li>
            <li class="mt-15"><span class="font-roboto-medium">Traffic Alarms</span> - You will be notified if you get hit by an unusual amount of requests. There are alarms that monitor both frontend website traffic and backend.</li>
            <li class="mt-15"><span class="font-roboto-medium">Infrastructure as Code</span> - SaaSConstruct uses Infrastructure as Code (IaC) to manage and provision the cloud resources required for your SaaS. You can easily customize and extend the infrastructure to fit your specific needs.</li>
            <li class="mt-15"><span class="font-roboto-medium">Translation</span> - Translate your frontend to any language by simply adding a JSON file.</li>
            <li class="mt-15"><span class="font-roboto-medium">Rate Limiting</span> - The API Gateway is set up with rate limiting, meaning in case of extremely high traffic to your backend, the traffic will be throttled.</li>
            <li class="mt-15"><span class="font-roboto-medium">Caching</span> - The frontend is hosted with CDN (CloudFront), meaning the resources are cached (for performance and cost efficiency). The backend endpoints have a caching layer as well.</li>
            <li class="mt-15"><span class="font-roboto-medium">CORS</span> - Cross-Origin Resource Sharing (CORS) is configured to allow controlled access to your backend from different origins. Only your own domain and those of the payment provider are allowed in the production CORS policy.</li>
            <li class="mt-15"><span class="font-roboto-medium">Error Handling</span> - In case of severe errors, such as payment issues, you will receive email notifications with a trace of the error.</li>
            <li class="mt-15"><span class="font-roboto-medium">Emails</span> - Emails are sent with AWS SES, which is an AWS email provider with a generous Free Tier.</li>
            <li class="mt-15"><span class="font-roboto-medium">Blog</span> - There is a blog template available to help you generate interest in your product. For example, almost half of the traffic to this site comes from one useful blog post.</li>
            <li class="mt-15"><span class="font-roboto-medium">Documentation</span> - For documentation, there is a documentation template where you can give instructions on how to use your product, and also embed code snippets (with a button so the code can be quickly copied).</li>
            <li class="mt-15"><span class="font-roboto-medium">Privacy policy, terms and conditions</span> - There are templates for this too, so you don't have to reinvent the wheel.</li>
            <li class="mt-15"><span class="font-roboto-medium">Discord Community and Support</span> - If you have questions, you can always reach out via email or Discord for support.</li>
          </ul>

          <h3>Tech Stack</h3>
          <p>For an architecture overview and the AWS services used, see <a href="https://saasconstruct.com/blog/the-tech-stack-of-a-simple-saas-for-aws-cloud" target="_blank" rel="noopener noreferrer" class="underline-link">this blog post</a></p>
          <ul class="list-item-one">
            <li><span class="font-roboto-medium">Frontend: Vue.js (TypeScript)</span> - Vue.js is not only one of the most popular frontend frameworks but also the fastest and easiest to start with. You can literally learn Vue in an hour. Additionally, using SFC (Single File Components) makes it very easy to work with AI assistants like ChatGPT to speed up your development. The programming language is standard across any frontend framework: TypeScript.</li>
            <li class="mt-15"><span class="font-roboto-medium">Backend: AWS Lambdas (Python)</span> - The backend is written in Python to support current and future AI capabilities. Nearly all AI solutions and libraries, including deep learning and machine learning, are Python-first. Note: If you don't know Python, you can extend the current AWS Lambdas with other AWS Lambdas in your preferred language.</li>
            <li class="mt-15"><span class="font-roboto-medium">Cloud Infrastructure: AWS CDK (Python)</span> - The cloud infrastructure is written in AWS CDK using Python to ensure consistency with the backend.</li>
          </ul>

          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import InfoContainer from "@/components/service-documentation/commons/InfoContainer.vue";

export default {
  name: 'DocsGetStarted',
  components: {
    SidebarDocumentation,
    DocumentationPagination,
    InfoContainer
  },
  setup() {
    return {};
  }
}
</script>

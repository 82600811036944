<template>
  <div class="main-page-wrapper overflow-hidden">
    <SaasInfraHeader/>
    <SaasInfraTerms/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";
import SaasInfraTerms from "@/components/terms/SaasInfraTerms.vue";

export default {
  name:'TermsConditionMain',
  components: {SaasInfraHeader, SaasInfraFooter, SaasInfraTerms},
}
</script>

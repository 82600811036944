<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Set up the codebase</h2>
          <h3>1. Fork the SaasConstruct repository</h3>
          <p>
            After purchasing SaasConstruct, you will receive an invitation to the GitHub repository. <br>
            Go to the SaasConstruct repository and fork it. This will create a private repository in your GitHub account.
            Make sure to <span class="font-roboto-medium">check</span> 'Copy the main branch only', we will create the development branch ourselves.
          </p>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/fork_repo.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/fork_repo_2.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>

          <h3>2. Clone your forked SaasConstruct repository to your local machine</h3>

          <CodeContainer :code="cloneCode.code" :language="cloneCode.language"/>

          <h3>3. Create the development branch from the main branch</h3>

          <CodeContainer :code="cloneCode2.code" :language="cloneCode2.language"/>

          <h3>4. Add the original SaaSConstruct repo as an upstream repository</h3>
          <p>This way you can get the latest changes from the original repository.</p>
          <CodeContainer :code="addUpstream.code" :language="addUpstream.language"/>
          <p>Each time you need to merge changes from the original SaaSConstruct repository into your personal repository, you can do the following:</p>
          <CodeContainer :code="mergeCode.code" :language="mergeCode.language"/>

          <p>Great! Let's move on to the next step.</p>


          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import CodeContainer from "@/components/service-documentation/commons/CodeContainer.vue";
import {ref} from "vue";

export default {
  name: 'CloneRepo',
  components: {
    CodeContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    const cloneCode = ref({
      language: 'bash',
      code: 'git clone https://github.com/YOUR GITHUB ACCOUNT USERNAME/saasconstruct.git'
    });
    const cloneCode2 = ref({
      language: 'bash',
      code:
          'cd saasconstruct # navigate to the project folder\n' +
          'git checkout -b development # create the development branch from the main branch\n'+
          'git push origin development # push the development branch to your GitHub repository\n'+
          'git checkout main # switch back to main branch'
    });
    const addUpstream = ref({
      language: 'bash',
      code: 'git remote add upstream https://github.com/Server-Kota-Org/saasconstruct'
    });
    const mergeCode = ref({
      language: 'bash',
      code: 'git fetch upstream\n' +
          'git merge upstream/main\n' +
          'git merge upstream/development'
    });

    return {
      cloneCode,
      cloneCode2,
      addUpstream,
      mergeCode
    }
  }
}
</script>



<template>
  <div class="clearfix">
    <SaasInfraHeader/>
    <DeployFrontend/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import DeployFrontend from "@/components/service-documentation/infra-deployment/deploy-frontend/DeployFrontend.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";


export default {
  name: 'InfraDeployFrontend',
  components: {SaasInfraHeader, SaasInfraFooter, DeployFrontend},
}
</script>

<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">
          <h2>Deploy the backend</h2>
          <h3>1. Create a CodePipeline connection to your GitHub repository</h3>
          <p>First, we need to create a connection between your AWS account and your GitHub repository.</p>
          <ul class="list-item-one">
            <li>
              Go to your <span class="font-roboto-medium">dev</span> account and navigate to CodePipeline. Then click <span class="font-roboto-medium">'Connections'</span> and then <span class="font-roboto-medium">'Create connection'</span>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/codepipeline_click_create_connection.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              On the next page, select <span class="font-roboto-medium">'GitHub'</span> and click <span class="font-roboto-medium">'Connect to GitHub'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/codepipeline_click_github.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              </li>
            <li class="mt-25">
              On the next page, click <span class="font-roboto-medium">'Install new app'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/codepipeline_install_new_app.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              The browser will take you to your GitHub account. Follow the instructions and select your forked SaaSConstruct repository.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/codepipeline_select_repo.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              Once it is done, click on <span class="font-roboto-medium">'Connect'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/codepipeline_click_connect.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              You can now see the connection.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/codepipeline_connection.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              Repeat these steps for the <span class="font-roboto-medium">prod</span> account (make sure it's in the region you've chosen).
            </li>
          </ul>
          <p>Great! Let's move on to the next step.</p>

          <h3>2. Set values for the global_config.py file</h3>
          <p>Now we need to configure the global_config.py file, which you'll find in the <span class="font-roboto-medium">'cdk/modules'</span> folder.
            <br>This file represents the application configuration that does not contain sensitive data (sensitive data is handled by AWS Secret Manager).
            <br> For the <span class="font-roboto-medium"> dev</span> account, change only these fields in the method <span class="font-roboto-medium">create_global_config_dev()</span>:
          </p>
          <CodeContainer :code="globalConfig.code" :language="globalConfig.language"/>
          <p>You can get the amplify_id by going to AWS Amplify and selecting your app.</p>
          <div class="documentation-image">
            <img
                src="../../../../assets/images/documentation/deployment/amplify_id.png"
                alt=""
                class="shapes screen-one"
            />
          </div>
          <p>Similarly, we set up the <span class="font-roboto-medium">prod</span> account. Change only these fields in the method <span class="font-roboto-medium">create_global_config_prod()</span>. The difference is that you need to write to the domain that we configured when we <router-link to="/documentation/deploy-frontend" class="underline-link">deployed the frontend</router-link>. Also set the subdomain to empty, as we will not be using it. </p>
          <CodeContainer :code="globalConfig2.code" :language="globalConfig2.language"/>
            <p>You don't need to push anything to the repo yet, because we're going to deploy AWS CodePipeline from the local machine first.</p>

          <p>Great! Now let's configure AWS Secret Manager.</p>

          <h3>3. Configure AWS Secret Manager</h3>
          <p>AWS Secret Manager is used to store sensitive data such as emails, API keys, etc.<br>
            We need to store some information so that AWS CodePipeline can use it when creating cloud resources.<br>
            We need to do this for both <span class="font-roboto-medium">dev</span> and <span class="font-roboto-medium">prod</span> accounts.
          </p>
          <p>Let's start with the <span class="font-roboto-medium">dev</span> account:</p>
          <ul class="list-item-one">
            <li>
              Go to AWS CodePipeline, select your connection, and copy its ARN.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/codepipeline_connection_arn.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>

            <li>Go to AWS Secret Manager (Make sure the region is the same as the one you usually use), click <span class="font-roboto-medium">'Store new secret'</span>, select <span class="font-roboto-medium">'Other type of secret'</span> and store the following data:
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/secrets_store_new_secret.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <ul class="list-item-one" style="margin-left: 25px">
                <li class="mt-15">
                  codestar_connection_arn: CONNECTION ARN THAT YOU COPIED
                </li>
                <li>
                  contact_email_from, contact_email_to, test_user_1, pipeline_fail_email. These emails need to be <span class="font-roboto-medium">different</span>, so just use a '+' sign as below. For example, if you have an email address myemail@gmail.com, you can simply add a plus sign.
                  Result is myemail+from@gmail.com.
                  <div class="documentation-image">
                    <img
                        src="../../../../assets/images/documentation/deployment/secrets_store_new_secret_2.png"
                        alt=""
                        class="shapes screen-one"
                    />
                  </div>
                </li>
              </ul>

            </li>
            <li class="mt-15">
              Click <span class="font-roboto-medium">'Next'</span>, name your secret <span class="font-roboto-medium">saasconstruct/secrets</span> and finish the setup (just click 'Next' and 'Store'). Your secret will appear.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/secrets_store_new_secret_3.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-15">
              Do the same for the <span class="font-roboto-medium">prod</span> account. The connection ARN will be different (because it is a different AWS account).
            </li>
            <li>
              Great! Let's move on to the next step!
            </li>
          </ul>


<!--          <h4>Bootstrap AWS accounts</h4>-->
<!--          <p>We will activate AWS CLI SSO so that your terminal has a permission to deploy to your dev account.<br>Run the following command:</p>-->
<!--          <CodeContainer :code="activateSSO.code" :language="activateSSO.language"/>-->
<!--          <p>This will take you to the browser where you will need to approve it. Once this is done, your AWS CLI will be authenticated for the amount of time you specified when you created the authorisation sets (e.g. 8 hours).</p>-->
<!--          <p>After that we can  bootstrap AWS accounts. <br>-->
<!--            Let's bootstrap the <span class="font-roboto-medium">dev</span> account. <br>-->
<!--            If the command does not run successfully, refer to the <span class="font-roboto-medium">'Troubleshooting'</span> section below.</p>-->
<!--          <CodeContainer :code="bootstrapDev.code" :language="bootstrapDev.language"/>-->
<!--          <p>Great! Now let's bootstrap the <span class="font-roboto-medium">prod</span> account.<br>-->
<!--            First activate SSO for the prod account:-->
<!--          </p>-->
<!--          <CodeContainer :code="activateSSO2.code" :language="activateSSO2.language"/>-->
<!--          <p>-->
<!--            Then run the cdk bootstrap command:-->
<!--          </p>-->
<!--          <CodeContainer :code="bootstrapProd.code" :language="bootstrapProd.language"/>-->
<!--          <p>Great! Now let's move on to the next step.</p>-->



          <h3>4. Create and activate virtualenv</h3>
          <p>
            First we need to enable the Python virtual environment. We have already installed <span class="font-roboto-medium">virtualenv</span>
            from the <router-link to="/documentation/install-tools" class="underline-link">previous step</router-link> , so this will be easy. <br>
          </p>
          <CodeContainer :code="activateEnvCode.code" :language="activateEnvCode.language"/>
          <p>We'll be using this virtual environment whenever we use CDK in Terminal or Command Prompt/Powershell, so don't forget to activate it each time. Usually IDE's like VS Code allow you to configure it <a href="https://code.visualstudio.com/docs/python/environments#_working-with-python-interpreters" target="_blank" rel="noopener noreferrer" class="underline-link">automatically.</a></p>
          <h3>5. Deploy the initial AWS CodePipeline</h3>
          <p>
            Now we will deploy the AWS CodePipeline. We will not deploy the whole backend for now, as we set <span class="font-roboto-medium">first_deploy=True</span> in global_config.py.<br>
          </p>
          <h4>Deploy to the dev account</h4>
          <p>First, we will deploy to the <span class="font-roboto-medium">dev</span> account, and then repeat the same steps for the <span class="font-roboto-medium">prod</span> account.</p>
          <ul class="list-item-one">
            <li>
              Go to your Access Portal and copy the commands to set the AWS credentials to your local machine. These are temporary credentials that will only be used for the initial setup.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/access_portal_temp_creds.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/deployment/access_portal_temp_creds_2.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-15">
              Run the copied commands in your terminal. Example for MacOS and Linux:
            </li>
            <CodeContainer :code="exportAwsKey.code" :language="exportAwsKey.language"/>
            <p>Note: You have set up temporary credentials for the dev account. To check if this worked, you can run any AWS CLI command that returns the account id. Example:</p>
            <CodeContainer :code="checkAccount.code" :language="checkAccount.language"/>
            <li class="mt-15">
              Run the following commands. This will bootstrap your <span class="font-roboto-medium">dev</span> account (it will set up CDK in your dev account).
            </li>
                      <CodeContainer :code="bootstrapDev.code" :language="bootstrapDev.language"/>
            <li class="mt-15">
              Run the following commands to check if the CDK template is ready for deployment. This will output the following stacks: <span class="font-roboto-medium">SaasConstructPipelineStack-dev, SaasConstructPipelineStack-prod</span>.
              </li>
                        <CodeContainer :code="cdkDeploy.code" :language="cdkDeploy.language"/>
            <li class="mt-15">
              Run the following command for the <span class="font-roboto-medium">dev</span> account:
            </li>
            <CodeContainer :code="cdkDeploy2.code" :language="cdkDeploy2.language"/>
            <p>Great, now let's do this again for <span class="font-roboto-medium">prod</span> account</p>
          </ul>

            <h4>Deploy to the prod account</h4>
          <p>The steps for the prod account are the same as for the dev account.</p>
          <ul class="list-item-one">
            <li>
              Go to your Access Portal and copy the commands to set the AWS credentials. Now choose the <span class="font-roboto-medium">prod</span> account.
            </li>
            <li>
              Run the copied commands in your terminal.
            </li>
            <li>
              Bootstrap the prod account.
            </li>
            <CodeContainer :code="bootstrapProd.code" :language="bootstrapProd.language"/>
            <li>
              Run the following command for the <span class="font-roboto-medium">prod</span> account:
            </li>
            <CodeContainer :code="cdkDeploy3.code" :language="cdkDeploy3.language"/>
          </ul>
          <p class="mt-15"><span class="font-roboto-medium">Congratulations!</span>  You can now see the CodePipeline in both your AWS accounts (dev and prod).
            </p>
          <div class="documentation-image">
            <img
                src="../../../../assets/images/documentation/deployment/codepipeline_dev.png"
                alt=""
                class="shapes screen-one"
            />
          </div>
          <div class="documentation-image">
            <img
                src="../../../../assets/images/documentation/deployment/codepipeline_prod.png"
                alt=""
                class="shapes screen-one"
            />
          </div>
            <p>Let's deploy the backend.</p>

          <h3>6. Deploy the backend</h3>
          <p>Now all we need to do is set one value and push it to your GitHub repository.</p>

          <ul class="list-item-one">
            <li>
              Go to the <span class="font-roboto-medium">global_config.py</span> and set first_deploy to False in both <span class="font-roboto-medium">create_global_config_dev()</span> and <span class="font-roboto-medium">create_global_config_prod()</span>:
              </li>
            <CodeContainer :code="globalConfig3.code" :language="globalConfig3.language"/>

            <li class="mt-15">
              Now just push the code to your GitHub repository. <br>
            </li>
            <InfoContainer class="mt-15">
              <span class="font-roboto-medium">IMPORTANT</span>
              After you've pushed the code, and while AWS CodePipeline is doing a rollout, you'll receive emails (check the spam folder too) from AWS to your inbox (the one you entered in Secret Manager), asking you to confirm that you want to receive notifications from AWS (e.g. if the pipeline fails, if traffic to your site spikes unexpectedly, etc). Please confirm them all.
            </InfoContainer>
            <CodeContainer class="mt-15" :code="commitPushFinal.code" :language="commitPushFinal.language"/>

          <li class="mt-15">After the rollout has been completed, the last step is to make sure your newly created resources are synchronised with the frontend. <br>
            To do this, just do an empty git commit and push the code.
          </li>
            <CodeContainer class="mt-15" :code="commitPushFinalSync.code" :language="commitPushFinalSync.language"/>
            <p>Alternatively, you can go to AWS Amplify (for both  <span class="font-roboto-medium">dev</span> and  <span class="font-roboto-medium">prod</span>) and simply click:  <span class="font-roboto-medium">Redeploy this version.</span></p>
            <div class="documentation-image">
              <img
                  src="../../../../assets/images/documentation/deployment/amplify_redeploy.png"
                  alt=""
                  class="shapes screen-one"
              />
            </div>
          </ul>
          <p><span class="font-roboto-medium">Congratulations!</span></p>

          <h3 style="margin-top: 30px !important;">7. Using the website</h3>


          <ul class="list-item-one">
            <p>You are now ready to use the website (both dev and prod versions):</p>
            <div class="documentation-image">
              <img
                  src="../../../../assets/images/documentation/deployment/initial_run.gif"
                  alt=""
                  class="shapes screen-one"
              />
            </div>

            <li class="mt-15">
              <span class="font-roboto-medium">Sign up with email and password</span>.
              <InfoContainer class="mt-15">
                <span class="font-roboto-medium">INFO</span>
                Currently you can use a test user email you've provided to the Secret Manager.
                The reason is that AWS does not give you full access to AWS email service (AWS SES) from the start. <br>
                You will have to request AWS (prod account only) to give you production access to AWS SES. <router-link to="/documentation/request-ses" class="underline-link">We will do it later.</router-link> <br>
                If you want to use additional emails now, just add a new verified identity (email) in AWS SES (AWS SES -> Identities -> Create identity).
              </InfoContainer>
              </li>
            <li>
              <span class="font-roboto-medium">Sign in with email and password and sign out</span>. We will configure Google signup/signin later.
            </li>
            <li>
              <span class="font-roboto-medium">Change email or password, change language. Delete a user</span>.
            </li>
            <li>
              <span class="font-roboto-medium">Add and delete items in the /service page</span>.
            </li>
            <li>
              <span class="font-roboto-medium">Use the contact form to send emails about a product</span>.
            </li>
            <li>The rest of the functionality, such as  <span class="font-roboto-medium">payments and the chatbot</span>, <span class="font-roboto-medium">will be configured later. Just follow the documentation</span>.</li>
            </ul>




          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>

  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import CodeContainer from "@/components/service-documentation/commons/CodeContainer.vue";
import {ref} from "vue";
import InfoContainer from "@/components/service-documentation/commons/InfoContainer.vue";

export default {
  name: 'ExplanationCodebase',
  components: {
    InfoContainer,
    CodeContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    const activateEnvCode = ref({
      language: 'bash',
      code:
          '# from the root folder of the project (folder /saasconstruct)\n' +
          'cd cdk # navigating to the infrastructure folder\n' +
          '# replace 3.11 with your version of Python if needed\n' +
          'python3.11 -m venv .venv # creating a virtual environment\n' +
          '# activating the virtual environment\n' +
          '.venv\\Scripts\\activate # for Windows\n' +
          'source .venv/bin/activate # for Posix (MacOS, Linux)\n' +
          'pip install -r requirements.txt # installing cdk libraries'
    });
    const configureSSO = ref({
      language: 'bash',
      code:
          'aws configure sso'

    });
    const configureSSO2 = ref({
      language: 'bash',
      code:
          'SSO session name: all-sso\n' +
          'SSO start URL: URL of the Access portal, e.g. https://d-996asdw.awsapps.com/start#/\n' +
          'SSO region: The region you previously chose, e.g. eu-central-1\n' +
          'SSO registration scopes [sso:account:access]: press Enter\n' +
          '# You will be prompted to browser to login\n' +
          'There are 2 accounts available to you: select dev account\n' +
          'CLI default client Region: Region you previously chose, e.g. eu-central-1\n' +
          'CLI default output format: press Enter\n' +
          'CLI profile name: dev'
    });

    const configureSSO3 = ref({
      language: 'bash',
      code:
          'aws s3 ls --profile dev'
    });

    const configureSSO4 = ref({
      language: 'bash',
      code:
          'aws s3 ls --profile prod'
    });

    const activateSSO = ref({
      language: 'bash',
      code:
          'aws sso login --profile dev'
    });
    const activateSSO2 = ref({
      language: 'bash',
      code:
          'aws sso login --profile prod'
    });
    const bootstrapDev = ref({
      language: 'bash',
      code:
          '# <dev-account-id> is your dev account id (you can find it in the Access Portal)\n' +
          '# <region> is your chosen region\n' +
          'cdk bootstrap aws://<dev-account-id>/<region> --cloudformation-execution-policies arn:aws:iam::aws:policy/AdministratorAccess'
    });
    const bootstrapProd = ref({
      language: 'bash',
      code:
          '# <prod-account-id> is your prod account id (you can find it in the Access Portal)\n' +
          '# <region> is your chosen region\n' +
          'cdk bootstrap aws://<prod-account-id>/<region> --cloudformation-execution-policies arn:aws:iam::aws:policy/AdministratorAccess'
    });
    const globalConfig = ref({
      language: 'python',
      code:
          'first_deploy=True, # set it to True when doing the initial deployment\n' +
          'google_sign_in_supported=False, # we don\'t deploy with google sign_in yet, we will do it later \n' +
          '# account info\n' +
          'deploy_account_id="YOUR DEV ACCOUNT ID", # for example, 9812739812\n' +
          'deploy_region="YOUR DEV ACCOUNT REGION", # for example, eu-central-1\n' +
          'app_account="YOUR DEV ACCOUNT ID", # for example, 9812739812\n' +
          'app_region="YOUR DEV ACCOUNT REGION", # for example, eu-central-1\n' +
          'repo_string="YOUR GITHUB USERNAME/saasconstruct",  # your github name/repository name\n' +
          '# you can find Amplify id (app id)  by navigating to AWS Amplify of your dev account \n' +
          'amplify_id = "YOUR APP ID IN AMPLIFY", # for example, d1bvn71osz5zr8 \n' +
          'app_random_name_extension="17284", # some random numbers (can be anything, just not long)'
    });
    const globalConfig2 = ref({
      language: 'python',
      code:
          'first_deploy=True, # set it to True when doing the initial deployment\n' +
          'google_sign_in_supported=False, # we don\'t deploy with google sign_in yet, we will do it later \n' +
          '# account info\n' +
          'deploy_account_id="YOUR PROD ACCOUNT ID", # for example, 938739837\n' +
          'deploy_region="YOUR PROD ACCOUNT REGION", # for example, eu-central-1\n' +
          'app_account="YOUR PROD ACCOUNT ID", # for example, 938739837\n' +
          'app_region="YOUR PROD ACCOUNT REGION", # for example, eu-central-1\n' +
          'repo_string="YOUR GITHUB USERNAME/saasconstruct",  # your github name/repository name\n' +
          '# you can find Amplify id (app id)  by navigating to AWS Amplify of your prod account \n' +
          'amplify_id = "YOUR APP ID IN AMPLIFY", # for example, d123kjh123 \n' +
          'domain=YOUR DOMAIN, # put the domain your purchased in Route53\n' +
          'subdomain="", # leave it empty\n' +
          'app_random_name_extension="17284", # some random numbers (can be anything, just not long)'
    });
    const globalConfig3 = ref({
      language: 'python',
      code:
          '# set it to False, everything else stays as before (like google_sign_in_supported=False)\n' +
          'first_deploy=False,'
    });
    const exportAwsKey = ref({
      language: 'bash',
      code:
          "export AWS_ACCESS_KEY_ID=\"ASIA27P6XSBHH7MXL\"\n" +
          "export AWS_SECRET_ACCESS_KEY=\"P3I1KcCO/ENtQX8Lomx7XhkKgPPVZHK96CSWi\"\n" +
          "export AWS_SESSION_TOKEN=\"IQoJb3JpZ2luX2VjEOX//////////wEaDGV1LWNlbnRyYWwtMSJIMEYCIQCD5CRONW6crS4Jp3hMf66cLf1ImrjK4j67i2TekQgPnAIhANbH6uqkpIpeH1pXOiBm1c7+zOQvTdnVkEKY2THh34adKu8CCL///////////wEQARoMNzU0ODM3ODUyMjM5Igxp+ViwKEB+3rTdvTYqwwKrrpvsD9ZrxfGh46ysHydDggT7iDPRhTp5qx9X8i17CEe7VhrN3gYJmcS6SUk6DcNJR+kqra+D5czQn7IjnNvV5aj9VY0lQs/Yz+kJcQlurZgIjUM1Jit72Ur0uk7VKyqPQ37mUXqmsCRTPeULc8fnB/Jn+x2SM1tRoAE3NhpBqWXq5fHYsYk196Xma1QS0anyuYFzccJtbfgrWsSqEcmv3POA5zw7yrSffcIj2jRuwdYeGw+rUYIcS3xWPCTfz8Rz1Jj+fQlzL3kIRQ/oLQUElySUoTCT8hKxDIRDV+WgqMaG0ikbKldj4lS3Oihx8+pepZEUkbjm05gMgr3jOspatZmLr1o5j1MfbWBEJ0SaURl2+4qUwv4vcbldJBkMWIVxZtJQs8SbeK1GJF73eFQ1LbvDpa4OQXwn6in70R2pu56ZGTDKo4m1BjqmAavkxgL/h7BWXFb60i0p4RvBqUhH9yV/cdea0YLaP9uRQGQb6QRSmaCF7+s/vAN7+PLo4B7D1G1pevrU+JlIcpGOZNlj5Fyx/wcfU1DZHM4ZkJwX23UqmF+0TB4Z8NRcRyZK+PTu4Ht4URDZfM5fyfOyrQ37iiAFnV+nIjEbvYmp8QSKNTvGzG7rmcgfk=\""
    });
    const cdkDeploy = ref({
      language: 'bash',
      code:
          '# from the root folder of the project (folder /saasconstruct)\n' +
          'cd cdk # navigate to the infrastructure folder\n' +
          "cdk ls\n"
    });
    const cdkDeploy2 = ref({
      language: 'bash',
      code:
          '# from the cdk folder of the project (folder /saasconstruct/cdk)\n' +
          'cdk deploy SaasConstructPipelineStack-dev --require-approval never\n'
    });
    const cdkDeploy3 = ref({
      language: 'bash',
      code:
          '# from the cdk folder of the project (folder /saasconstruct/cdk)\n' +
          'cdk deploy SaasConstructPipelineStack-prod --require-approval never\n'
    });
    const checkAccount = ref({
      language: 'bash',
      code:
          '# compare the output of this command with your account id\n' +
          'aws sts get-caller-identity --query Account --output text'
    });
    const commitPushFinal = ref({
      language: 'bash',
      code:
          'cd saasconstruct # navigate to the project folder\n' +
          'git add .\n'+
          'git commit -m "deploy backend"\n'+
          'git push origin main # pushes code to the main branch (prod)\n'+
          'git push origin main:development # pushes current main branch to development (dev)\n'

    });
    const commitPushFinalSync = ref({
      language: 'bash',
      code:
          'cd saasconstruct # navigate to the project folder\n' +
          'git add .\n'+
          'git commit --allow-empty -m "empty"\n'+
          'git push origin main # pushes code to the main branch (prod)\n'+
          'git push origin main:development # pushes current main branch to development (dev)\n'

    });
    return {
      activateEnvCode,
      configureSSO,
      configureSSO2,
      configureSSO3,
      configureSSO4,
      activateSSO,
      activateSSO2,
      bootstrapDev,
      bootstrapProd,
      globalConfig,
      globalConfig2,
      globalConfig3,
      exportAwsKey,
      cdkDeploy,
      cdkDeploy2,
      cdkDeploy3,
      checkAccount,
      commitPushFinal,
      commitPushFinalSync
    }
  }
}
</script>




<template>
  <div class="main-page-wrapper overflow-hidden">
    <SaasInfraHeader/>
    <SaasInfraPricingForOneTimePaymentsArea/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraPricingForOneTimePaymentsArea from './SaasInfraPricingForOneTimePaymentsArea.vue';
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";

export default {
  name: 'PricingForOneTimePaymentsAreaMain',
  components: {SaasInfraHeader, SaasInfraFooter, SaasInfraPricingForOneTimePaymentsArea}
}
</script>

<template>
  <div class="code-container">
    <highlightjs :language=language :code=code></highlightjs>
    <copy-button :code=code></copy-button>
  </div>
</template>

<script>
import CopyButton from "@/components/service-documentation/commons/CopyButton.vue";

export default {
  components: {CopyButton},
  props: {
    code: String,
    language: String
  }
};
</script>

<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">


          <h2>Request a production access to AWS SES (prod account only)</h2>
          <h3>What is AWS SES?</h3>

          <p><span class="font-roboto-medium">AWS SES</span> is an email service from AWS that can send both transactional (such as sending an email to confirm account creation) and marketing emails.
          </p>

          <p>
            When you create an AWS account, you can only use AWS SES to send emails to verified identities (such as the test user we created).<br>
            This is because your AWS SES service is in a sandbox. We need to ask AWS to give us production access to the production AWS account. <br>
          </p>
          <InfoContainer class="mt-15">
            <span class="font-roboto-medium">INFO</span>
            AWS takes these requests seriously and may deny them. It is therefore important to provide as much information as possible.
          </InfoContainer>


          <h3>Request the production access</h3>
          <ul class="list-item-one">
            <li>
              Go to AWS SES in your prod account and click 'Request production access'.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/increasequotas/ses_prod_access.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              Select Transactional and enter the website URL (it's better to use an actual domain at this point rather than an Amplify Hosting generated URL).
            </li>
            <li class="mt-25">
              Describe your use case carefully and completely. It is very important. Some suggestions:
              <ul class="list-item-one">
                <li>
                  Describe your use case in detail.
                </li>
                <li>
                  Describe your user base.
                </li>
                <li>
                  You may want to indicate how the emails are obtained (by self-registration in your product).
                </li>
                <li>
                  Mention that you will use AWS SES together with AWS Cognito for transactional emails when users perform authentication actions.<br>
                </li>
                <li>
                  Indicate that you have read and agree to compy with the AWS Terms of Service and Acceptable Use Policy.
                </li>
                <li>
                  Mention that you can provide any additional information if it is needed.
                </li>
              </ul>
            </li>
            <li>
              Submit your request. Wait for the reply (it can take 1-2 days).
            </li>
          </ul>


          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import InfoContainer from "@/components/service-documentation/commons/InfoContainer.vue";

export default {
  name: 'RequestSES',
  components: {
    InfoContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    return {};
  }
}
</script>

<template>
  <div class="md-pt-50" id="pricing">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 m-auto">
          <div class="title-style-six text-center mb-15">
            <h2>{{ $t('pricingForOneTimePaymentsArea.oneTimeProducts') }}</h2>
            <h4 class="mt-20">{{ $t('pricingForOneTimePaymentsArea.encouragement') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="pricing-table-area-four">
      <div class="tab-content">
        <div class="tab-pane active" id="month">
          <div class="row justify-content-center">
            <div
                v-for="item in pricingProducts"
                :key="item.id"
                class="col-lg-5 col-sm-6"
            >
              <div class="pr-table-wrapper">
                <div class="pack-name">{{ $t(item.pack_name_key) }}</div>

                <ul class="pr-feature" style="padding: 20px 0 20px 0;">
                  <li v-for="feature in item.features" :key="feature.id"
                      :class="{ 'special': item.specialFeatures.includes(feature.id) }">
                    {{ $t(feature.text_key) }} {{ $t(findAdditionalTextKey(feature.id, item.id)) }}
                    <ul v-if="feature.subfeatures" class="pr-subfeature" style="padding-left: 20px;">
                      <li v-for="subfeature in feature.subfeatures" :key="subfeature.id">
                        {{ $t(subfeature.text_key) }}
                      </li>
                    </ul>
                  </li>
                </ul>
                <div
                    class="top-banner d-md-flex"
                >
                  <div>
                    <div><span>${{ item.price }}</span></div>
                  </div>
                </div>
                <button
                    class="trial-button plausible-event-name=BuyClick"
                    @click="createCheckoutSession(item)"
                    :class="{loading: creatingSession === item.id, 'disabled-pay-button': (oneTimePaymentsStore.oneTimePaymentsMade && oneTimePaymentsStore.oneTimePaymentsMade.includes(item.pack_name_en))}"
                    :disabled="creatingSession === item.id || (oneTimePaymentsStore.oneTimePaymentsMade && oneTimePaymentsStore.oneTimePaymentsMade.includes(item.pack_name_en)) || fetchingOneTimePaymentsMade"
                >
                  <span v-if="creatingSession === item.id || fetchingOneTimePaymentsMade" class="spinner"></span>
                  <span
                      v-else-if="oneTimePaymentsStore.oneTimePaymentsMade && oneTimePaymentsStore.oneTimePaymentsMade.includes(item.pack_name_en)">{{
                      $t('pricingForOneTimePaymentsArea.bought')
                    }}</span>
                  <span v-else>{{ $t('pricingForOneTimePaymentsArea.buy') }}</span>
                </button>
                <p class="pt-5 unlimited-projects-message font-roboto-light" style="font-size: 16px">
                  {{ $t('pricingForOneTimePaymentsArea.payOnce') }}</p>
              </div>
              <!-- /.pr-table-wrapper -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.pricing-table-area-four -->
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import usePricingProducts from "@/composables/usePricingProducts";
import {useOneTimePaymentsStore} from "@/store/oneTimePayments";
import useFetchOneTimePaymentsMade from "@/composables/useFetchOneTimePaymentsMade";
import useCreateCheckoutSessionForOneTimePaymentsStripe
  from "@/composables/useCreateCheckoutSessionForOneTimePaymentsStripe";
import {isLemonSqueezy, isStripe} from "@/composables/useEnvironment";
import {useAuthStore} from "@/store/auth";
import useCreateCheckoutSessionForOneTimePaymentsLemonSqueezy
  from "@/composables/useCreateCheckoutSessionForOneTimePaymentsLemonSqueezy";

export default {
  name: "SaasInfraPricingForOneTimePaymentsArea",
  setup() {
    const creatingSession = ref(false)
    const {pricingProducts, findAdditionalTextKey} = usePricingProducts();
    const {fetchingOneTimePaymentsMade, fetchOneTimePaymentsMade} = useFetchOneTimePaymentsMade();
    const oneTimePaymentsStore = useOneTimePaymentsStore();
    const store = useAuthStore()

    let createCheckoutSessionForOneTimePayment;
    let createCheckoutSessionForOneTimePaymentWithoutLogin;

    if (isStripe()) {
      const {
        createCheckoutSessionForOneTimePaymentStripe,
        createCheckoutSessionForOneTimePaymentWithoutLoginStripe,
      } = useCreateCheckoutSessionForOneTimePaymentsStripe();

      createCheckoutSessionForOneTimePayment = createCheckoutSessionForOneTimePaymentStripe;
      createCheckoutSessionForOneTimePaymentWithoutLogin = createCheckoutSessionForOneTimePaymentWithoutLoginStripe;
    }

    if (isLemonSqueezy()) {
      const {
        createCheckoutSessionForOneTimePaymentLemonSqueezy,
        createCheckoutSessionForOneTimePaymentWithoutLoginLemonSqueezy,
      } = useCreateCheckoutSessionForOneTimePaymentsLemonSqueezy();

      createCheckoutSessionForOneTimePayment = createCheckoutSessionForOneTimePaymentLemonSqueezy;
      createCheckoutSessionForOneTimePaymentWithoutLogin = createCheckoutSessionForOneTimePaymentWithoutLoginLemonSqueezy;
    }

    const createCheckoutSession = async (item) => {
      if (store.isAuthenticated) {
        await createCheckoutSessionForOneTimePayment(item, creatingSession);
      } else {
        await createCheckoutSessionForOneTimePaymentWithoutLogin(item, creatingSession);
      }
    }

    onMounted(() => {
      if (!oneTimePaymentsStore.oneTimePaymentsMade) {
        fetchOneTimePaymentsMade();
      }
    });


    return {
      creatingSession,
      createCheckoutSession,
      oneTimePaymentsStore,
      pricingProducts,
      fetchingOneTimePaymentsMade,
      findAdditionalTextKey
    };
  }
};
</script>





<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">

          <h2>Add Stripe payments</h2>

          <h3>What is Stripe?</h3>

          <p>Stripe is a payment service (payment gateway) that allows you to accept payments from your users.<br>It has a lot of features.<br>
            It is very popular with all kinds of businesses.
          </p>

          <h3>1. Create products in Stripe (Test Mode and AWS dev account)</h3>
          <p>First, we will create products in a test mode that allows you to test payments. This only applies to your AWS dev account.
          </p>
          <ul class="list-item-one">
            <li>
              Create a <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer" class="underline-link">Stripe account</a> if you don't already have one.<br>
              <InfoContainer class="mt-15">
                <span class="font-roboto-medium">If you already have a Stripe account</span>
                For every application/SaaS you have, you will need an additional Stripe account. Don't worry, this is what you supposed to do. All your accounts will be under your name and the same email. <br>
                This is done also to avoid confusion in webhooks. <br>
                So, if you already have a Stripe account and you already have some other application there, just add an additional account.
                If you don't want to create a new account, you can put multiple SaaS in one account, but this is not recommended.
                <div class="documentation-image">
                  <img
                      src="../../../../assets/images/documentation/paymentproviders/stripe_new_account.png"
                      alt=""
                      class="shapes screen-one"

                  />
                </div>
              </InfoContainer>

            </li>
            <li class="mt-15">
              Make sure that you are in the <span class="font-roboto-medium">Test mode</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_test_mode.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 75%; height: 75%"
                />
              </div>
            </li>
            <li>
              Go to the Product catalog and create three products: Free, Basic and Standard. When you create each product, create two prices for it: monthly and yearly. These will be our subscriptions (Free Monthly, Free Yearly, Basic Monthly, Basic Yearly, Standard Monthly, Standard Yearly). <br>
              Example:
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_product_price.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>

            </li>
            <li class="mt-25">
              Note (write down) a price id of each of the six subscriptions.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_product_price2.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-35">
              Change <span class="font-roboto-medium">create_global_config_dev()</span> in global_config.py.<br>
            </li>
            <CodeContainer :code="paymentConfig.code" :language="paymentConfig.language"/>
            <li class="mt-25">
              Fill in <span class="font-roboto-medium">create_stripe_plans_dev()</span> in global_config.py.<br>
              - price_id is the price id you copied<br> - price is your price<br> - jobs_limit is the number of actions (add and delete notes) that a user is allowed to do on the Service page (you can leave jobs_limit as it is, and change it later at any time).<br> Example:
            </li>
            <CodeContainer :code="paymentConfig2.code" :language="paymentConfig2.language"/>
            <p>Note: make sure you copy it correctly (for example, your Standard Monthly plan price id will be StripePlan price id with the name StandardMonthly).</p>
          </ul>



          <h3 style="margin-top: 40px !important;">2. Create a webhook in Stripe (Test Mode and AWS dev account)</h3>
          <ul class="list-item-one">
            <li>
              In your AWS dev account, go to API Gateway service and copy the URL of the gateway.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/gateway_url.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              In your Stripe account, create a webhook, paste the URL you copied and add <span class="font-roboto-medium">/webhook_payment_stripe</span> to it.<br>
              Also select the following events:
              <ul class="list-item-one">
                <li>checkout.session.completed</li>
                <li>customer.subscription.deleted</li>
                <li>customer.subscription.updated</li>
                <li>invoice.paid</li>
                <li>invoice.payment_failed</li>
              </ul>

              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_add_webhook.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
              <div class="documentation-image mt-25">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_add_webhook_2.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 75%; height: 75%"
                />
              </div>
            </li>
            <li class="mt-25">
              Note the signing secret of the webhook, we will need it later.
              <div class="documentation-image mt-25">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_webhook_secret.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              Also note an API key in your Stripe account, we will also need it later.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_api_key.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>
          <h3 style="margin-top: 40px !important;">3. Configure Customer Portal (Test Mode and AWS dev account)</h3>
          <p>The Stripe Customer Portal allows your users to manage their subscriptions, including upgrades, downgrades and cancellations.</p>
          <ul class="list-item-one">
            <li class="mt-15">
              In your Stripe account, go to Billing Settings and select the <span class="font-roboto-medium">Customer Portal tab</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_customer_portal.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-15">
              Make sure that <span class="font-roboto-medium">'Cancel subscriptions'</span> is enabled with 'Cancel at end of billing period'. These will allow users to cancel subscriptions in their customer portal.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_customer_portal3.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 75%; height: 75%"
                />
              </div>
            </li>

            <li>
              In 'Subscriptions' section select <span class="font-roboto-medium">'Customers can switch plans'</span>. Also <span class="font-roboto-medium">add the products</span> you've created.<br>
              Select <span class="font-roboto-medium">'Apply changes immediately with proration'</span>. Proration allows your users to switch subscriptions at no extra cost. For example, if a user pays for a $20 monthly subscription and then wants to upgrade to a $50 monthly subscription (within a day after the $20 purchase), they only pay the remaining $30. If they upgrade the next day, or after a week, the remaining amount will be greater, and after a month it will be a full $50.<br>
              <div class="documentation-image mt-25">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_customer_portal2.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 50%; height: 50%"
                />
              </div>
            </li>
            <li class="mt-15">
              Click 'Save changes'.
            </li>
          </ul>

          <h3 style="margin-top: 40px !important;">4. Save Stripe secrets to AWS Secret Manager (Test Mode and AWS dev account)</h3>
          <ul class="list-item-one">
            <p>Go to AWS Secret Manager (dev account) and update your secrets (you have noted them in the previous steps). Add the following:</p>
            <li>
              <span class="font-roboto-medium">stripe_webhook_key</span> - this is a signing secret for the webhook.
            </li>
            <li>
              <span class="font-roboto-medium">stripe_api_key</span> - this is an API key.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/stripe_secret_manager.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>


          <h3 style="margin-top: 40px !important;">4. Deploy changes and test payments (Test Mode and AWS dev account)</h3>

          <ul class="list-item-one">
            <li>
              Now just push the code to your GitHub repository:
            </li>
            <CodeContainer class="mt-15" :code="commitPushDev.code" :language="commitPushDev.language"/>
            <li class="mt-15">After the rollout has been completed, the last step is to make sure your updated payment information is delivered to the frontend (it could be cached so we need to run CI/CD again).<br>
              To do this, just do an empty git commit and push the code.
            </li>
            <CodeContainer class="mt-15" :code="commitPushFinalSync.code" :language="commitPushFinalSync.language"/>
            <p>Alternatively, you can go to AWS Amplify (for <span class="font-roboto-medium">dev</span> account) and simply click:  <span class="font-roboto-medium">Redeploy this version.</span></p>
            <div class="documentation-image">
              <img
                  src="../../../../assets/images/documentation/deployment/amplify_redeploy.png"
                  alt=""
                  class="shapes screen-one"
              />
            </div>
            <li class="mt-15">
              Go to your website (AWS dev account, you can get the URL in Amplify Hosting) and test payments. Just subscribe to any plan. You must use <a href="https://docs.stripe.com/testing#testing-interactively" target="_blank" rel="noopener noreferrer" class="underline-link">Stripe test cards</a> to do this.
              <InfoContainer class="mt-15">
                <span class="font-roboto-medium">IMPORTANT</span>
                Never use actual real cards when testing payments in Test Mode! Use only <a href="https://docs.stripe.com/testing#testing-interactively" target="_blank" rel="noopener noreferrer" class="underline-link">Stripe test cards.</a>
              </InfoContainer>
            </li>
            <li class="mt-25">
              Note: After initial payment for the subscription, you can open the Customer Portal by navigating to the Account and clicking 'Manage'.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/paymentproviders/ls_customer_portal_manage.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>


          <h3 style="margin-top: 40px !important;">5. Enable Stripe payments for the prod account</h3>
          <ul class="list-item-one">
            <li>
              <span class="font-roboto-medium"><a href="https://docs.stripe.com/get-started/account/activate" target="_blank" rel="noopener noreferrer" class="underline-link">Activate</a> your Stripe account</span>.
            </li>
            <li class="mt-15">
              Once the store has been activated, deactivate the <span class="font-roboto-medium">Test mode</span>.
            </li>
            <li>
              Repeat steps 1-4 from this documentation page, but this time:
              <ul class="list-item-one">
                <li>
                  Don't use the <span class="font-roboto-medium">Test mode</span>.
                </li>
                <li>
                  Change the prod functions <span class="font-roboto-medium">create_stripe_plans_prod()</span> and <span class="font-roboto-medium">create_global_config_prod()</span> functions instead of dev functions.
                </li>
                <li>
                  Save the Stripe secrets to your AWS prod account.
                </li>
                <li>
                  This time you don't need to test the payment flows with the test cards because it's a production version and you can accept real payments.
                </li>
              </ul>
            </li>
          </ul>
          <p><span class="font-roboto-medium">Congratulations!</span> You now have a payment system integrated into your website.</p>




          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import InfoContainer from "@/components/service-documentation/commons/InfoContainer.vue";
import {ref} from "vue";
import CodeContainer from "@/components/service-documentation/commons/CodeContainer.vue";

export default {
  name: 'AddStripe',
  components: {
    CodeContainer,
    InfoContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    const paymentConfig = ref({
      language: 'python',
      code:
          'payment_system = "STRIPE",'
    });
    const paymentConfig2 = ref({
      language: 'python',
      code:
          '  StripePlan(\n' +
          '      name="FreeMonthly",\n' +
          '      price_id="price_1PmxeZJJMR1NKlQ2wdgawfBf", # change this\n' +
          '      price="0", # change this if you have a different price\n' +
          '      jobs_limit="10"),\n' +
          '# do the same for the rest of plans'
    });
    const commitPushDev = ref({
      language: 'bash',
      code:
          'cd saasconstruct # navigate to the project folder\n' +
          'git add .\n'+
          'git commit -m "support Stripe payments for the dev account"\n'+
          'git push origin main # pushes code to the main branch (prod)\n'+
          'git push origin main:development # pushes current main branch to development (dev)\n'
    });
    const commitPushFinalSync = ref({
      language: 'bash',
      code:
          'cd saasconstruct # navigate to the project folder\n' +
          'git add .\n'+
          'git commit --allow-empty -m "empty"\n'+
          'git push origin main # pushes code to the main branch (prod)\n'+
          'git push origin main:development # pushes current main branch to development (dev)\n'

    });
    return {
      paymentConfig,
      paymentConfig2,
      commitPushDev,
      commitPushFinalSync
    };
  }
}
</script>

<template>
  <div class="user-data-page clearfix">
    <div class="form-wrapper">
      <form @submit.prevent="onSubmit" class="user-data-form">
        <div class="wrapper-google-sign-in-button-sign-up-area">
          <button type="button" @click="onSubmitWithGoogle" class="google-sign-in-button">
            {{ $t('signin.signInWithGoogle') }}
          </button>
        </div>
        <hr class="delimiter-80">
        <div class="row">
          <div class="input-group-meta d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('signin.emailLabel') }}</label>
              <input required type="email" :placeholder="$t('signin.emailPlaceholder')" v-model="email"/>
            </div>
          </div>
          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <label>{{ $t('signin.passwordLabel') }}</label>
              <input
                  ref="passwordInput"
                  required
                  :type="'password'"
                  :placeholder="$t('signin.passwordPlaceholder')"
                  class="pass_log_id"
                  v-model="password"
              />
            </div>
          </div>

          <div class="input-group-meta mt-10 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <button class="theme-btn-one mb-50" data-test="submit-button"
                      :disabled="isProcessing">
                <span v-if="isProcessing" class="spinner"
                      style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
                <span v-else>{{ $t('signin.signInWithEmailButton') }}</span>
              </button>
            </div>
          </div>

          <div class="input-group-meta mt-30 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <p class="global-link" @click="forgotPassword">{{ $t('signin.forgotPasswordLink') }}</p>
              <router-link class="global-link" to="/signup">{{ $t('signin.noAccountSignUpLink') }}</router-link>
            </div>
          </div>
        </div>
      </form>
    </div> <!-- /.form-wrapper -->
  </div>
</template>


<script>
import { ref } from 'vue';
import { useToast } from "vue-toastification";
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth";
import { useI18n } from 'vue-i18n';
import {signInWithRedirect, signOut, signIn} from "aws-amplify/auth";
import {useSendCode} from "@/composables/useSendNewCode";

export default {
  name: 'SaasInfraSignInArea',
  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const router = useRouter();
    const store = useAuthStore();
    const toastTimeout = ref(4000);
    const isProcessing = ref(null);
    const {sendNewCode} = useSendCode();

    const email = ref('');
    const password = ref('');

    const signInWithEmail = async () => {
      try {
        isProcessing.value = true;
        await signOut({ global: false }); // clear storage
        const signedIn = await signIn({
          username: email.value,
          password: password.value,
        });
        if (signedIn.nextStep.signInStep === "CONFIRM_SIGN_UP") {
          toast.info(t('toasts.pleaseConfirmYourEmail'), {
            timeout: toastTimeout.value
          });
          store.$patch({
            currentEmail: null,
          });
          router.push('/signup-confirm');
          await sendNewCode(email.value)
        } else {
          store.$patch({
            currentEmail: email.value,
          });
          toast.success(t('toasts.signedInSuccess'), {
            timeout: toastTimeout.value
          });
          router.push('/account');
        }
      } catch (error) {
        // if (error.name === 'CodeDeliveryFailureException')
        if (error.code === 'UserNotConfirmedException') {
          toast.error(t('toasts.confirmEmailError', { error: error.message }), {
            timeout: toastTimeout.value
          });
          router.push('signup-confirm');
        } else {
          toast.error(t('toasts.signInError', { error: error.message }), {
            timeout: toastTimeout.value
          });
        }
      } finally {
        isProcessing.value = false;
      }
    };

    const signInWithGoogle = async () => {
      try {
        await signOut({ global: false }); // clear storage
        await signInWithRedirect({provider: "Google"});
      } catch (error) {
        toast.error(t('toasts.signInWithGoogleError', { error: error.message }), {
          timeout: toastTimeout.value
        });
      }
    };

    const onSubmit = () => {
      signInWithEmail();
    };

    const onSubmitWithGoogle = () => {
      signInWithGoogle();
    };

    const forgotPassword = () => {
      router.push("/forgot-password");
    };

    return {
      email,
      password,
      forgotPassword,
      onSubmit,
      onSubmitWithGoogle,
      isProcessing
    };
  },
};
</script>

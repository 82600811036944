<template>
  <div class="container mb-30 d-flex justify-content-center align-items-center">
    <div class="bg-wrapper d-flex flex-column justify-content-center align-items-start">
      <h2 class="font-roboto" style="font-size: 24px">{{ $t('account.yourProduct') }}</h2>
      <div class="bg-wrapper-inner d-flex flex-row align-items-center justify-content-between">
        <h2 class="font-roboto-light" style="font-size: 20px">
          <span v-if="fetchingOneTimePaymentsMade" class="spinner"></span>
          <span v-else>{{ currentProductName }}</span>
        </h2>
        <router-link v-if="oneTimePaymentsStore.oneTimePaymentsMade?.length> 0" to="/documentation/get-started" class="theme-btn-one"
                     :disabled="fetchingOneTimePaymentsMade" style="max-width: 230px; text-align: center;">
          <span v-if="fetchingOneTimePaymentsMade" class="spinner"
                style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
          <span v-else>{{ $t('account.toDocs') }}</span>
        </router-link>
        <router-link v-else to="/#pricing" class="theme-btn-one"
                     :disabled="fetchingOneTimePaymentsMade" style="max-width: 143px; text-align: center;">
          <span v-if="fetchingOneTimePaymentsMade" class="spinner"
                style="border: 2px solid #fff; border-top-color: transparent; animation: spinner 0.6s linear infinite;"></span>
          <span v-else>{{ $t('account.manage') }}</span>
        </router-link>
      </div>
      <div v-if="!fetchingOneTimePaymentsMade && oneTimePaymentsStore.oneTimePaymentsMade?.length > 0" class="mt-10">
        <template v-for="product in allProducts" :key="product.pack_name_en">
          <ul class="pr-feature"
              v-if="oneTimePaymentsStore.oneTimePaymentsMade && product.pack_name_en === currentProduct.pack_name_en">
            <li v-for="feature in currentProduct.features" :key="feature.id" :class="{ 'special': product.specialFeatures.includes(feature.id) }" >{{ $t(feature.text_key) }} {{ $t(findAdditionalTextKey(feature.id, product.id)) }}</li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted} from 'vue';
import {useI18n} from "vue-i18n";
import useFetchOneTimePaymentsMade from "@/composables/useFetchOneTimePaymentsMade";
import {useOneTimePaymentsStore} from "@/store/oneTimePayments";
import usePricingProducts from "@/composables/usePricingProducts";


export default {
  name: 'AccountOneTimePaymentSection',
  setup() {
    const oneTimePaymentsStore = useOneTimePaymentsStore()
    const {fetchingOneTimePaymentsMade, fetchOneTimePaymentsMade} = useFetchOneTimePaymentsMade();
    const {allProducts, findAdditionalTextKey} = usePricingProducts();
    const {t} = useI18n();


    const currentProduct = computed(() => {
      // Ensure there's at least one payment made
      if (!oneTimePaymentsStore.oneTimePaymentsMade?.length) {
        return null;
      }

      // Filter products to those that have been paid for at least once
      const paidProducts = allProducts.filter(product =>
          oneTimePaymentsStore.oneTimePaymentsMade.includes(product.pack_name_en)
      );

      // Convert price strings to numbers for comparison and find the highest priced product
      return paidProducts.reduce((highest, product) => {
        const productPrice = parseFloat(product.price); // Convert price to number
        if (!highest || productPrice > parseFloat(highest.price)) {
          return product; // Return the current product if it's the first one or has a higher price
        }
        return highest; // Otherwise, keep the current highest
      }, null);
    });


    // Use currentProduct to derive the current product name.
    const currentProductName = computed(() => {
      return currentProduct.value ? t(currentProduct.value.pack_name_key) : t('account.noProductYet');
    });



    onMounted(() => {
      if (!oneTimePaymentsStore.oneTimePaymentsMade) {
        fetchOneTimePaymentsMade();
      }
    });

    return {
      oneTimePaymentsStore,
      allProducts,
      currentProductName,
      currentProduct,
      fetchingOneTimePaymentsMade,
      findAdditionalTextKey
    };
  },
};
</script>

<template>
  <div class="main-page-wrapper font-roboto overflow-hidden">
    <SaasInfraHeader/>
    <BlogPostStartingWithAwsWhatToLearnAndDoFirstMainArea/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraHeader from '@/components/header/SaasInfraHeader.vue';
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import BlogPostStartingWithAwsWhatToLearnAndDoFirstMainArea
  from "@/components/blog/blog-post-starting-with-aws-what-to-learn-and-do-first/BlogPostStartingWithAwsWhatToLearnAndDoFirstMainArea.vue";

export default {
  name: 'BlogPostSimpleTechStackForAwsCloud',
  components: {
    BlogPostStartingWithAwsWhatToLearnAndDoFirstMainArea,
    SaasInfraHeader,
    SaasInfraFooter,
  }
}
</script>

import SaasInfra from "@/pages/saas-infra.vue";
import PricingForSubscriptionsPage from "@/pages/pricing-for-subscriptions.vue";
import PricingForOneTimePaymentsPage from "@/pages/pricing-for-one-time-payments.vue";
import {GENERAL_CONSTANTS} from "@/constants/generalConstants";
import {i18n} from "@/main";
import SignInPage from "@/pages/signin.vue";
import ForgotPasswordPage from "@/pages/forgot-password.vue";
import SignUpPage from "@/pages/signup.vue";
import SignUpConfirmPage from "@/pages/signup-confirm.vue";
import AccountPage from "@/pages/account.vue";
import TermsCondition from "@/pages/terms-condition.vue";
import PrivacyPolicy from "@/pages/privacy-policy.vue";
import AboutCompany from "@/components/about-company/index.vue";
import NotFound from "@/pages/404.vue";
import {useToast} from "vue-toastification";

const toast = useToast()
const toastTimeout = 4000

export default [
    {
        path: '/',
        name: 'HomePage',
        component: SaasInfra,
        beforeEnter(to, from, next) { // check if it comes from stripe
            let payment = to.query?.payment;
            if (payment === GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_FAIL) {
                toast.error(i18n.global.t('toasts.paymentFail'), {timeout: toastTimeout * 2});
                next();
            } else {
                next();
            }
        },
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
        }
    },
    {
        path: '/pricing-for-subscriptions',
        name: 'PricingForSubscriptionsPage',
        component: PricingForSubscriptionsPage,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
        }
    },
    {
        path: '/pricing-for-one-time-payments',
        name: 'PricingForOneTimePaymentsPage',
        component: PricingForOneTimePaymentsPage,
        beforeEnter(to, from, next) { // check if it comes from stripe
            let payment = to.query.payment;
            if (payment === GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_FAIL) {
                toast.error(i18n.global.t('toasts.paymentFail'), {timeout: toastTimeout * 2});
                next();
            } else {
                next();
            }
        },
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
        }
    },
    {
        path: '/signin',
        name: 'SignInPage',
        component: SignInPage,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
        },
    },
    {
        path: '/signup',
        name: 'SignUpPage',
        component: SignUpPage,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
        },
    },
    {
        path: '/signup-confirm',
        name: 'SignUpConfirmPage',
        component: SignUpConfirmPage,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
            avoidClearingEmailOnError: true
        },
    },
    {
        path: '/account',
        name: 'AccountPage',
        component: AccountPage,
        beforeEnter(to, from, next) {
            if (to.href.includes("?code=")) { // google social login
                toast.success(i18n.global.t('toasts.signedInSuccess'), {timeout: toastTimeout});
            }

            if (to.query.payment === GENERAL_CONSTANTS.PAYMENT_QUERY_PARAM_VALUE_SUCCESS) { // payment
                toast.success(i18n.global.t('toasts.paymentSuccess'), {timeout: 0});
            }

            next();
        },
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
            requiresAuth: true,
            avoidClearingEmailOnError: true
        },
    },
    {
        path: '/terms',
        name: 'Terms',
        component: TermsCondition,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
            isTranslated: false
        },
    },
    {
        path: '/privacy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.titleTextKey',
            isTranslated: false
        },
    },
    {
        path: '/about',
        name: 'About',
        component: AboutCompany,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.aboutTextKey',
        },
    },
    {
        path: '/not-found',
        name: 'NotFound',
        component: NotFound,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.notFoundTextKey',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            companyKey: 'meta.companyKey',
            textKey: 'meta.notFoundTextKey',
        },
    },
]

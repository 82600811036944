<template>
  <div class="main-page-wrapper overflow-hidden">
    <SaasInfraHeader/>
    <SaasInfraAccountArea/>
    <SaasInfraFooter/>
  </div>
</template>

<script>
import SaasInfraAccountArea from './SaasInfraAccountArea.vue';
import SaasInfraFooter from "@/components/footer/SaasInfraFooter.vue";
import SaasInfraHeader from "@/components/header/SaasInfraHeader.vue";

export default {
  name: 'AccountAreaMain',
  components: {SaasInfraHeader, SaasInfraFooter, SaasInfraAccountArea}
}
</script>

import {defineStore} from "pinia";

const LANGUAGE_STORAGE_KEY = "language"
export const useLanguageStore = defineStore({
    id: 'language',
    state: () => {
        // List of supported languages
        const supportedLanguages = ['en', 'de', 'fr'];

        // Function to get the initial language
        const getInitialLanguage = () => {
            const storedLanguage = localStorage.getItem(LANGUAGE_STORAGE_KEY);
            if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
                return storedLanguage;
            }

            // uncomment this if you want the site to display to be displayed in language of the browser before user chooses explicitly
            // const browserLanguage = navigator.language.substring(0, 2);
            // if (supportedLanguages.includes(browserLanguage)) {
            //     return browserLanguage;
            // }

            return 'en'; // Fallback language
        };

        return {
            language: getInitialLanguage()
        };
    },
    actions: {
        setLanguage(newLanguage) {
            this.language = newLanguage;
        }
    }
});

export function usePersistLanguage() {
    const languageStore = useLanguageStore();

    languageStore.$subscribe((mutation, state) => {
        if (state.language !== null && state.language !== undefined) {
            localStorage.setItem(LANGUAGE_STORAGE_KEY, state.language);
        } else {
            localStorage.removeItem(LANGUAGE_STORAGE_KEY);
        }
    });

}

